// Internal dependencies
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { AssignmentService } from '@/services/assignment.service';

// Interfaces
import { Assignment } from '@/structures/assignment';
import { User } from '@/structures/user';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'test-cognitif-details',
    templateUrl: './test-cognitif-details.component.html',
    styleUrls: ['./test-cognitif-details.component.scss'],
    imports: [SharedModule, MatDialogModule]
})
export class TestCognitifDetailsComponent implements OnInit {
    toto: SafeHtml;
    constructor(
        public dialogRef: MatDialogRef<TestCognitifDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { assignment: Assignment; user: User },
        private assignmentService: AssignmentService
    ) {}

    ngOnInit() {
        this.assignmentService
            .getTestCognitifDetails(this.data.assignment, this.data.user)
            .subscribe((data: SafeHtml) => {
                this.toto = data;
            });
    }

    getSafeHTML(): SafeHtml {
        return this.toto;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
