import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '@/services/login.service';
import { PanelService } from '@/services/panel.service';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    standalone: false
})
export class TabsComponent {
    reduced = false;
    constructor(
        private router: Router,
        private loginService: LoginService,
        private panelService: PanelService
    ) {}

    goTo(route: string) {
        this.router.navigate([route], {
            queryParamsHandling: 'merge'
        });
    }

    isCreationSpace() {
        return this.router.url.includes('teacher/creation');
    }

    toggleLeftPanel($event: MouseEvent) {
        $event.stopImmediatePropagation();
        this.reduced = !this.reduced;
        this.panelService.toggleLeftPanel();
    }

    showCreationTabs() {
        return (
            !this.loginService.getUser().roles.corporationTeacher &&
            !this.loginService.getUser().roles.tutor &&
            (!this.loginService.getUser().roles.externalTeacher ||
                this.loginService.getUser().additionalRoles.contentCreator)
        );
    }

    showAssignmentTabs() {
        return !this.loginService.getUser().roles.nationalTeacher;
    }

    showTabsContainer() {
        return (
            [this.showAssignmentTabs(), this.showCreationTabs(), this.showValidationTabs()].filter(
                Boolean
            ).length >= 2
        );
    }

    showValidationTabs() {
        return (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.internalTeacher ||
            this.loginService.getUser().roles.externalTeacher ||
            this.loginService.getUser().roles.siteTeacher
        );
    }
}
