import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LoginService } from '@/services/login.service';

@Component({
    selector: 'app-library-confirmation',
    templateUrl: './library-confirmation.component.html',
    styleUrls: ['./library-confirmation.component.scss'],
    standalone: false
})
export class LibraryConfirmationComponent implements OnInit {
    @Output() openConfirmationDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<LibraryConfirmationComponent>,
        private loginService: LoginService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    recursiveAction = false;
    comment = '';

    ngOnInit() {
        if (this.data.comment) {
            this.comment = this.data.comment;
        }
    }

    /**
     * Détermine le message à afficher dans le corps de la fenêtre
     * @returns {String} Le message à afficher dans le corps de la fenêtre
     */
    getMainMessage(): String {
        return this.data.message;
    }

    getSubMessage(): string {
        return this.data.submessage;
    }

    getTitle(): String {
        return this.data.title;
    }

    isAdmin(): boolean {
        return (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.nationalAdmin
        );
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Supprime le forum actuel
     */
    action() {
        if (this.recursiveAction) {
            this.dialogRef.close({ recursive: true, comment: this.comment });
        } else {
            this.dialogRef.close({ recursive: false, comment: this.comment });
        }
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close(false);
    }
}
