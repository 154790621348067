// Internal dependencies
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components
import { LeftContentComponent } from './left-content/left-content.component';
import { RightContentComponent } from './right-content/right-content.component';

// Services
import { LibraryService } from '@/services/library.service';

// Interfaces
import { ContentSelection, Content, PartialContent } from '@/structures/content-selection';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-content-selection',
    templateUrl: './content-selection.component.html',
    styleUrls: ['./content-selection.component.scss'],
    imports: [
        SharedModule,
        MatDialogModule,
        MatTabsModule,
        LeftContentComponent,
        RightContentComponent
    ]
})
export class ContentSelectionComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ContentSelectionComponent>,
        private libraryService: LibraryService
    ) {}

    activeTab: string = 'assemblies';
    activeKey: string;

    contentsList: ContentSelection;

    ngOnInit() {
        this.libraryService.getContentsSelection().subscribe((data: ContentSelection) => {
            this.contentsList = data;
        });
    }

    // #region Getters

    isSelectedTab(tab: string): boolean {
        return this.activeTab === tab;
    }

    getActiveContent(): Content {
        for (const section in this.contentsList) {
            if (Array.isArray(this.contentsList[section])) {
                for (const item of this.contentsList[section]) {
                    if (item.key === this.activeKey) {
                        return item;
                    }
                }
            } else if (typeof this.contentsList[section] === 'object') {
                const subSection = this.contentsList[section];
                for (const subArray in subSection) {
                    if (Array.isArray(subSection[subArray])) {
                        for (const item of subSection[subArray]) {
                            if (item.key === this.activeKey) {
                                return item;
                            }
                        }
                    }
                }
            }
        }
    }

    // #endregion Getters

    // #region Handlers

    handleClickSelectTab(tab: string): void {
        this.activeTab = tab;
        this.activeKey = undefined;
    }

    handleConfirm(): void {
        this.dialogRef.close({ key: this.activeKey, data: this.getActiveContent().data });
    }

    handleClickCloseDialog() {
        this.dialogRef.close();
    }

    // #endregion Handlers
}
