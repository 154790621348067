import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject, combineLatest } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { UserService } from '@/services/user.service';
import { GroupService } from '@/services/group.service';
import { LoadingService } from '@/services/loading.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { AssignmentService } from '@/services/assignment.service';

import { Assignment } from '@/structures/assignment';
import { User } from '@/structures/user';
import { Group } from '@/structures/group';
import { DevoirService } from '@/services/devoir.service';

import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-tracking',
    templateUrl: './user-tracking.component.html',
    styleUrls: ['./user-tracking.component.scss'],
    standalone: false
})
export class UserTrackingComponent implements OnInit {
    saveGroupDefinitiveNotes$: Subject<boolean> = new Subject();
    openUploadDevoir$: Subject<{
        assignment: Assignment;
        user?: User;
        group?: Group;
    }> = new Subject();
    openDevoirNewAttemptConfirmation$: Subject<User | boolean> = new Subject();
    openAnonymousConfirmationDialog$: Subject<{ allDevoirsReady: boolean }> = new Subject();
    teacherChoiceForAnonymousAssignation$: Subject<
        boolean | { missingNotes: 0 | null }
    > = new Subject();

    subscriptions = new Subscription();

    tracking: any;
    notes: any;
    assignment: Assignment;
    saveNotesAsDefinitive = false;

    searchInput: string;

    constructor(
        public dialogRef: MatDialogRef<UserTrackingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: UserService,
        private groupService: GroupService,
        private loadingService: LoadingService,
        private assignmentService: AssignmentService,
        private devoirService: DevoirService,
        private flashMessageService: FlashMessageService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.tracking = null;
        this.notes = {};
        this.assignment = this.data.assignment;
        this.searchInput = '';
        this.loadingService.startLoading('mainViewUserTracking', 'getWorkTracking');
        if (!this.isGroupAssignment()) {
            this.subscriptions.add(
                this.userService.getUserDevoirTracking(this.data.assignment.assignmentId).subscribe(
                    (data: any) => {
                        this.tracking = data.map((user) => {
                            if (
                                user.hasOwnProperty('temporaryScore') &&
                                !user.hasOwnProperty('temporary_score')
                            ) {
                                return {
                                    ...user,
                                    temporary_score: user.temporaryScore
                                };
                            }
                            return user;
                        });
                        this.loadingService.stopLoading('mainViewUserTracking', 'getWorkTracking');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('addDisponibilityDialog');
                    }
                )
            );
        } else {
            this.subscriptions.add(
                this.groupService
                    .getGroupDevoirTracking(this.data.assignment.assignmentId)
                    .subscribe(
                        (data: any) => {
                            this.tracking = data.map((user) => {
                                if (
                                    user.hasOwnProperty('temporaryScore') &&
                                    !user.hasOwnProperty('temporary_score')
                                ) {
                                    return {
                                        ...user,
                                        temporary_score: user.temporaryScore
                                    };
                                }
                                return user;
                            });
                            if (this.tracking.some((user) => user.score !== null)) {
                                this.saveNotesAsDefinitive = true;
                            }
                            this.loadingService.stopLoading(
                                'mainViewUserTracking',
                                'getWorkTracking'
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('addDisponibilityDialog');
                        }
                    )
            );
            this.subscriptions.add(
                this.saveGroupDefinitiveNotes$.subscribe((confirmed: boolean) => {
                    if (confirmed) {
                        this.saveDefinitiveNotes();
                    }
                })
            );
            this.subscriptions.add(
                this.teacherChoiceForAnonymousAssignation$.subscribe(
                    (choice: boolean | { missingNotes: 0 | null }) => {
                        if (!choice) {
                            this.dialogRef.close();
                        } else if (choice === true) {
                            this.saveDefinitiveNotes();
                        } else if (choice.missingNotes === 0) {
                            this.giveZeroToMissingNotes();
                        } else if (choice.missingNotes === null) {
                            this.giveNoNoteToMissingNotes();
                        }
                    }
                )
            );
        }
    }

    reminderWork(user) {
        let assignmentId;
        for (let i = 0; i < this.data.assignment.users_status.length; i++) {
            if (this.data.assignment.users_status[i].id === +user.userid) {
                assignmentId = this.data.assignment.users_status[i].assignmentId;
                break;
            }
        }
        this.subscriptions.add(
            this.assignmentService.remindAssignment(assignmentId).subscribe(
                (res: boolean) => {
                    this.flashMessageService.flash('Email envoyé');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('addDisponibilityDialog');
                }
            )
        );
    }

    /**
     * ouvre la popup de confirmation de nouvelle tentative.
     * @param user l'apprenant concerné
     */
    giveNewAttempt(user: User): void {
        this.openDevoirNewAttemptConfirmation$.next(user);
    }

    /**
     * Au retour confirmé de la popup de confirmation de nouvelle tentative,
     * le tracking est réinitialiser pour autoriser un nouveau dépôt
     * @param user l'apprenant concerné
     */
    onConfirmNewAttempt(user) {
        this.subscriptions.add(
            this.userService.removeTracking(user.userid, this.assignment).subscribe(() => {
                this.flashMessageService.flash(
                    'Le contenu <b>' + this.assignment.title + '</b> a été réinitialisé'
                );
                this.assignment.tracking.status = 'not attempted';
                this.assignment.tracking.score = undefined;
                this.dialogRef.close();
            })
        );
    }

    getDevoirDates(param) {
        return new Date(new Date(param).getTime() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, -1);
    }

    getNumberOfCompleted() {
        let number = 0;
        if (this.tracking) {
            for (let i = 0; i < this.tracking.length; i++) {
                if (!!this.tracking[i].submitted_file) {
                    number++;
                }
            }
        }
        return number;
    }

    inSearchResult(user) {
        const reg = new RegExp(this.accentFold(this.searchInput), 'i');

        const firstLast = this.accentFold(user.firstname + ' ' + user.lastname.toUpperCase());
        const lastFirst = this.accentFold(user.lastname.toUpperCase() + ' ' + user.firstname);

        if (firstLast.search(reg) > -1 || lastFirst.search(reg) > -1) {
            return true;
        }

        return false;
    }

    accentFold(string: string) {
        return string.replace(
            /([àáâãäå])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
            function (str, a, c, e, i, n, o, s, u, y, ae) {
                if (a) {
                    return 'a';
                } else if (c) {
                    return 'c';
                } else if (e) {
                    return 'e';
                } else if (i) {
                    return 'i';
                } else if (n) {
                    return 'n';
                } else if (o) {
                    return 'o';
                } else if (s) {
                    return 's';
                } else if (u) {
                    return 'u';
                } else if (y) {
                    return 'y';
                } else if (ae) {
                    return 'ae';
                }
            }
        );
    }

    cancelSearch() {
        this.searchInput = '';
    }

    /**
     * retourne true si une note est en cours d'édition pour l'apprenant
     */
    isBeingEvaluated(userId: number) {
        return this.notes[userId] !== undefined ? true : false;
    }

    /**
     * vérifie qu'un utilisateur est prêt à être noté
     */
    isReadyToBeNoted(user: any): boolean {
        return (
            user.status === 'completed' &&
            user.submitted_file &&
            !this.isBeingEvaluated(user.userid) &&
            user.score === null &&
            user.temporary_score === null
        );
    }

    /**
     * disable le bouton de reveal si un apprenant a rendu son devoir mais n'a pas encore été noté
     */
    isRevealButtonDisabled(): boolean {
        if (this.tracking) {
            return this.tracking.some((userTracking) => this.isReadyToBeNoted(userTracking));
        }
        return true;
    }

    isGroupAssignment(): boolean {
        return !this.data.target.firstname;
    }

    /**
     * Au clic sur le bouton, une note est crée dans l'objet $scope.notes
     * si l'user a une note temporaire, elle est utilisée, sinon la note est init à 50
     */
    createNoteInput(userClickedId: number, initialScore?: number) {
        if (this.isAnonymous() && this.data.assignment.is_revealed) {
            return;
        }
        if (!this.notes[userClickedId]) {
            if (initialScore !== undefined) {
                this.notes[userClickedId] = +initialScore;
            } else {
                this.notes[userClickedId] = 50;
            }
        }
    }

    increaseNote(userId: number) {
        this.notes[userId] += 1;
        this.checkLimits(userId);
    }

    decreaseNote(userId: number) {
        this.notes[userId] -= 1;
        this.checkLimits(userId);
    }

    checkLimits(userId: number): void {
        if (this.notes[userId] < 0) {
            this.notes[userId] = 0;
        } else if (this.notes[userId] > 100) {
            this.notes[userId] = 100;
        }
    }

    onNoteChange(event, userId: number) {
        if (isNaN(event.target.value)) {
            this.notes[userId] = this.notes[userId];
            return;
        } else if (event.target.value < 0) {
            this.notes[userId] = 0;
            event.target.value = 0;
        } else if (event.target.value > 100) {
            this.notes[userId] = 100;
            event.target.value = 100;
        } else {
            this.notes[userId] = Number(event.target.value);
        }
    }

    toggleNotesDefinitive(): void {
        this.saveNotesAsDefinitive = !this.saveNotesAsDefinitive;
    }

    /**
     * Si le tableau de notes est vide (notes non modifiées) => grade
     * sinon, enregistrement des notes déf ou temp, puis grade()
     */
    saveNotes() {
        if (!this.isGroupAssignment()) {
            this.saveIndividualDevoirNote();
        } else if (Object.entries(this.notes).length === 0) {
            this.gradeGroup();
        } else if (this.saveNotesAsDefinitive) {
            this.saveDefinitiveNotes();
        } else {
            this.saveTemporaryNotes();
        }
    }

    sendReminder() {
        const params = {
            enrolmentSource: 'individual',
            assignmentId: this.assignment.assignmentId
        };
        this.subscriptions.add(
            this.assignmentService.remindAssignment(params).subscribe(() => {
                this.flashMessageService.flash('Email envoyé');
            })
        );
    }

    /**
     * Ouvre une popup pour confirmer la levée de l'anonymat
     * + message spécial si présence de copies non rendues/notées
     */
    openAnonymousRevealConfirmation(): void {
        if (this.notes.length === this.assignment.users) {
            this.openAnonymousConfirmationDialog$.next({ allDevoirsReady: true });
        } else {
            this.openAnonymousConfirmationDialog$.next({ allDevoirsReady: false });
        }
    }

    /**
     * Funcking save note for individual learner
     */
    saveIndividualDevoirNote() {
        this.subscriptions.add(
            this.userService
                .noteDevoirTemporary(
                    this.tracking[0].userid,
                    this.assignment.id,
                    this.notes[this.tracking[0].userid]
                )
                .subscribe((response: any) => {
                    this.gradeUser();
                })
        );
    }

    /**
     * Enregistre les notes en tant que temporaryScore
     */
    saveTemporaryNotes() {
        const isThereANotealready = this.tracking.some((user) => user.score !== null);
        if (isThereANotealready) {
            this.subscriptions.add(
                this.devoirService
                    .gradeGroupWork(this.assignment.assignmentId, 'cancel')
                    .subscribe((response: any) => {
                        const observableArray = [];

                        for (const i in this.notes) {
                            if (!isNaN(Number(this.notes[i]))) {
                                observableArray.push(
                                    this.userService.noteDevoirTemporary(
                                        i,
                                        this.assignment.id,
                                        this.notes[i]
                                    )
                                );
                            }
                        }

                        this.subscriptions.add(
                            combineLatest(observableArray).subscribe(
                                () => {
                                    this.loadingService.stopLoading(
                                        'userTrackingButton',
                                        'saveNotes'
                                    );
                                    this.dialogRef.close(true);
                                },
                                (error: HttpErrorResponse) => {
                                    this.loadingService.stopLoading('userTrackingButton');
                                }
                            )
                        );
                    })
            );
        } else {
            const observableArray = [];
            for (const i in this.notes) {
                if (!isNaN(this.notes[i])) {
                    observableArray.push(
                        this.userService.noteDevoirTemporary(i, this.assignment.id, this.notes[i])
                    );
                }
            }
            if (observableArray.length === 0) {
                this.saveNotesAsDefinitive = true;
                this.gradeGroup();
            }

            this.subscriptions.add(
                combineLatest(observableArray).subscribe(
                    () => {
                        this.loadingService.stopLoading('userTrackingButton', 'saveNotes');
                        this.dialogRef.close(true);
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('userTrackingButton');
                    }
                )
            );
        }
    }

    gradeGroup(giveZeroToMissingNotes?: 'zero' | 'noNote') {
        if (this.isGroupAssignment) {
            this.devoirService
                .gradeGroupWork(
                    this.assignment.assignmentId,
                    this.saveNotesAsDefinitive ? 'grade' : 'cancel',
                    giveZeroToMissingNotes
                )
                .subscribe((response: any) => {
                    this.dialogRef.close(true);
                });
        }
    }

    gradeUser() {
        this.subscriptions.add(
            this.devoirService
                .gradeUserWork(this.assignment.assignmentId, 'grade')
                .subscribe((data: any) => {
                    this.flashMessageService.flash('La note a bien été enregistrée');
                    this.dialogRef.close();
                })
        );
    }

    giveZeroToMissingNotes() {
        this.saveTemporaryNotes();
        this.saveNotesAsDefinitive = true;
        this.gradeGroup('zero');
    }

    giveNoNoteToMissingNotes() {
        this.saveTemporaryNotes();
        this.saveNotesAsDefinitive = true;
        this.gradeGroup('noNote');
    }

    /**
     * enregistre les notes définitives
     */
    saveDefinitiveNotes() {
        const observableArray = [];
        for (const i in this.notes) {
            if (!isNaN(this.notes[i])) {
                observableArray.push(
                    this.userService.noteDevoirTemporary(i, this.assignment.id, this.notes[i])
                );
            }
        }
        if (observableArray.length === 0) {
            this.saveNotesAsDefinitive = true;
            this.gradeGroup();
        }

        this.subscriptions.add(
            combineLatest(observableArray).subscribe(
                () => {
                    this.loadingService.stopLoading('userTrackingButton', 'saveNotes');
                    // this.dialogRef.close(this.notes);
                    this.saveNotesAsDefinitive = true;
                    this.gradeGroup();
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('userTrackingButton');
                }
            )
        );
    }

    getDownloadAllDevoirsLink(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            this.groupService.getGroupDevoirDownloadAllLink(this.data.assignment.assignmentId)
        );
    }

    /**
     * ouvre la modale d'upload de corrigé de devoir individuel ou collectif
     * @param user (optionnel) user dans le cas d'un corrigé individuel
     */
    openUploadDevoirCorrection(user?: User): void {
        if (user) {
            this.openUploadDevoir$.next({
                assignment: this.assignment,
                user
            });
        } else {
            this.openUploadDevoir$.next({
                assignment: this.assignment,
                group: this.data.target
            });
        }
    }

    openCorrectionFile(url: string): void {
        window.open(url, '_blank');
    }

    isAnonymous() {
        return this.isGroupAssignment() && this.data.assignment.is_anonymous;
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
