// Internal dependencies
import { Component, OnInit, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

// External dependencies

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@Component({
    selector: 'app-assembly-confirmation-entry',
    templateUrl: './assembly-confirmation-entry.component.html',
    styleUrls: ['./assembly-confirmation-entry.component.scss'],
    imports: [SharedModule, MatExpansionModule]
})
export class AssemblyConfirmationEntryComponent implements OnInit {
    @Input() content: Content;
    @Input() mode: string;

    opened: boolean;

    constructor(private libraryService: LibraryService) {}

    ngOnInit() {
        this.opened = true;
    }

    toggleIcon() {
        this.opened = !this.opened;
    }

    getIcon(): string {
        if (this.content.status === 'approved') {
            return this.libraryService.getIcon(this.content);
        } else {
            return this.libraryService.getDraftIcon(this.content);
        }
    }

    getContentClass(): string {
        if (this.content.status !== 'approved') {
            switch (this.mode) {
                case 'delete': {
                    return 'red';
                }
                case 'publish': {
                    return 'green';
                }
                case 'copy': {
                    return 'blue';
                }
            }
        }
    }

    isAssembly(): boolean {
        return !!this.content.level;
    }
}
