import { Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { AdminService } from '@/services/admin.service';
import { LoadingService } from '@/services/loading.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-create-domain',
    templateUrl: './create-domain.component.html',
    styleUrls: ['./create-domain.component.scss'],
    standalone: false
})
export class CreateDomainComponent implements OnInit {
    private subscriptions$: Subscription = new Subscription();

    domain: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<CreateDomainComponent>,
        private adminService: AdminService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        if (this.data) {
            this.domain = this.data.name;
        }
    }

    getTitle() {
        if (!this.data) {
            return 'Créer un domaine';
        } else {
            return 'Modifier un domaine';
        }
    }

    getFooter() {
        if (!this.data) {
            return 'Créer';
        } else {
            return 'Modifier';
        }
    }

    isLoading() {
        return this.loadingService.isLoading('createDomain');
    }

    action() {
        let action: any;
        this.loadingService.startLoading('createDomain');
        if (this.data) {
            action = this.adminService.updateDomain(this.data.name, this.domain);
        } else {
            action = this.adminService.createDomain(this.domain);
        }
        this.subscriptions$.add(
            action.subscribe(
                () => {
                    this.loadingService.stopLoading('createDomain');
                    this.dialogRef.close(this.domain);
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('createDomain');
                }
            )
        );
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
