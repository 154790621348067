// Internal dependencies
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    input,
    output,
    model
} from '@angular/core';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { MatExpansionModule } from '@angular/material/expansion';
import { DndModule } from 'ngx-drag-drop';
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { AssignmentService } from '@/services/assignment.service';
import { DialogService } from '@/services/dialog.service';
import { FavoriteService } from '@/services/favorite.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { LibraryService } from '@/services/library.service';
import { LoginService } from '@/services/login.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-creation-space-entry',
    templateUrl: './creation-space-entry.component.html',
    styleUrls: ['./creation-space-entry.component.scss'],
    imports: [MatExpansionModule, DndModule, SharedModule]
})
export class CreationSpaceEntryComponent implements OnInit {
    draft = model<Content>();
    parent = model<Content>();
    @Input() type: string;
    @Input() isEditingChildrenOrder: boolean;
    @Input() locked = false;

    clonedDraft = output<{ draft: Content; type: string }>();
    deletedDraft = output<{ draft: Content; type: string }>();

    initialDraft: Content;
    editOrder: boolean;
    opened: boolean;

    constructor(
        private assignmentService: AssignmentService,
        private dialogService: DialogService,
        private favoriteService: FavoriteService,
        private flashMessageService: FlashMessageService,
        private libraryService: LibraryService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.editOrder = false;
        if (this.draft().status === 'approved') {
            this.locked = true;
        }
        if (this.parent()) {
            this.draft().parentid = this.parent().id;
        } else {
            this.draft().parentid = undefined;
        }
        this.libraryService.foldAll.subscribe(() => {
            this.opened = false;
        });
    }

    //#region Getters

    displayIcon(icon: string) {
        switch (icon) {
            case 'fiche': {
                return true;
            }
            case 'guide': {
                return this.draft().guide;
            }
            case 'ordoOn': {
                return (
                    !this.isLocked() &&
                    !this.isActivity() &&
                    !this.editOrder &&
                    this.draft().children &&
                    this.draft().children.length > 1
                );
            }
            case 'ordoOff': {
                return (
                    !this.isLocked() &&
                    this.draft().ordered &&
                    !this.isActivity() &&
                    !this.editOrder
                );
            }
            case 'clone':
            case 'bin':
            case 'publish': {
                return !this.locked;
            }
            case 'send-to-validation': {
                return (
                    !(
                        this.loginService.getUser().additionalRoles.validator ||
                        this.loginService.getUser().roles.localAdmin ||
                        this.loginService.getUser().roles.nationalAdmin ||
                        this.loginService.getUser().roles.nationalTeacher ||
                        this.loginService.getUser().roles.siteTeacher ||
                        this.draft().type === 'devoir'
                    ) && this.draft().quizIsPublishable !== false
                );
            }
            case 'historique': {
                return this.draft().rejected;
            }
            case 'add-child': {
                return !this.isActivity() && !this.isLocked() && this.draft().level !== 'sequence';
            }
            case 'remove-child': {
                return this.parent() && this.parent().status !== 'approved';
            }
            case 'add-to-favorites': {
                return !this.draft().favorite;
            }
            case 'remove-from-favorites': {
                return this.draft().favorite;
            }
            case 'view-activity': {
                return this.isActivity();
            }
            case 'view-ordered': {
                return this.draft().ordered;
            }
            case 'view-locked': {
                return this.locked;
            }
            case 'view-favorites': {
                return this.draft().favorite;
            }
        }
    }

    getFicheTooltip(): string {
        if (!this.isLocked()) {
            return 'Editer la fiche';
        } else {
            return 'Voir la fiche';
        }
    }

    getTypeLabel(): string {
        return this.libraryService.getTypeLabel(this.draft());
    }

    getIcon(): string {
        if (this.draft().status === 'approved') {
            return this.libraryService.getIcon(this.draft());
        } else {
            return this.libraryService.getDraftIcon(this.draft());
        }
    }

    getDraftTooltip(): string {
        return `<strong>Titre : </strong>${this.draft().title}</br>
        ${this.draft().id ? '<strong>ID : </strong>' : ''}${this.draft().id || ''}${
            this.draft().ucode ? '<strong> Code : </strong>' : ''
        }${this.draft().ucode || ''}</br>
        ${this.draft().description ? '<strong>Description : </strong>' : ''}${
            this.draft().description || ''
        }</br>
        ${this.draft().duration}`;
    }

    getForbiddenDropTooltipMessage(): string {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (!this.canDropContent()) {
                if (this.assignmentService.getDraggedElement().id === this.draft().id) {
                    return;
                }
                if (
                    this.assignmentService.getDraggedElement().hasprice === 2 &&
                    !this.loginService.getUser().roles.nationalAdmin
                ) {
                    return 'Vous ne pouvez pas utiliser les enfants de contenus payants dans vos créations';
                }
                if (this.locked) {
                    return 'Vous ne pouvez rien déposer sur un contenu issu de la bibliothèque';
                }
                switch (this.draft().category) {
                    case 'positionnement':
                    case 'preparation':
                    case 'ressource':
                    case 'travail':
                    case 'evaluation':
                    case 'corrige':
                    case 'tp':
                    case 'presentiel':
                    case 'guide':
                    case 'url':
                    case 'devoir':
                        return 'Vous ne pouvez rien déposer sur une activité';
                    default: {
                        switch (this.draft().level) {
                            case 'parcours':
                                return "Seuls les blocs, les compétences et les modules peuvent être déposés à la racine d'un parcours";
                            case 'bloc':
                                return "Seuls les compétences et les modules peuvent être déposés à la racine d'un bloc";
                            case 'competence':
                                return "Seuls les modules, les séquences et les activités peuvent être déposés à la racine d'une compétence";
                            case 'module':
                                return 'Seules les séquences et les activités peuvent être déposées dans un module';
                            case 'sequence':
                                return 'Seules les activités peuvent être déposées dans une séquence';
                            case 'activity':
                                return 'Vous ne pouvez rien déposer sur une activité.';
                            default:
                                return undefined;
                        }
                    }
                }
            } else {
                return undefined;
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            if (!this.canDropContent()) {
                return 'Impossible de déplacer ce contenu ici';
            }
        }
    }

    canDropContent() {
        if (this.assignmentService.getDraggedElement()) {
            if (
                this.assignmentService.getDraggedMode() === 'MOVE' &&
                this.assignmentService.getDraggedElement().desiredPublicationMode !== 'new' &&
                this.assignmentService.getDraggedElement().id === this.draft().id
            ) {
                return false;
            } else {
                return (
                    this.assignmentService.canBeChild(this.draft()) &&
                    !this.locked &&
                    !(
                        this.assignmentService.getDraggedElement().hasprice === 2 &&
                        !this.loginService.getUser().roles.nationalAdmin
                    )
                );
            }
        } else {
            return true;
        }
    }

    isActivity(): boolean {
        return !this.draft().level;
    }

    isFirstElement(index: number): boolean {
        return index === 0;
    }

    isLocked() {
        return this.locked;
    }

    //#endregion Getters

    //#region Handlers

    handleClickIncreaseOrder($event: any) {
        $event.stopImmediatePropagation();
        this.draft().orderNumber++;
    }

    handleClickDecreaseOrder($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft().orderNumber > 1) {
            this.draft().orderNumber--;
        }
    }

    handleClickViewFiche($event: any) {
        $event.stopImmediatePropagation();
        if (!this.isLocked()) {
            this.libraryService.getContent(this.draft().id).subscribe((content: Content) => {
                this.dialogService.openContentCreation(content).subscribe((data: Content) => {
                    if (data !== undefined) {
                        this.draft.set({ ...this.draft(), ...data });
                    }
                });
            });
        } else {
            this.dialogService.openContentDetails(this.draft().id);
        }
    }

    handleClickViewGuide($event: any) {
        $event.stopImmediatePropagation();
        if (this.draft().guide) {
            const popup = window.open(this.draft().guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    handleClickToggleOrdoOn($event: any) {
        $event.stopImmediatePropagation();
        this.initialDraft = JSON.parse(JSON.stringify(this.draft()));
        this.opened = true;
        this.draft.set({
            ...this.draft(),
            ordered: true,
            children: this.draft().children.map((child: Content, index: number) => ({
                ...child,
                orderNumber: index + 1
            }))
        });
        this.editOrder = true;
    }

    handleClickToggleOrdoOff($event: any) {
        $event.stopImmediatePropagation();
        this.draft.set({
            ...this.draft(),
            ordered: false,
            children: this.draft().children.map((child: Content) => {
                delete child.orderNumber;
                return child;
            })
        });
        this.libraryService.updateAssembly(this.draft(), undefined).subscribe(() => {
            this.editOrder = false;
            this.flashMessageService.flash("L'ordonnancement du contenu a été supprimé");
        });
    }

    handleClickCloneDraft($event: any) {
        $event.stopImmediatePropagation();
        if (this.isActivity()) {
            this.libraryService.duplicateDraft(this.draft()).subscribe((data: any) => {
                this.clonedDraft.emit({
                    draft: {
                        ...this.draft(),
                        id: data.id,
                        title: 'Copie de ' + this.draft().title,
                        favorite: false
                    },
                    type: this.type
                });
                this.flashMessageService.flash(
                    "Le contenu a été dupliqué dans l'espace de création"
                );
            });
        } else {
            this.dialogService
                .openAssemblyConfirmation(this.draft(), 'copy')
                .subscribe((confirmed: boolean) => {
                    if (confirmed) {
                        this.libraryService.duplicateDraft(this.draft()).subscribe((data: any) => {
                            this.libraryService.emitAddedDraft(data);
                            this.flashMessageService.flash(
                                "Le contenu a été dupliqué dans l'espace de création"
                            );
                        });
                    }
                });
        }
    }

    handleClickDeleteDraft($event: any) {
        $event.stopImmediatePropagation();
        if (this.isActivity()) {
            this.dialogService
                .openConfirmationDialog(
                    'Supprimer le brouillon',
                    'Etes-vous sûr de vouloir supprimer le brouillon "' + this.draft().title + '" ?'
                )
                .subscribe((data: boolean) => {
                    if (data) {
                        this.libraryService.deleteDraft(this.draft()).subscribe(() => {
                            this.deletedDraft.emit({ draft: this.draft(), type: this.type });
                            this.flashMessageService.flash('Le contenu a été supprimé');
                        });
                    }
                });
        } else {
            this.dialogService
                .openAssemblyConfirmation(this.draft(), 'delete')
                .subscribe((confirmed: boolean) => {
                    if (confirmed) {
                        this.libraryService.deleteDraft(this.draft()).subscribe(() => {
                            this.deletedDraft.emit({ draft: this.draft(), type: this.type });
                            this.flashMessageService.flash('Le contenu a été supprimé');
                        });
                    }
                });
        }
    }

    handleClickPublishDraft($event: any) {
        $event.stopImmediatePropagation();

        if (this.isActivity()) {
            this.handlePublishDraftFlow(this.draft(), this.type);
        } else {
            this.handlePublishDraftFlowForNonActivity(this.draft(), this.type);
        }
    }

    handleClickSendDraftToValidation($event: any) {
        $event.stopImmediatePropagation();

        if (this.isActivity()) {
            this.handleSendDraftToValidationFlow(this.draft(), this.type);
        } else {
            this.handleSendDraftToValidationFlowForNonActivity(this.draft(), this.type);
        }
    }

    handleClickOpenHistory($event: any) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentHistory(this.draft());
    }

    handleClickAddChild($event: any) {
        $event.stopImmediatePropagation();
        const levelArray = ['parcours', 'bloc', 'competence', 'module', 'sequence'];
        const currentLevel = levelArray.indexOf(this.draft().level);
        const disabledLevel = levelArray.slice(0, currentLevel + 1);
        if (levelArray[currentLevel] === 'bloc' || levelArray[currentLevel] === 'parcours') {
            disabledLevel.push('sequence');
        }
        const position = this.assignmentService.calculatePosition(
            this.draft(),
            this.draft().children.length
        );
        const content = {
            ...this.draft(),
            parentid: this.draft().id,
            level: levelArray[currentLevel + 1],
            disabledLevel: disabledLevel,
            ordered: false,
            position
        };
        delete content.id;
        delete content.children;
        delete content.basedOn;

        this.dialogService.openContentCreation(content).subscribe((createdContent: Content) => {
            // rustine car le back ne le renvoie pas.
            if (!createdContent.position) {
                createdContent.position = content.position;
            }
            this.draft().children.push(createdContent);
            this.opened = true;
        });
    }

    handleClickRemoveChild($event: any) {
        $event.stopImmediatePropagation();
        const tmpContent = {
            ...this.parent(),
            children: this.parent().children.filter(
                (child: Content) => child.id !== this.draft().id
            )
        };
        this.libraryService.updateAssembly(tmpContent, undefined).subscribe(() => {
            if (this.draft().status === 'draft') {
                this.libraryService.emitAddedDraft(this.draft());
            }
            this.parent.set(tmpContent);
            this.flashMessageService.flash("Le contenu a été retiré de l'assemblage");
        });
    }

    handleClickAddToFavorites($event): void {
        $event.stopImmediatePropagation();
        this.favoriteService.addContentToFavorites(this.draft()).subscribe((data: any) => {
            this.draft().favorite = true;
            this.flashMessageService.flash(
                `Le contenu ${this.draft().title} a été ajouté à vos favoris`
            );
        });
    }

    handleClickRemoveFromFavorites($event: any): void {
        $event.stopImmediatePropagation();
        this.favoriteService.removeContentFromFavorites(this.draft()).subscribe((data: any) => {
            this.draft().favorite = false;
            this.flashMessageService.flash(
                `Le contenu ${this.draft().title} a été retiré de vos favoris`
            );
        });
    }

    handleClickOpenActivity($event): void {
        $event.stopImmediatePropagation();
        this.libraryService.openActivity(this.draft());
    }

    handleDropContent(index) {
        if (!this.canDropContent()) {
            return;
        }
        const element = {
            ...this.assignmentService.getDraggedElement()
        };
        if (!this.draft().ordered && element.orderNumber) {
            delete element.orderNumber;
        }

        // Content from library
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            this.handleEndDrag();
            this.addChildToDraft(element, index);
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            this.handleEndDrag();
            // Content from same parent
            if (element.parentid === this.draft().id) {
                if (index === undefined) {
                    index = this.draft().children.length;
                }
                this.updateChildrenPosition(element, index);
                // Content from another parent
            } else if (element.parentid) {
                this.libraryService.getContent(element.parentid).subscribe((parent: Content) => {
                    if (parent.status !== 'draft') {
                        return;
                    }
                    parent.children = [
                        ...parent.children.filter((child) => child.id !== element.id)
                    ];
                    this.libraryService.updateAssembly(parent, undefined).subscribe((data: any) => {
                        this.addChildToDraft(element, index);

                        // TODO Remove content from previous parent
                    });
                });
                // Content from no parent
            } else {
                this.addChildToDraft(element, index);
            }
        }
    }

    handleStartDrag() {
        this.assignmentService.setDraggedElement(this.draft());
        this.assignmentService.setDraggedMode('MOVE');
    }

    handleEndDrag() {
        this.assignmentService.clearDraggedElement();
        this.assignmentService.clearDraggedMode();
    }

    handleConfirmOrder($event: any) {
        this.initialDraft = undefined;
        $event.stopImmediatePropagation();
        this.libraryService.updateAssembly(this.draft(), undefined).subscribe((data: any) => {
            this.editOrder = false;
            this.flashMessageService.flash("L'ordonnancement du contenu a été mis à jour");
        });
    }

    handleCancelOrder($event) {
        $event.stopImmediatePropagation();
        this.draft().children.map((child: Content) => {
            this.initialDraft.children.map((initialChild: Content) => {
                if (child.id === initialChild.id) {
                    if (initialChild.orderNumber) {
                        child.orderNumber = initialChild.orderNumber;
                    } else {
                        delete child.orderNumber;
                    }
                }
            });
            return child;
        });
        if (this.initialDraft.ordered) {
            this.draft().ordered = true;
        } else {
            this.draft().ordered = false;
        }
        this.initialDraft = undefined;
        this.editOrder = false;
    }

    //#endregion Handlers

    //#region Internals

    private handlePublishDraftFlow(draft: any, type: string): void {
        if (draft.publishable) {
            this.dialogService.openSelectPublication(draft).subscribe((data: any) => {
                if (data) {
                    this.processPublication(data, draft, type);
                }
            });
        } else {
            this.openContentCreationAndPublish(draft, type);
        }
    }

    private handlePublishDraftFlowForNonActivity(draft: any, type: string): void {
        if (draft.publishable) {
            this.dialogService
                .openAssemblyConfirmation(draft, 'publish')
                .subscribe((confirmed: boolean) => {
                    if (confirmed) {
                        this.dialogService.openSelectPublication(draft).subscribe((data: any) => {
                            if (data) {
                                this.processPublication(data, draft, type);
                            }
                        });
                    }
                });
        } else {
            this.openContentCreationAndPublish(draft, type);
        }
    }

    private handleSendDraftToValidationFlow(draft: any, type: string): void {
        if (draft.publishable) {
            this.dialogService.openSelectValidator(draft).subscribe((data: any) => {
                if (data) {
                    this.dialogService
                        .openSelectPublication(draft)
                        .subscribe((publicationMode: any) => {
                            if (data && publicationMode) {
                                this.processValidation(draft, data, publicationMode);
                            }
                        });
                }
            });
        } else {
            this.openContentCreationAndValidate(draft);
        }
    }

    private handleSendDraftToValidationFlowForNonActivity(draft: any, type: string): void {
        if (draft.publishable) {
            this.dialogService.openSelectValidator(draft).subscribe((data: any) => {
                if (data) {
                    this.dialogService
                        .openSelectPublication(draft)
                        .subscribe((publicationMode: any) => {
                            if (data && publicationMode) {
                                this.processValidation(draft, data, publicationMode);
                            }
                        });
                }
            });
        } else {
            this.openContentCreationAndValidate(draft);
        }
    }

    private openContentCreationAndPublish(draft: any, type: string): void {
        this.dialogService
            .openContentCreation({ ...draft, with_publish_option: true })
            .subscribe((content: Content) => {
                if (content !== undefined) {
                    draft.set({ ...content });
                }
                if (draft.publishable) {
                    this.dialogService.openSelectPublication(draft).subscribe((data: any) => {
                        if (data) {
                            this.processPublication(data, draft, type);
                        }
                    });
                }
            });
    }

    private openContentCreationAndValidate(draft: any): void {
        this.dialogService
            .openContentCreation({ ...draft, with_publish_option: true })
            .subscribe((content: Content) => {
                if (content !== undefined) {
                    draft.set({ ...content });
                }
                if (draft.publishable) {
                    this.dialogService.openSelectValidator(draft).subscribe((data: any) => {
                        if (data) {
                            this.dialogService
                                .openSelectPublication(draft)
                                .subscribe((publicationMode: any) => {
                                    if (data && publicationMode) {
                                        this.processValidation(draft, data, publicationMode);
                                    }
                                });
                        }
                    });
                }
            });
    }

    private processPublication(data: any, draft: any, type: string): void {
        draft.publicationMode = data.publicationMode;
        this.libraryService.approveContent(draft, data.comment).subscribe(() => {
            this.flashMessageService.flash('Le contenu a été publié dans la bibliothèque');
            if (this.parent()) {
                this.locked = true;
            } else {
                this.deletedDraft.emit({ draft, type });
            }
            this.libraryService.emitRefreshLibrary();
        });
    }

    private processValidation(draft: any, data: any, publicationMode: any): void {
        const params = {
            id: draft.id,
            publicationMode: publicationMode.publicationMode,
            validators: data.validators,
            urgent: data.urgent,
            comment: data.comment
        };
        this.libraryService.sendContentToValidation(params).subscribe(() => {
            this.flashMessageService.flash('Le contenu a été communiqué au(x) valideur(s)');
            this.deletedDraft.emit({ draft, type: this.type });
        });
    }

    private updateChildrenPosition(element, index) {
        const position = this.assignmentService.calculatePosition(this.draft(), index, element);
        if (position) {
            this.draft().children.map((data: Content) => {
                if (data.id === element.id) {
                    data.position = position;
                }
                return data;
            });
            this.libraryService.updateAssembly(this.draft(), undefined).subscribe(() => {
                this.flashMessageService.flash('Le contenu a été déplacé');
            });
        }
    }

    private addChildToDraft(element, index) {
        const body = JSON.parse(JSON.stringify(this.draft()));
        if (index === undefined) {
            index = this.draft().children.length;
        }
        element.position = this.assignmentService.calculatePosition(this.draft(), index);
        if (this.draft().ordered) {
            element.orderNumber = 1;
        }
        body.children.push(element);
        this.libraryService.updateAssembly(body, undefined).subscribe(() => {
            this.opened = true;
            if (element.status === 'draft') {
                this.draft().children.push(element);
                this.draft().children.sort((a: Content, b: Content) => a.position - b.position);

                if (element.parentid) {
                    this.parent.set({ ...this.parent, children: [...body.children] });
                } else {
                    this.deletedDraft.emit({ draft: element, type: this.type });
                }
            }
            this.flashMessageService.flash("Le contenu a été ajouté dans l'assemblage");
        });
    }

    //endregion Internals
}
