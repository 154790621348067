import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigService } from '@/services/config.service';
import { DialogService } from '@/services/dialog.service';
import { ReportingService } from '@/services/reporting.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { AssignmentService } from '@/services/assignment.service';
import { LoginService } from 'src/app/services/login.service';

import { User } from '@/structures/user';
import { Assignment } from 'src/app/structures/assignment';

import { MoreActionsDirective } from '@/components/teacher/library/library-entry/more-actions/more-actions.directive';

import * as FileSaver from 'file-saver';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    providers: [MoreActionsDirective],
    standalone: false
})
export class UserComponent {
    @Input() user: User;

    @Output() favoriteAdded: EventEmitter<any> = new EventEmitter();
    @Output() favoriteRemoved: EventEmitter<any> = new EventEmitter();
    private subscriptions$: Subscription = new Subscription();

    className = 'group-' + Math.floor(Math.random() * 999999);

    constructor(
        private configService: ConfigService,
        private dialogService: DialogService,
        private reportingService: ReportingService,
        private flashMessageService: FlashMessageService,
        private assignmentService: AssignmentService,
        private loginService: LoginService,
        private router: Router
    ) {}

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isLocalAdmin() {
        return this.loginService.getUser().roles.localAdmin;
    }

    isAccountManager() {
        return this.loginService.getUser().additionalRoles.accountManager;
    }

    getIcon() {
        if (this.user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (this.user.roles.nationalAdmin) {
            return 'icon-adminnational';
        } else if (this.user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (this.user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (this.user.roles.learner) {
            return 'icon-apprenant';
        } else if (this.user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (this.user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (this.user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (this.user.roles.siteTeacher) {
            return 'icon-formateur-site';
        }
    }

    getConnectionStatusIcon() {
        if (this.user.connectionStatus === 'offline') {
            return '';
        } else if (this.user.connectionStatus === 'online') {
            return 'green';
        } else if (this.user.connectionStatus === 'idle') {
            return 'orange';
        } else if (this.user.connectionStatus === 'donotdisturb') {
            return 'red';
        }
    }

    getConnectionStatusTooltip() {
        if (this.user.connectionStatus === 'offline') {
            return 'Hors ligne';
        } else if (this.user.connectionStatus === 'online') {
            return 'En ligne';
        } else if (this.user.connectionStatus === 'idle') {
            return 'Absent';
        } else if (this.user.connectionStatus === 'donotdisturb') {
            return 'Ne pas déranger';
        }
    }

    canCallUser() {
        return (
            this.user.id !== this.loginService.getUser().id &&
            this.getConnectionStatusIcon() === 'green' &&
            this.user.applications.findIndex((application) => {
                return application.app_id === 'easicall';
            }) > -1
        );
    }

    canChatUser() {
        return (
            this.user.id !== this.loginService.getUser().id &&
            this.getConnectionStatusIcon() === 'green' &&
            this.user.applications.findIndex((application) => {
                return application.app_id === 'easichat';
            }) > -1
        );
    }

    callUser() {
        const message: any = {
            from: this.loginService.getUser().id,
            to: this.user.id,
            users: {
                toName: this.user.lastname.toUpperCase() + ' ' + this.user.firstname
            },
            callid: null,
            status: 'new-call'
        };
        (<HTMLIFrameElement>document.getElementById('header-container')).contentWindow.postMessage(
            message,
            '*'
        );
    }

    chatUser() {
        const message: any = {
            startChat: JSON.stringify(this.user)
        };
        (<HTMLIFrameElement>document.getElementById('footer-container')).contentWindow.postMessage(
            message,
            '*'
        );
    }

    hasIncompleteEvents(user: User): boolean {
        return !user.events.CGU || !user.events.emailverified || !user.events.passwordverified;
    }

    canOpenUserDialog(user: User): boolean {
        if (this.isNationalAdmin()) {
            return true;
        } else if (this.isLocalAdmin()) {
            return !user.roles.nationalAdmin && !user.roles.localAdmin;
        } else if (this.isAccountManager()) {
            return user.roles.learner || user.roles.prospect || user.roles.tutor;
        } else {
            return user.roles.learner || user.roles.prospect;
        }
    }

    getUserName() {
        if (this.user) {
            return `${this.user.lastname.toUpperCase()} ${this.user.firstname}`;
        }
    }

    getUserCompletion() {
        return ` (${this.user.completion}%)`;
    }

    openUserDialog() {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage({ viewUser: this.user.id }, '*');
    }

    goToUserAssignment() {
        if (this.loginService.getUser().roles.tutor) {
            this.router.navigate(['/tutor/student/' + this.user.id], {
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate(['/teacher/student/' + this.user.id], {
                queryParamsHandling: 'merge'
            });
        }
    }

    addToFavorites() {
        if (this.user.roles.learner || this.user.roles.prospect) {
            this.favoriteAdded.emit({ type: 'student', element: this.user });
        } else {
            this.favoriteAdded.emit({ type: 'teacher', element: this.user });
        }
    }

    removeFromFavorites() {
        if (this.user.roles.learner || this.user.roles.prospect) {
            this.favoriteRemoved.emit({ type: 'student', element: this.user });
        } else {
            this.favoriteRemoved.emit({ type: 'teacher', element: this.user });
        }
    }

    openExchangeDialog() {
        this.dialogService.openExchange(this.user);
    }

    getUserReporting() {
        this.subscriptions$.add(
            this.reportingService
                .getCSVReporting({ userId: this.user.id })
                .subscribe((csvText: string) => {
                    const date =
                        (new Date().getDate() < 10
                            ? '0' + new Date().getDate()
                            : new Date().getDate()) +
                        '-' +
                        (new Date().getMonth() + 1 < 10
                            ? '0' + (new Date().getMonth() + 1)
                            : new Date().getMonth() + 1) +
                        '-' +
                        new Date().getFullYear();
                    const csvName =
                        'reporting_' +
                        this.user.lastname.toUpperCase() +
                        '_' +
                        this.user.firstname +
                        '_' +
                        date +
                        '.csv';
                    const blob = new Blob(['\ufeff', csvText], {
                        type: 'text/plain;charset=iso-8859-1;'
                    });
                    FileSaver.saveAs(blob, csvName);

                    this.flashMessageService.flash("L'export CSV est terminé");
                })
        );
    }

    openTestReporting() {
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?studentId=' +
                this.user.id +
                '&select=all',
            '_blank'
        );
    }

    openHistoryDialog() {
        this.dialogService.openUserHistory(this.user);
    }

    openUserReporting() {
        window.open(
            this.configService.getReportFrontEndpoint() + '#/?studentId=' + this.user.id,
            '_blank'
        );
    }

    checkAssignContentToUser() {
        const draggedElement = JSON.parse(
            JSON.stringify(this.assignmentService.getDraggedElement())
        );
        if (draggedElement.hasprice) {
            this.subscriptions$.add(
                this.dialogService.openSelectFIFCDialog().subscribe((FI: boolean) => {
                    this.assignContentToUser(draggedElement, FI);
                })
            );
        } else {
            this.assignContentToUser(draggedElement, undefined);
        }
    }

    assignContentToUser(element: Assignment, FI: boolean) {
        this.subscriptions$.add(
            this.assignmentService
                .assignContentToUser(element, this.user, undefined, undefined, FI)
                .subscribe((assignment: any) => {
                    this.dialogService
                        .openAddDisponibility(assignment.assignment)
                        .subscribe((data: any) => {
                            if (data) {
                                this.flashMessageService.flash(
                                    'La restriction calendaire a été ajoutée'
                                );
                            }
                        });
                    this.flashMessageService.flash(
                        `Le contenu <b>${
                            element.title
                        }</b> a été assigné à l\'utilisateur <b>${this.getUserName()}</b>`
                    );
                    this.assignmentService.clearDraggedElement();
                    this.assignmentService.clearDraggedMode();
                })
        );
    }
}
