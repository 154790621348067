// Internal dependencies
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LoadingService } from '@/services/loading.service';
import { LibraryService } from '@/services/library.service';
import { DialogService } from '@/services/dialog.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
    imports: [
        SharedModule,
        MatSlideToggleModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule
    ]
})
export class VideoPlayerComponent implements OnInit {
    constructor(
        private loadingService: LoadingService,
        private libraryService: LibraryService,
        private dialogService: DialogService,
        private route: ActivatedRoute
    ) {}

    content: Content;
    private subscriptions$: Subscription = new Subscription();

    supportedExtensions = ['mp4', 'f4v', 'flv'];

    mimeTypes = {
        mp4: 'video/mp4',
        f4v: 'video/mp4',
        flv: 'video/x-flv'
    };

    ngOnInit() {
        this.loadingService.startLoading('videoPlayer');
        this.subscriptions$.add(
            this.libraryService
                .getContent(+this.route.snapshot.paramMap.get('activityId'))
                .subscribe((data: Content) => {
                    this.content = data;
                    this.loadingService.stopLoading('videoPlayer');
                    if (
                        this.supportedExtensions.indexOf(
                            /[^.]+$/.exec(this.content.path)[0].toLowerCase()
                        ) === -1
                    ) {
                        const popup = window.open(this.content.path, '_blank');
                        if (!popup) {
                            const warningTitle = "Impossible d'ouvrir la page";
                            const warningBody =
                                "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                            this.dialogService.openWarning(warningBody, warningTitle);
                        }
                        window.close();
                    }
                })
        );
    }

    getVideoSrc() {
        return this.content.path;
    }

    getVideoType() {
        return this.mimeTypes[/[^.]+$/.exec(this.content.path)[0].toLowerCase()];
    }

    getDescription() {
        return this.content.description;
    }

    isLoading() {
        return this.loadingService.isLoading('videoPlayer');
    }
}
