import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LibraryService } from '@/services/library.service';
import { DialogService } from '@/services/dialog.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { LoadingService } from '@/services/loading.service';

import { Domain } from '@/structures/domain';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-domains',
    templateUrl: './domains.component.html',
    styleUrls: ['./domains.component.scss'],
    standalone: false
})
export class DomainsComponent implements OnInit {
    domains: Array<Domain>;
    subscriptions = new Subscription();

    constructor(
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.domains = [];
        this.loadingService.startLoading('getDomains');
        this.subscriptions.add(
            this.libraryService.getDomains().subscribe((data: Array<Domain>) => {
                this.loadingService.stopLoading('getDomains');
                this.domains = data;
            })
        );
    }

    isLoading() {
        return this.loadingService.isLoading('getDomains');
    }

    getDomainsCount() {
        return this.domains.length;
    }

    openCreateDomain() {
        this.subscriptions.add(
            this.dialogService.openCreateDomain().subscribe((data: string) => {
                if (data) {
                    this.flashMessageService.flash(`Le domaine <b>${data}</b> a été créé`);
                }
            })
        );
    }

    openEditDomain(domain: Domain) {
        this.subscriptions.add(
            this.dialogService.openCreateDomain(domain).subscribe((data: string) => {
                if (data) {
                    this.flashMessageService.flash(`Le domaine <b>${data}</b> a été mis à jour`);
                }
            })
        );
    }
}
