import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import * as FileSaver from 'file-saver';

import { ConfigService } from '@/services/config.service';
import { LoginService } from '@/services/login.service';
import { DialogService } from '@/services/dialog.service';
import { LibraryService } from '@/services/library.service';
import { AssignmentService } from '@/services/assignment.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { GroupService } from '@/services/group.service';
import { MultiselectService } from '@/services/library-multiselect.service';
import { ReportingService } from '@/services/reporting.service';

import { Group } from '@/structures/group';
import { User } from '@/structures/user';
import { Assignment } from '@/structures/assignment';
import { DatesPipe } from 'src/app/pipes/dates.pipe';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-group-assignments-entry',
    templateUrl: './group-assignments-entry.component.html',
    styleUrls: ['./group-assignments-entry.component.scss'],
    providers: [DatesPipe],
    standalone: false
})
export class GroupAssignmentsEntryComponent implements OnInit {
    @Input() group: Group;
    @Input() referents: Array<User>;
    @Input() assignment: Assignment;
    @Input() parent: Assignment;
    @Input() index: number;

    @Output() childrenAssigned: EventEmitter<Assignment> = new EventEmitter();
    @Output() childrenMoved: EventEmitter<Assignment> = new EventEmitter();
    @Output() assignmentDeleted: EventEmitter<Assignment> = new EventEmitter();

    subscriptions = new Subscription();

    heritedDisponibility: boolean;
    opened: boolean;

    quizList: Array<number>;
    isDragging = false;
    tooltipMessage: string;

    constructor(
        private configService: ConfigService,
        private loginService: LoginService,
        private dialogService: DialogService,
        private libraryService: LibraryService,
        private assignmentService: AssignmentService,
        private flashMessageService: FlashMessageService,
        private groupService: GroupService,
        private reportingService: ReportingService,
        private multiselectService: MultiselectService,

        private datesPipe: DatesPipe
    ) {}

    ngOnInit() {
        if (this.assignment && this.parent) {
            if (!this.assignment.timestart && this.parent.timestart) {
                this.assignment.timestart = this.parent.timestart;
                if (!this.assignment.timeend) {
                    this.heritedDisponibility = true;
                }
            }
            if (!this.assignment.timeend && this.parent.timeend) {
                this.assignment.timeend = this.parent.timeend;
                if (!this.assignment.timestart) {
                    this.heritedDisponibility = true;
                }
            }
        }
        this.subscriptions.add(
            this.assignmentService.refreshTracking.subscribe((assignments) => {
                this.updateTrackingRecursive(assignments);
            })
        );
        this.subscriptions.add(
            this.assignmentService.refreshDisponibility.subscribe((assignments) => {
                this.updateDisponibilityRecursive(assignments);
            })
        );
    }

    getIcon(): string {
        if (this.multiselectService.isAssignmentSelected(this.assignment)) {
            return 'icon-Valide selected';
        } else {
            return this.libraryService.getIcon(this.assignment);
        }
    }

    isFirstElement(): boolean {
        return this.index === 0;
    }

    isTutor(): boolean {
        return this.loginService.getUser().roles.tutor;
    }

    refreshDisponibility() {
        this.subscriptions.add(
            this.groupService.getGroupAssignments(this.group.id).subscribe((success: any) => {
                this.assignmentService.emitRefreshDisponibility(success.tree);
            })
        );
    }

    updateDisponibilityRecursive(assignments) {
        assignments.map((assignment: Assignment) => {
            if (assignment.assignmentId === this.assignment.assignmentId) {
                this.assignment.timestart = assignment.timestart;
                this.assignment.timeend = assignment.timeend;
                if (this.assignment && this.parent) {
                    if (!this.assignment.timestart && this.parent.timestart) {
                        this.assignment.timestart = this.parent.timestart;
                        if (!this.assignment.timeend) {
                            this.heritedDisponibility = true;
                        }
                    }
                    if (!this.assignment.timeend && this.parent.timeend) {
                        this.assignment.timeend = this.parent.timeend;
                        if (!this.assignment.timestart) {
                            this.heritedDisponibility = true;
                        }
                    }
                }
            }
            if (assignment.children) {
                this.updateDisponibilityRecursive(assignment.children);
            }
        });
    }

    startDrag() {
        this.assignmentService.setDraggedElement(this.assignment);
        this.assignmentService.setDraggedMode('MOVE');
    }

    endDrag() {
        this.assignmentService.clearDraggedElement();
        this.assignmentService.clearDraggedMode();
    }

    preventAssignment() {
        if (this.assignmentService.getDraggedElement()) {
            return !this.canAssignContent();
        } else {
            return false;
        }
    }

    preventAssignmentInParent() {
        if (this.assignmentService.getDraggedElement()) {
            return !this.canAssignContentInParent();
        } else {
            return false;
        }
    }

    canAssignContent() {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (this.assignmentService.getDraggedElement() && !this.assignment.hasprice) {
                return this.assignmentService.canBeChild(this.assignment);
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            return (
                this.assignment.assignmentId === this.assignmentService.getDraggedElement().parents
            );
        }
    }

    canAssignContentInParent() {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (
                this.assignmentService.getDraggedElement() &&
                this.parent &&
                !this.parent.hasprice
            ) {
                if (this.parent.assignmentId) {
                    return this.assignmentService.canBeChild(this.parent);
                } else {
                    return true;
                }
            }
            return true;
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            return this.assignment.parents === this.assignmentService.getDraggedElement().parents;
        }
    }

    getForbiddenAssignmentTooltipMessage(): string {
        if (this.assignmentService.getDraggedMode() === 'COPY') {
            if (!this.canAssignContent()) {
                if (this.assignment.hasprice) {
                    return 'Vous ne pouvez rien déposer sur cette assignation';
                }
                switch (this.assignment.category) {
                    case 'positionnement':
                    case 'preparation':
                    case 'ressource':
                    case 'travail':
                    case 'evaluation':
                    case 'corrige':
                    case 'tp':
                    case 'presentiel':
                    case 'guide':
                    case 'url':
                    case 'devoir':
                        return 'Vous ne pouvez rien déposer sur une activité';
                    default: {
                        switch (this.assignment.level) {
                            case 'parcours':
                                return "Seuls les blocs, les compétences et les modules peuvent être déposés à la racine d'un parcours";
                                break;
                            case 'bloc':
                                return "Seuls les compétences et les modules peuvent être déposés à la racine d'un bloc";
                                break;
                            case 'competence':
                                return "Seuls les modules, les séquences et les activités peuvent être déposés à la racine d'une compétence";
                                break;
                            case 'module':
                                return 'Seules les séquences et les activités peuvent être déposées dans un module';
                                break;
                            case 'sequence':
                                return 'Seules les activités peuvent être déposées dans une séquence';
                                break;
                            case 'activity':
                                return 'Vous ne pouvez rien déposer sur une activité.';
                            default:
                                break;
                        }
                    }
                }
            } else {
                return undefined;
            }
        } else if (this.assignmentService.getDraggedMode() === 'MOVE') {
            if (!this.canAssignContent()) {
                return 'Impossible de déplacer cette assignation ici';
            }
        }
    }

    checkAssignContentToGroup(index: number) {
        const draggedElement = JSON.parse(
            JSON.stringify(this.assignmentService.getDraggedElement())
        );
        const draggedMode = JSON.parse(JSON.stringify(this.assignmentService.getDraggedMode()));

        if (index === undefined && !this.canAssignContent()) {
            return;
        }
        if (index !== undefined && !this.canAssignContentInParent()) {
            return;
        }
        if (draggedMode === 'COPY') {
            if (draggedElement.hasprice) {
                this.subscriptions.add(
                    this.dialogService.openSelectFIFCDialog().subscribe((FI: boolean) => {
                        if (index === undefined) {
                            this.assignContentToGroup(
                                draggedElement,
                                this.assignment,
                                undefined,
                                FI
                            );
                        } else {
                            this.assignContentToGroup(
                                draggedElement,
                                this.parent,
                                this.assignmentService.calculatePosition(this.parent, index),
                                FI
                            );
                        }
                    })
                );
            } else {
                if (index === undefined) {
                    this.assignContentToGroup(draggedElement, this.assignment);
                } else {
                    this.assignContentToGroup(
                        draggedElement,
                        this.parent,
                        this.assignmentService.calculatePosition(this.parent, index)
                    );
                }
            }
        } else {
            const position = this.assignmentService.calculatePosition(
                this.parent,
                index,
                draggedElement
            );
            if (position) {
                this.subscriptions.add(
                    this.assignmentService
                        .moveGroupAssignment(draggedElement, position)
                        .subscribe(() => {
                            this.flashMessageService.flash(
                                `Le contenu <b>${draggedElement.title}</b> a été déplacé`
                            );
                            if (!this.parent.assignmentId) {
                                this.childrenMoved.emit({
                                    assignmentId: draggedElement.assignmentId,
                                    position: position
                                });
                            } else {
                                this.parent.children.map((assignment: Assignment) => {
                                    if (assignment.assignmentId === draggedElement.assignmentId) {
                                        assignment.position = position;
                                    }
                                    return assignment;
                                });
                            }
                            this.assignmentService.clearDraggedElement();
                            this.assignmentService.clearDraggedMode();
                        })
                );
            }
        }
    }

    assignContentToGroup(element: Assignment, parent: Assignment, position?: number, FI?: boolean) {
        this.subscriptions.add(
            this.assignmentService
                .assignContentToGroup(element, this.group, position, parent, FI)
                .subscribe((assignment: any) => {
                    if (assignment.show_warning_cron) {
                        this.dialogService.openInfo(
                            "Pour ne pas vous bloquer dans votre navigation et vous permettre d'utiliser EASi sans interruption, Cette demande sera traitée par nos systèmes dans les meilleurs délais."
                        );
                        this.addAssignmentToData(assignment, parent, position);
                        return;
                    }
                    this.flashMessageService.flash(
                        `Le contenu <b>${element.title}</b> a été assigné au groupe <b>${this.group.name}</b>`
                    );
                    if (element.type === 'devoir') {
                        this.subscriptions.add(
                            this.dialogService.openDevoirConfirmation().subscribe((data: any) => {
                                if (data) {
                                    this.subscriptions.add(
                                        this.groupService
                                            .markAssignmentAsAnonymous(
                                                assignment.assignment.assignmentId
                                            )
                                            .subscribe(() => {
                                                assignment.assignment.is_anonymous = true;
                                                this.addAssignmentToData(
                                                    assignment,
                                                    parent,
                                                    position
                                                );
                                                this.flashMessageService.flash(
                                                    `L'assignation a été marqué comme anonyme`
                                                );
                                            })
                                    );
                                } else {
                                    this.dialogService
                                        .openAddDisponibility(assignment.assignment)
                                        .subscribe((data: any) => {
                                            if (data) {
                                                this.flashMessageService.flash(
                                                    'La restriction calendaire a été ajoutée'
                                                );
                                                assignment.assignment.timestart = data.timestart;
                                                assignment.assignment.timeend = data.timeend;
                                                this.refreshDisponibility();
                                            }
                                            this.addAssignmentToData(assignment, parent, position);
                                        });
                                }
                            })
                        );
                    } else {
                        this.dialogService
                            .openAddDisponibility(assignment.assignment)
                            .subscribe((data: any) => {
                                if (data) {
                                    this.flashMessageService.flash(
                                        'La restriction calendaire a été ajoutée'
                                    );
                                    assignment.assignment.timestart = data.timestart;
                                    assignment.assignment.timeend = data.timeend;
                                    this.refreshDisponibility();
                                }
                                this.addAssignmentToData(assignment, parent, position);
                            });
                    }
                })
        );
    }

    addAssignmentToData(assignment, parent: Assignment, position: number) {
        if (!parent || !parent.assignmentId) {
            this.childrenAssigned.emit(assignment.assignment);
        } else {
            if (position) {
                this.parent.children.push(assignment.assignment);
            } else {
                if (this.assignment.children) {
                    this.assignment.children.push(assignment.assignment);
                } else {
                    this.assignment.children = [assignment.assignment];
                }
            }
        }
        this.assignmentService.clearDraggedElement();
        this.assignmentService.clearDraggedMode();
    }

    getSortedChildren() {
        if (this.assignment.children) {
            return this.assignment.children.sort((a: Assignment, b: Assignment) => {
                if (a.position < b.position) {
                    return -1;
                }
                if (a.position > b.position) {
                    return 1;
                }
                return 0;
            });
        }
    }

    hasGuide() {
        return !!this.assignment.guide;
    }

    viewGuide($event) {
        $event.stopImmediatePropagation();
        if (this.assignment.guide) {
            const popup = window.open(this.assignment.guide);
            if (!popup) {
                const warningTitle = "Impossible d'ouvrir la page";
                const warningBody =
                    "Vous utilisez un bloqueur de pop-ups qui vous empêche d'ouvrir un nouvel onglet. <br >Veuillez modifier les paramètres de votre navigateur pour autoriser les pop-ups pour le site : <br><strong>easi-training.fr</strong>";
                this.dialogService.openWarning(warningBody, warningTitle);
            }
        }
    }

    canHideAssignment() {
        return this.assignment.status === 'visible';
    }

    hideAssignment($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.groupService.hideGroupAssignment(this.assignment.assignmentId).subscribe(() => {
                this.assignment.status = 'hidden';
                this.assignment.active_users = 0;
                this.flashMessageService.flash("L'assignation a été désassignée.");
            })
        );
    }

    showAssignment($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.groupService.showGroupAssignment(this.assignment.assignmentId).subscribe(() => {
                this.assignment.status = 'visible';
                this.assignment.active_users = this.assignment.users;
                this.flashMessageService.flash("L'assignation a été réassignée.");
            })
        );
    }

    getAddDisponibilityTooltip() {
        if (this.showDisponibilityIcon()) {
            return 'Modifier la restriction calendaire';
        } else {
            return 'Ajouter une restriction calendaire';
        }
    }

    openAddDisponibilityDialog($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService.openAddDisponibility(this.assignment).subscribe((data: any) => {
                if (data) {
                    this.flashMessageService.flash('La restriction calendaire a été ajoutée');
                    this.assignment.timestart = data.timestart;
                    this.assignment.timeend = data.timeend;
                    this.refreshDisponibility();
                }
            })
        );
    }

    deleteAssignment($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService
                .openConfirmationDialog(
                    "Supprimer l'assignation",
                    `Êtes-vous sûr de vouloir supprimer l\'assignation du contenu "${this.assignment.title}"`
                )
                .subscribe((data) => {
                    if (data) {
                        this.subscriptions.add(
                            this.groupService
                                .deleteGroupAssignments(this.assignment.assignmentId)
                                .subscribe(() => {
                                    this.assignmentDeleted.emit(this.assignment);
                                    this.flashMessageService.flash(
                                        "L'assignation a été supprimée."
                                    );
                                })
                        );
                    }
                })
        );
    }

    removeAssignment(assignment: Assignment) {
        for (const i in this.assignment.children) {
            if (this.assignment.children[i].assignmentId === assignment.assignmentId) {
                this.assignment.children.splice(+i, 1);
            }
        }
    }

    openContentDialog($event) {
        $event.stopImmediatePropagation();
        this.dialogService.openContentDetails(this.assignment.id);
    }

    enabledValidationButton() {
        return (
            this.assignment.forceStatus &&
            !this.assignment.forced_completed &&
            this.assignment.users > 0 &&
            this.getIso() === 1
        );
    }

    validateAssignment($event) {
        $event.stopImmediatePropagation();
        if (this.assignment.level && !this.assignment.tracking.forcedStatus) {
            const dialogTitle = 'Forcer la validation';
            const dialogBody =
                "Par défaut le calcul du statut des assemblages est automatique.<br />Si vous décidez de forcer la validation ou l'invalidation, l'automatisme sera définitivement désactivé pour cet assemblage.<br />Êtes-vous sûr de vouloir forcer le changement de statut de cet élément ?";
            this.subscriptions.add(
                this.dialogService
                    .openConfirmationDialog(dialogTitle, dialogBody)
                    .subscribe((data: boolean) => {
                        if (data) {
                            this.subscriptions.add(
                                this.groupService
                                    .validateContentForGroup(this.group.id, this.assignment.id)
                                    .subscribe(() => {
                                        this.refreshTracking();
                                        this.flashMessageService.flash(
                                            'Le contenu <b>' +
                                                this.assignment.title +
                                                '</b> a été validé'
                                        );
                                    })
                            );
                        }
                    })
            );
        } else {
            this.subscriptions.add(
                this.groupService
                    .validateContentForGroup(this.group.id, this.assignment.id)
                    .subscribe(() => {
                        this.refreshTracking();
                        this.flashMessageService.flash(
                            'Le contenu <b>' + this.assignment.title + '</b> a été validé'
                        );
                    })
            );
        }
    }

    enabledInvalidationButton() {
        return (
            this.assignment.forceStatus &&
            this.assignment.forced_completed &&
            this.assignment.users > 0
        );
    }

    invalidateAssignment($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.groupService
                .invalidateContentForGroup(this.group.id, this.assignment.id)
                .subscribe(() => {
                    this.refreshTracking();
                    this.flashMessageService.flash(
                        'Le contenu <b>' + this.assignment.title + '</b> a été invalidé'
                    );
                })
        );
    }

    refreshTracking() {
        this.subscriptions.add(
            this.groupService.getGroupAssignments(this.group.id).subscribe((success: any) => {
                this.assignmentService.emitRefreshTracking(success.tree);
            })
        );
    }

    getLaunchButtonTooltip() {
        if (this.assignment.type === 'devoir') {
            return "Voir l'énoncé";
        } else {
            return 'Voir le contenu';
        }
    }

    openActivity() {
        this.libraryService.openActivity(this.assignment);
    }

    markAsReferent($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.groupService.markAsReferent(this.assignment.assignmentId).subscribe(() => {
                this.flashMessageService.flash(
                    "Vous êtes devenu référent de l'assignation <strong>" +
                        this.assignment.title +
                        '</strong>'
                );
                this.assignment.referents.push(this.loginService.getUser().id);
            })
        );
    }

    unmarkAsReferent($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.groupService.unmarkAsReferent(this.assignment.assignmentId).subscribe(() => {
                this.flashMessageService.flash(
                    "Vous n'êtes plus référent de l'assignation <strong>" +
                        this.assignment.title +
                        '</strong>'
                );
                const index = this.assignment.referents.indexOf(this.loginService.getUser().id);
                this.assignment.referents.splice(index, 1);
            })
        );
    }

    removeDisponibility($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.groupService
                .setDisponibilityToGroupAssignment(this.assignment.assignmentId, null, null)
                .subscribe(() => {
                    this.assignment.timestart = undefined;
                    this.assignment.timeend = undefined;
                    this.refreshDisponibility();
                })
        );
    }

    isQuiz() {
        return this.assignment.type === 'quiz';
    }

    openReportingGroup($event) {
        $event.stopImmediatePropagation();
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?groupId=' +
                this.group.id +
                '&select=test&id=' +
                this.assignment.quizId +
                '&name=' +
                this.assignment.title,
            '_blank'
        );
    }

    getQuizsContentFromAssignments(assignments) {
        let found: boolean;
        for (const i in assignments) {
            if (assignments[i].type === 'quiz') {
                found = false;
                for (const j in this.quizList) {
                    if (this.quizList[j] === assignments[i].quizId) {
                        found = true;
                    }
                }
                if (!found) {
                    this.quizList.push(assignments[i].quizId);
                }
            } else if (assignments[i].children) {
                this.getQuizsContentFromAssignments(assignments[i].children);
            }
        }
    }

    isDevoir() {
        return this.assignment.type === 'devoir';
    }

    openGroupTrackingDialog($event) {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.dialogService
                .openGroupTracking(this.assignment, this.group)
                .subscribe((data: boolean) => {
                    if (data) {
                        this.flashMessageService.flash('Les notes ont été enregistrés');
                    }
                    this.groupService
                        .getGroupAssignments(this.group.id, undefined)
                        .subscribe((success: any) => {
                            this.updateTrackingRecursive(success.tree);
                        });
                })
        );
    }

    showDownloadGroupReport() {
        return (
            this.group.type === 'role' &&
            [null, 'sequence', 'module'].indexOf(this.assignment.level) > -1
        );
    }

    downloadGroupReport($event) {
        $event.stopImmediatePropagation();

        this.reportingService
            .getCSVReporting({ groupId: this.group.id, contentId: this.assignment.id })
            .subscribe((csvText: string) => {
                const date =
                    (new Date().getDate() < 10
                        ? '0' + new Date().getDate()
                        : new Date().getDate()) +
                    '-' +
                    (new Date().getMonth() + 1 < 10
                        ? '0' + (new Date().getMonth() + 1)
                        : new Date().getMonth() + 1) +
                    '-' +
                    new Date().getFullYear();
                const csvName = 'reporting_' + this.group.name + '_' + date + '.csv';
                const blob = new Blob(['\ufeff', csvText], {
                    type: 'text/plain;charset=iso-8859-1;'
                });
                FileSaver.saveAs(blob, csvName);

                this.flashMessageService.flash("L'export CSV est terminé");
            });
    }

    updateTrackingRecursive(assignments) {
        assignments.map((assignment: Assignment) => {
            if (assignment.assignmentId === this.assignment.assignmentId) {
                this.assignment = JSON.parse(JSON.stringify(assignment));
            }
            if (assignment.children) {
                this.updateTrackingRecursive(assignment.children);
            }
        });
    }

    remindWork($event: Event): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.assignmentService.remindAssignment(this.assignment).subscribe((data: boolean) => {
                this.flashMessageService.flash('Email envoyé');
            })
        );
    }

    showDuration() {
        if (
            this.getDuration() &&
            (this.assignment.level === 'module' ||
                this.assignment.level === 'sequence' ||
                !this.assignment.level)
        ) {
            return true;
        }
        return false;
    }

    getProgressionIcon() {
        if (this.assignment.show_warning_cron) {
            return 'icon-Voirplus color_gris1_foreground';
        }

        if (this.assignment.forced_completed && this.assignment.users > 0) {
            return 'icon-online orange';
        }

        switch (this.assignment.tracking_status) {
            case 'not attempted': {
                return 'icon-select';
            }
            case 'opened': {
                return 'icon-Progression40';
            }
            case 'completed': {
                return 'icon-online';
            }
            case 'passed': {
                return 'icon-Valide';
            }
            case 'failed': {
                return 'icon-Fermerdetails';
            }
            default: {
                return 'icon-select';
            }
        }
    }

    getStatus() {
        if (this.assignment.show_warning_cron) {
            return 'Assignation en cours...';
        }
        let string = '';
        if (this.assignment.users_status_total['not attempted']) {
            string +=
                'Non commencé : ' + this.assignment.users_status_total['not attempted'] + '<br>';
        }
        if (this.assignment.users_status_total.opened) {
            string += 'En cours : ' + this.assignment.users_status_total.opened + '<br>';
        }
        if (this.assignment.users_status_total.completed) {
            string += 'Terminé : ' + this.assignment.users_status_total.completed + '<br>';
        }
        if (this.assignment.users_status_total.passed) {
            string += 'Réussi : ' + this.assignment.users_status_total.passed + '<br>';
        }
        if (this.assignment.users_status_total.failed) {
            string += 'Non réussi : ' + this.assignment.users_status_total.failed + '<br>';
        }
        string += this.assignment.completion + '% des membres du groupe ont terminé ce contenu';
        return string;
    }

    getAssignmentDate() {
        return (
            'Contenu assigné le ' + new Date(this.assignment.assignmentDate).toLocaleDateString()
        );
    }

    /**
     * Retourne duration formatté s'il existe, sinon content_duration formatté s'il existe, ou false;
     */
    getDuration() {
        if (!!this.assignment.duration && this.assignment.duration !== '00:00') {
            const timeArray = this.assignment.duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            if (!this.isIncompleteDuration()) {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '')
                );
            } else {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '') +
                    "(durée d'activité(s) manquante(s) dans l'assemblage)"
                );
            }
        } else if (
            this.assignment.content_duration &&
            this.assignment.content_duration !== '00:00'
        ) {
            const timeArray = this.assignment.content_duration.split(':');
            const time = {
                hours: +timeArray[0],
                minutes: +timeArray[1]
            };
            if (!this.isIncompleteDuration()) {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '')
                );
            } else {
                return (
                    'Durée estimée : ' +
                    (time.hours ? time.hours + 'h' : '') +
                    (time.minutes ? time.minutes + 'mn' : '') +
                    "(durée d'activité(s) manquante(s) dans l'assemblage)"
                );
            }
        }
        return false;
    }

    isIncompleteDuration() {
        if (this.assignment.level === 'module' || this.assignment.level === 'sequence') {
            return this.checkIncompleteDurationRecursive(this.assignment);
        }
        return false;
    }

    checkIncompleteDurationRecursive(entry) {
        if (
            (!entry.duration || entry.duration === '00:00') &&
            (!entry.content_duration || entry.content_duration === '00:00')
        ) {
            return true;
        }
        for (const i in entry.children) {
            if (entry.children[i]) {
                const checkChildrenIncompleteDuration = this.checkIncompleteDurationRecursive(
                    entry.children[i]
                );
                if (checkChildrenIncompleteDuration) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    isAssignationPersonnalized() {
        return this.assignment.active_users < this.assignment.users;
    }

    getPersonnalizationCount() {
        return `${this.assignment.active_users}/ ${this.assignment.users}`;
    }

    getIso() {
        return this.assignment.isIso;
    }

    getIsoWarning() {
        let message = 'Vous ne pouvez pas forcer la validation de ce contenu.<br><br>';
        if (this.assignment.isIso === 0) {
            message +=
                'Cet assemblage est assigné plusieurs fois à cet apprenant <br>et sa composition ou les restrictions de sa composition varie.<br> Le calcul de la complétion prend donc en compte l’ensemble<br> de tous ses enfants, où qu’ils soient situés parmi les assignations.';
        } else if (this.assignment.isIso === -1) {
            message +=
                'Cet assemblage contient des enfants qui sont assignés plusieurs fois à cet apprenant <br>et leur composition ou les restrictions de leur composition varie.';
        }
        return message;
    }

    showDisponibilityIcon() {
        return !!this.assignment.timestart;
    }

    showWarningDisponibility() {
        let showWarning = false;
        for (const i in this.assignment.users_status) {
            if (
                this.assignment.users_status[i].tracking === 'not attempted' ||
                this.assignment.users_status[i].tracking === 'opened'
            ) {
                showWarning = true;
            }
        }
        return this.getDisponibilityClass() === 'grey' && showWarning;
    }

    showRemindIcon(): boolean {
        return (
            this.assignment.users_status_total['not attempted'] > 0 ||
            this.assignment.users_status_total['opened'] > 0
        );
    }

    getDisponibilityClass = function () {
        const now = new Date();
        const start = new Date(this.assignment.timestart);
        let end;
        if (this.assignment.timeend !== null) {
            end = new Date(this.assignment.timeend);
        }

        if (start.getTime() > now.getTime()) {
            return 'orange';
        }

        if (end && end.getTime() < now.getTime()) {
            return 'grey';
        }
        return 'green';
    };

    getDisponibilityTooltip() {
        let tmp = this.datesPipe.fromStartToEndFormatting({
            start: this.assignment.timestart,
            end: this.assignment.timeend
        });
        if (this.showWarningDisponibility()) {
            tmp += ", un ou plusieurs apprenants n'ont pas terminé dans le délai";
        }
        return tmp;
    }

    getReferentAvatar() {
        if (this.assignment.referents.length === 1) {
            const currentReferent = this.referents.filter((referent: User) => {
                return referent.id === this.assignment.referents[0];
            })[0];
            if (currentReferent && currentReferent.avatar) {
                return currentReferent.avatar;
            }
            return false;
        }
        return false;
    }

    getReferentTooltip() {
        if (this.assignment.referents.length === 0) {
            return 'Aucun formateur référent';
        }
        let text = 'Formateur(s) référent(s) :<br>';
        for (const i in this.assignment.referents) {
            if (this.assignment.referents) {
                const currentReferent = this.referents.filter((referent: User) => {
                    return referent.id === this.assignment.referents[i];
                })[0];
                if (currentReferent) {
                    text +=
                        '<i>' +
                        currentReferent.lastname.toUpperCase() +
                        ' ' +
                        currentReferent.firstname;
                    if (this.assignment.referents[i] === this.loginService.getUser().id) {
                        text += ' (vous)';
                    }
                    text += '</i><br>';
                }
            }
        }
        return text.substring(0, text.length - 4);
    }

    isReferent() {
        return this.assignment.referents.indexOf(this.loginService.getUser().id) > -1;
    }

    hasPrice() {
        return this.assignment.hasprice !== 0 && this.assignment.hasprice !== null;
    }

    isOrdered() {
        return this.assignment.ordered;
    }

    isActivity(): boolean {
        return !this.assignment.level;
    }

    toggleIcon() {
        this.opened = !this.opened;
    }

    getAssignmentTooltip() {
        const titre = `Titre : ${this.assignment.title}`;
        const id = this.assignment.id ? `<br/>ID : ${this.assignment.id}` : '';
        const duration = this.showDuration() ? `<br/>${this.getDuration()}` : '';
        return titre + id + duration;
    }

    selectAssignment($event: Event) {
        $event.stopPropagation();
        if (!this.multiselectService.isAssignmentSelected(this.assignment)) {
            this.multiselectService.addAssignmentToSelection(this.assignment);
        } else {
            this.multiselectService.removeAssignmentFromSelection(this.assignment);
        }
    }
}
