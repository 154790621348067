// Internal dependencies
import { Component, OnInit, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';

// Interfaces

// Pipes
import { FileDropzoneDirective } from 'src/app/directives/file-dropdown.directive';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-mass-update',
    templateUrl: './mass-update.component.html',
    styleUrls: ['./mass-update.component.scss'],
    imports: [SharedModule, MatDialogModule, FileDropzoneDirective]
})
export class MassUpdateComponent implements OnInit {
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    @ViewChild('fileInput', { static: false }) fileInput;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MassUpdateComponent>,
        private libraryService: LibraryService,
        private loadingService: LoadingService
    ) {}

    subscriptions = new Subscription();

    file: File;

    importStarted: boolean;
    importFinished = false;

    ngOnInit() {
        this.importStarted = false;
    }

    getTitle() {
        return 'Modifier des contenus';
    }

    getCurrentFileLabel() {
        if (this.file) {
            return this.file.name;
        } else {
            return 'Glisser-déposer votre fichier ici';
        }
    }

    openSelectFile() {
        this.fileInput.nativeElement.click();
    }

    uploadFile(files: FileList) {
        this.file = files.item(0);
    }

    isImportStarted() {
        return this.importStarted;
    }

    startImport() {
        this.importStarted = true;

        this.libraryService.updateMassContent(this.file).subscribe(
            (data) => {
                this.importFinished = true;
                if (data.length) {
                    this.openErrorDialog.next(data.join('<br>'));
                }
            },
            () => {
                this.importFinished = true;
            }
        );
    }

    exportAsCSV() {}

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }

    isImportFinished(): boolean {
        return this.importFinished;
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
