import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { AssignmentService } from '@/services/assignment.service';
import { UserService } from '@/services/user.service';
import { ReportingService } from '@/services/reporting.service';
import { ConfigService } from '@/services/config.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { LogService } from '@/services/log.service';
import { MultiselectService } from '@/services/library-multiselect.service';

import { User } from '@/structures/user';
import { Assignment } from '@/structures/assignment';

import * as FileSaver from 'file-saver';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-assignments',
    templateUrl: './user-assignments.component.html',
    styleUrls: ['./user-assignments.component.scss'],
    standalone: false
})
export class UserAssignmentsComponent implements OnInit, OnDestroy {
    getUserAssignments$ = new Subscription();

    user: User;
    referents: Array<User>;
    assignments: Array<Assignment>;
    assignmentsToDisplay: Array<Assignment>;
    pageIndex: number;

    filters: any = {
        domains: [],
        categories: [],
        referents: [],
        origin: [],
        status: [],
        search: ''
    };

    groupAssignment: Array<any>;
    groupRoleAssignment: Array<any>;

    originList: Array<string>;

    subscriptions = new Subscription();

    constructor(
        private userService: UserService,
        private reportingService: ReportingService,
        private configService: ConfigService,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService,
        private assignmentService: AssignmentService,
        private libraryService: LibraryService,
        private logService: LogService,
        private loginService: LoginService,
        private multiselectService: MultiselectService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.loadingService.startLoading('studentAssignments', 'getUser');
        this.subscriptions.add(
            this.userService.getUser(+this.route.snapshot.paramMap.get('userId')).subscribe(
                (data: User) => {
                    this.user = data;
                    this.loadingService.startLoading('studentAssignments', 'getUsersCompletion');
                    this.subscriptions.add(
                        this.userService
                            .getUsersCompletion([+this.route.snapshot.paramMap.get('userId')])
                            .subscribe(
                                (completion: any) => {
                                    this.loadingService.stopLoading(
                                        'studentAssignments',
                                        'getUsersCompletion'
                                    );
                                    this.user.completion = completion[0].completion;
                                },
                                (error: HttpErrorResponse) => {
                                    this.loadingService.stopLoading('studentAssignments');
                                }
                            )
                    );
                    this.loadingService.stopLoading('studentAssignments', 'getUser');
                    this.getUserAssignments(true);
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('studentAssignments');
                }
            )
        );
        this.assignmentService
            .getUserFilter(+this.route.snapshot.paramMap.get('userId'))
            .subscribe((data: any) => {
                this.filters.domains = data.domain
                    .map((item) => ({
                        title: item,
                        key: item
                    }))
                    .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));

                this.filters.categories = [
                    ...data.level.map((item) => ({
                        title: this.libraryService.getCategoryAndLevelLabelFromKey(item),
                        key: item,
                        icon: this.libraryService.getIcon({ level: item })
                    })),
                    {
                        separator: true
                    },
                    ...data.category.map((item) => ({
                        title: this.libraryService.getCategoryAndLevelLabelFromKey(item),
                        key: item,
                        icon: this.libraryService.getIcon({ category: item })
                    }))
                ];

                this.filters.status = data.tracking.map((status: string) => {
                    return {
                        key: status,
                        title: (() => {
                            switch (status) {
                                case 'not attempted':
                                    return 'Non commencé';
                                case 'failed':
                                    return 'Non réussi';
                                case 'passed':
                                    return 'Réussi';
                                case 'completed':
                                    return 'Terminé';
                                case 'opened':
                                    return 'En cours';
                            }
                        })()
                    };
                });

                this.filters.referents = data.referent.map((referent: any) => {
                    return {
                        title: referent.lastname.toUpperCase() + ' ' + referent.firstname,
                        key: referent.id,
                        icon: 'icon-formateur-referent'
                    };
                });

                this.filters.origin = data.origin.map((item) => ({
                    title: item.name,
                    key: item.id
                }));
                this.filters.origin.push(
                    { separator: true },
                    { title: 'Assignation individuelle', key: 'individual' }
                );
            });
    }

    ngOnDestroy() {
        this.multiselectService.clearAssignmentSelection();
    }

    showResetFilters() {
        if (
            this.filters.domains.filter((domain) => domain.selected === true).length ||
            this.filters.categories.filter((category) => category.selected === true).length ||
            this.filters.referents.filter((referent) => referent.selected === true).length ||
            this.filters.status.filter((status) => status.selected === true).length ||
            this.filters.origin.filter((origin) => origin.selected === true).length ||
            this.filters.search !== ''
        ) {
            return true;
        }
        return false;
    }

    resetFilters() {
        this.filters.domains.map((domain) => (domain.selected = false));
        this.filters.categories.map((category) => (category.selected = false));
        this.filters.referents.map((referent) => (referent.selected = false));
        this.filters.status.map((status) => (status.selected = false));
        this.filters.origin.map((origin) => (origin.selected = false));
        this.filters.search = '';
        this.getUserAssignments();
    }

    getUserAssignments(withLog?: boolean) {
        if (this.getUserAssignments$) {
            this.getUserAssignments$.unsubscribe();
            this.loadingService.stopLoading('studentAssignments', 'getUserAssignments');
        }
        this.loadingService.startLoading('studentAssignments', 'getUserAssignments');
        const filters = {
            ...this.filters,
            withLog: withLog
        };
        this.getUserAssignments$ = this.userService
            .getUserAssignments(+this.route.snapshot.paramMap.get('userId'), filters)
            .subscribe(
                (assignments: any) => {
                    this.assignmentsToDisplay = [];
                    this.pageIndex = 0;

                    this.referents = assignments.referents;
                    this.assignments = assignments.tree;
                    this.groupAssignment = [];
                    this.groupRoleAssignment = [];
                    this.originList = [];
                    this.assignments
                        .sort((a: Assignment, b: Assignment) => {
                            if (a.enrolmentType === 'role' && b.enrolmentType !== 'role') {
                                return -1;
                            } else if (a.enrolmentType !== 'role' && b.enrolmentType === 'role') {
                                return 1;
                            } else {
                                return 0;
                            }
                        })
                        .map((assignment: Assignment, index: number) => {
                            if (index < 30) {
                                this.assignmentsToDisplay.push(assignment);
                            }
                            if (
                                this.originList.indexOf(assignment.enrolmentSource) === -1 &&
                                assignment.enrolmentSource !== 'individual'
                            ) {
                                this.originList.push(assignment.enrolmentSource);
                                if (assignment.enrolmentType === 'role') {
                                    this.groupRoleAssignment.push({
                                        name: assignment.enrolmentSource,
                                        id: +assignment.groupId
                                    });
                                } else {
                                    this.groupAssignment.push({
                                        name: assignment.enrolmentSource,
                                        id: +assignment.groupId
                                    });
                                }
                            }
                            return assignment;
                        });
                    this.checkAndOverrideCorruptedPositions();
                    this.loadingService.stopLoading('studentAssignments', 'getUserAssignments');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('studentAssignments');
                }
            );
        this.subscriptions.add(this.getUserAssignments$);
    }

    getPreviousAssignment() {
        if (this.pageIndex >= 2) {
            const assignmentsToAdd = this.assignments.slice(
                (this.pageIndex - 2) * 30,
                (this.pageIndex - 1) * 30
            );
            // Remove the 30 previous assignments
            const tata = this.assignmentsToDisplay.splice(30, 30);

            this.assignmentsToDisplay = assignmentsToAdd.concat(this.assignmentsToDisplay);
            this.pageIndex--;
        }
    }

    getNextAssignment() {
        const assignmentsToAdd = this.assignments.slice(
            (this.pageIndex + 1) * 30,
            (this.pageIndex + 2) * 30
        );
        if (assignmentsToAdd.length > 0) {
            // Remove the 30 previous assignments
            if (this.pageIndex >= 1) {
                this.assignmentsToDisplay.splice(0, 30);
            }

            this.assignmentsToDisplay = this.assignmentsToDisplay.concat(assignmentsToAdd);
            this.pageIndex++;
        }
    }

    addToAssignments($event: Assignment) {
        if (!$event.parents) {
            // assignation à la racine
            this.assignmentsToDisplay.push($event);
            this.assignments.push($event);
        } else {
            // assignationdans un parent qui est à la racine (#257978)
            const parent = this.assignments.find((asst) => asst.assignmentId === $event.parents);
            if (!parent) {
                this.assignmentsToDisplay.push($event);
                this.assignments.push($event);
            }
            parent.children.push($event);
            parent.children.sort((a, b) => b.position - a.position);
        }
    }

    moveAssignment($event: Assignment) {
        this.assignments.map((assignment: Assignment) => {
            if ($event.assignmentId === assignment.assignmentId) {
                assignment.position = $event.position;
            }
            return assignment;
        });
    }

    removeAssignment(assignment: Assignment) {
        for (const i in this.assignments) {
            if (this.assignments[i].assignmentId === assignment.assignmentId) {
                this.assignments.splice(+i, 1);
            }
        }
        for (const i in this.assignmentsToDisplay) {
            if (this.assignmentsToDisplay[i].assignmentId === assignment.assignmentId) {
                this.assignmentsToDisplay.splice(+i, 1);
            }
        }
    }

    updateAssignmentStatus(assignment: Assignment) {
        for (const i in this.assignments) {
            if (this.assignments[i].assignmentId === assignment.assignmentId) {
                this.assignments[i].status = assignment.status;
            }
        }
        for (const i in this.assignmentsToDisplay) {
            if (this.assignmentsToDisplay[i].assignmentId === assignment.assignmentId) {
                this.assignmentsToDisplay[i].status = assignment.status;
            }
        }
    }

    openUserDialog() {
        const iframe: HTMLIFrameElement = document.getElementById(
            'header-container'
        ) as HTMLIFrameElement;
        iframe.contentWindow.postMessage({ viewUser: this.user.id }, '*');
    }

    getUserReporting($event) {
        $event.stopImmediatePropagation();
        this.reportingService
            .getCSVReporting({ userId: this.user.id })
            .subscribe((csvText: string) => {
                const date =
                    (new Date().getDate() < 10
                        ? '0' + new Date().getDate()
                        : new Date().getDate()) +
                    '-' +
                    (new Date().getMonth() + 1 < 10
                        ? '0' + (new Date().getMonth() + 1)
                        : new Date().getMonth() + 1) +
                    '-' +
                    new Date().getFullYear();
                const csvName =
                    'reporting_' +
                    this.user.lastname.toUpperCase() +
                    '_' +
                    this.user.firstname +
                    '_' +
                    date +
                    '.csv';
                const blob = new Blob(['\ufeff', csvText], {
                    type: 'text/plain;charset=iso-8859-1;'
                });
                FileSaver.saveAs(blob, csvName);
                this.flashMessageService.flash("L'export CSV est terminé");
            });
    }

    goToStudentPanel() {
        if (this.loginService.getUser().roles.tutor) {
            this.router.navigate(['/tutor/panel'], { queryParamsHandling: 'merge' });
        } else {
            this.router.navigate(['/teacher/panel'], { queryParamsHandling: 'merge' });
        }
    }

    openUserTestReporting($event) {
        $event.stopImmediatePropagation();
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?studentId=' +
                this.user.id +
                '&select=all',
            '_blank'
        );
    }

    goToGroup(groupId: number) {
        this.router.navigate(['/teacher/group/' + groupId], {
            queryParamsHandling: 'merge'
        });
    }

    checkAndOverrideCorruptedPositions() {
        if (this.assignments.find((ass) => ass.position === 0)) {
            this.cleanOriginAssignmentsPositions('individual');
            this.groupAssignment.map((origin) => {
                this.cleanOriginAssignmentsPositions(origin.name);
            });
        }
    }

    cleanOriginAssignmentsPositions(origin) {
        // mise en ordre des assignations / postions
        const orderedAssignments = this.assignments
            .filter((assignment: Assignment) => {
                return assignment.enrolmentSource === origin;
            })
            .sort((a: Assignment, b: Assignment) => {
                if (a.position < b.position) {
                    return -1;
                }
                if (a.position > b.position) {
                    return 1;
                }
                return 0;
            });

        if (orderedAssignments.length === 0) {
            return [];
        }

        // Si le premier élément a une position 0
        if (orderedAssignments[0].position === 0) {
            let corruptedAssignmentsCount = 0;
            // recherche des premiers éléments avec position à 0
            for (
                let i = 0;
                i < orderedAssignments.length && orderedAssignments[i].position === 0;
                i++
            ) {
                corruptedAssignmentsCount++;
            }
            // remplacement des positions par des valeurs correctes en partant du premier élt avec une position correcte.
            for (let j = corruptedAssignmentsCount - 1; j >= 0; j--) {
                if (orderedAssignments[j + 1]) {
                    orderedAssignments[j].position = Math.trunc(
                        orderedAssignments[j + 1].position / 2
                    );
                } else {
                    orderedAssignments[j].position = 1000000;
                }
            }
            for (let k = 0; k < corruptedAssignmentsCount; k++) {
                const element = orderedAssignments[k];
                if (element.position > 0) {
                    this.subscriptions.add(
                        this.assignmentService
                            .moveUserAssignment(element, element.position)
                            .subscribe(() => {
                                this.logService.success(
                                    'UserAssignmentComponent : cleanOriginAssignmentsPositions() : assignement moved at position',
                                    element.position
                                );
                            })
                    );
                }
            }
        }
        return orderedAssignments;
    }

    getAssignmentFromOrigin(origin, count?) {
        let target;
        if (count) {
            target = this.assignments;
        } else {
            target = this.assignmentsToDisplay;
        }
        return target
            .filter((assignment: Assignment) => {
                return assignment.enrolmentSource === origin;
            })
            .sort((a: Assignment, b: Assignment) => {
                if (a.position < b.position) {
                    return -1;
                }
                if (a.position > b.position) {
                    return 1;
                }
                return 0;
            });
    }

    getUserName() {
        if (this.user) {
            return `${this.user.lastname.toUpperCase()} ${this.user.firstname}`;
        }
    }

    getUserCompletion() {
        if (this.user && this.user.completion !== undefined && this.user.completion !== null) {
            return `(${this.user.completion}%)`;
        }
    }

    cancelSearch() {
        this.filters.search = '';
    }

    openUserReporting() {
        window.open(
            this.configService.getReportFrontEndpoint() + '#/?studentId=' + this.user.id,
            '_blank'
        );
    }

    isLoading() {
        return this.loadingService.isLoading('studentAssignments');
    }

    isSelectionEmpty() {
        return this.multiselectService.getSelectedAssignment().length === 0;
    }

    addDisponibility(): void {
        this.subscriptions.add(this.multiselectService.addDisponibility());
    }
}
