// Internal dependencies
import { Component, input, output, model } from '@angular/core';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LoginService } from '@/services/login.service';
import { ConfigService } from '@/services/config.service';

// Interfaces
import { Content } from '@/structures/content-selection';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-right-content',
    templateUrl: './right-content.component.html',
    styleUrls: ['./right-content.component.scss'],
    imports: [SharedModule]
})
export class RightContentComponent {
    constructor(
        private loginService: LoginService,
        private configService: ConfigService
    ) {}

    activeTab = model<string>();
    activeKey = model<string>();
    activeContent = input<Content>();

    confirmed = output<void>();

    // #region Getters

    isAdmin() {
        return (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.nationalAdmin
        );
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isSelectedTab(tab: string): boolean {
        return this.activeTab() === tab;
    }

    isSelectedKey(key: string): boolean {
        return this.activeKey() === key;
    }

    getAssemblyClass() {
        return `icon-${this.activeKey()}-cercle`;
    }

    getBackgroundStyle() {
        return `url(${this.configService.getConfig().endpoint.cdn}/assets/img/content-selection/image/${this.activeContent().image})`;
    }

    // #endregion Getters

    // #region Handlers

    handleClickSelectTab(tab: string): void {
        this.activeTab.set(tab);
        this.activeKey.set(undefined);
    }

    handleClickConfirm() {
        this.confirmed.emit();
    }

    // #endregion Handlers
}
