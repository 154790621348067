<div class="tabs-container" *ngIf="showTabsContainer()">
    <div
        class="tab left"
        *ngIf="showAssignmentTabs()"
        [ngClass]="{ active: !isCreationSpace() }"
        (click)="goTo('teacher/panel')"
    >
        <div class="overlay">
            <div class="label">Assignations</div>
        </div>
    </div>

    <div
        class="tab right"
        *ngIf="showCreationTabs()"
        [ngClass]="{ active: isCreationSpace() }"
        (click)="goTo('teacher/creation')"
    >
        <div class="overlay">
            <div></div>
            <div class="label">Créations</div>
            <div
                class="icon-minimizebloc"
                (click)="toggleLeftPanel($event)"
                [ngClass]="{ closed: reduced }"
            ></div>
        </div>
    </div>
</div>
