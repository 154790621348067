import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Colors, Chart, ChartConfiguration } from 'chart.js';

import { ReportingService } from '@/services/reporting.service';

// Interface for the radar data structure
interface RadarData {
    label: string;
    score: number;
}

// Interface for the response structure from the service
interface RadarResponse {
    response: string; // JSON string of the radar data
    attemptId: number;
    finish: boolean;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-view-chart',
    templateUrl: './view-chart.component.html',
    styleUrls: ['./view-chart.component.scss'],
    standalone: false
})
export class ViewChartComponent implements OnInit {
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();
    @Output() openConfirmationDialog: EventEmitter<string> = new EventEmitter();

    radarChartRequestPending: boolean = false;
    radarChartRequestSuccess: boolean = false;
    radarChartData:
        | {
              labels: string[];
              datasets: { label: string; data: number[]; fill: boolean }[];
              options?: ChartConfiguration['options'];
          }
        | undefined;
    radarChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: { display: false },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        const labels = tooltipItems.map((item) => item.label);
                        return labels.join(' / ');
                    },
                    label: (tooltipItem) => {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`;
                    }
                }
            }
        }
    };

    constructor(
        public dialogRef: MatDialogRef<ViewChartComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private reportingService: ReportingService
    ) {
        Chart.register(Colors);
    }

    ngOnInit() {
        console.log('this.data', this.data);
        const userId = this.data.user.id;
        const courseId = this.data.content.id;

        // Fetch data for the radar chart
        this.radarChartRequestPending = true;
        this.radarChartRequestSuccess = false;
        this.reportingService.getN4STrackingForRadar(userId, courseId).subscribe({
            next: (response: RadarResponse) => {
                // Parse the "response" property which contains a JSON string
                let radarData: RadarData[] = JSON.parse(response.response);

                // Format the data for the radar chart
                this.radarChartData = {
                    labels: radarData.map((item) => item.label),
                    datasets: [
                        {
                            label: 'Tracking Radar',
                            data: radarData.map((item) => item.score),
                            fill: true
                        }
                    ]
                };
                this.radarChartRequestPending = false;
                this.radarChartRequestSuccess = true;
            },
            error: (err) => {
                // Handle the HTTP request failure
                //alert('Failed to fetch radar data:');
                console.error('Failed to fetch radar data:', err);
                this.radarChartRequestPending = false;
                this.radarChartRequestSuccess = false;
            },
            complete: () => {
                //alert('Radar data fetch complete');
                console.log('Radar data fetch complete');
                this.radarChartRequestPending = false;
            }
        });
    }

    /**
     * Détermine le message à afficher dans le corps de la fenêtre
     * @returns {String} Le message à afficher dans le corps de la fenêtre
     */
    getMainMessage(): String {
        // When data is ready, provide a canvas element for the chart
        return ``;
    }

    getTitle(): String {
        return 'Radar Tracking';
    }

    getCloseButtonValue(): string {
        return 'FERMER';
    }

    /**
     * @param {Event} $event L'event Javascript émis lors du clic
     * Supprime le forum actuel
     */
    action() {
        this.dialogRef.close(true);
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close(false);
    }
}
