// Internal dependencies
import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services

import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { UserService } from '@/services/user.service';

// Interfaces
import { Content } from '@/structures/content';
import { User } from '@/structures/user';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-add-validator',
    templateUrl: './add-validator.component.html',
    styleUrls: ['./add-validator.component.scss'],
    imports: [SharedModule, MatDialogModule]
})
export class AddValidatorComponent implements OnInit {
    subscriptions = new Subscription();

    search: string;
    comment: string;
    validators: Array<User>;
    selectedValidators: Array<User>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public content: Content,
        public dialogRef: MatDialogRef<AddValidatorComponent>,
        private libraryService: LibraryService,
        private loadingService: LoadingService,
        private loginService: LoginService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.search = '';
        const params = {
            role: 'validator',
            offset: 0
        };
        this.loadingService.startLoading('selectValidator', 'getUsers');
        this.subscriptions.add(
            this.userService.getUsersLight(params).subscribe(
                (data: Array<User>) => {
                    params.role = 'localAdmin';
                    this.userService.getUsersLight(params).subscribe((data2: Array<User>) => {
                        data = data
                            .concat(data2)
                            .filter((user) => user.id !== this.loginService.getUser().id);
                        this.loadingService.startLoading('selectValidator', 'getContentInfo');
                        this.subscriptions.add(
                            this.libraryService.getContentInfo(this.content).subscribe(
                                (validationInfo: Array<any>) => {
                                    this.validators = data
                                        .filter((validator: User) => {
                                            const selected =
                                                validationInfo.filter((validatorInfo: any) => {
                                                    return validator.id === validatorInfo.id;
                                                }).length > 0;
                                            return !selected;
                                        })
                                        .sort((a, b) => {
                                            if (a.additionalRoles.validator && b.roles.localAdmin) {
                                                return -1;
                                            } else if (
                                                a.roles.localAdmin &&
                                                b.additionalRoles.validator
                                            ) {
                                                return 1;
                                            } else {
                                                return 0;
                                            }
                                        });

                                    this.selectedValidators = data
                                        .filter((validator: User) => {
                                            const selected =
                                                validationInfo.filter((validatorInfo: any) => {
                                                    return validator.id === validatorInfo.id;
                                                }).length > 0;
                                            return selected;
                                        })
                                        .sort((a, b) => {
                                            if (a.additionalRoles.validator && b.roles.localAdmin) {
                                                return -1;
                                            } else if (
                                                a.roles.localAdmin &&
                                                b.additionalRoles.validator
                                            ) {
                                                return 1;
                                            } else {
                                                return 0;
                                            }
                                        });
                                    this.loadingService.stopLoading(
                                        'selectValidator',
                                        'getContentInfo'
                                    );
                                },
                                (error: HttpErrorResponse) => {
                                    this.loadingService.stopLoading('selectValidator', 'getUsers');
                                }
                            )
                        );
                        this.loadingService.stopLoading('selectValidator', 'getUsers');
                    });
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('selectValidator', 'getUsers');
                }
            )
        );
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    getSelectedValidatorCount() {
        if (this.validators) {
            return this.validators.filter((validator: User) => {
                return validator.selected;
            }).length;
        }
    }

    cancelSearch() {
        this.search = '';
    }

    getValidatorName(validator: User) {
        return `${validator.lastname.toUpperCase()} ${validator.firstname}`;
    }

    isValidatorSelected(validator: User) {
        return validator.selected;
    }

    toggleValidator(validator: User) {
        validator.selected = !validator.selected;
    }

    canSendToValidation() {
        return this.getSelectedValidatorCount() > 0 && this.comment;
    }

    sendToValidation() {
        const data = {
            comment: this.comment,
            validators: this.validators
                .filter((validator) => validator.selected)
                .map((validator) => validator.id)
        };
        this.dialogRef.close(data);
    }

    isLoading() {
        return this.loadingService.isLoading('selectValidator');
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
