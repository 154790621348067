<div class="header">
    <div class="title" [innerHTML]="getTitle()">xxx</div>
</div>
<mat-dialog-content class="content">
    <div *ngIf="radarChartRequestPending">
        <p>Loading data...</p>
    </div>
    <div *ngIf="!radarChartRequestPending && !radarChartRequestSuccess">
        <p>Erreur: impossible de charger les données du radar.</p>
    </div>
    <div class="canvas-container" *ngIf="radarChartRequestSuccess && radarChartData">
        <canvas baseChart [type]="'radar'" [data]="radarChartData" [options]="radarChartOptions">
        </canvas>
    </div>
</mat-dialog-content>
<div class="footer">
    <div class="close button" (click)="closeDialog()">{{ getCloseButtonValue() }}</div>
</div>
