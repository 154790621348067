import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';

import { LibraryService } from '@/services/library.service';
import { FlashMessageService } from '@/services/flash-message.service';

import { Content, ReportStatus } from '@/structures/content';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-view-activity-error',
    templateUrl: './view-activity-error.component.html',
    styleUrls: ['./view-activity-error.component.scss'],
    standalone: false
})
export class ViewActivityErrorComponent implements OnInit {
    subscriptions = new Subscription();

    comment: string;
    nationalAdminMode: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public content: any,
        public dialogRef: MatDialogRef<ViewActivityErrorComponent>,
        private libraryService: LibraryService,
        private flashMessageService: FlashMessageService,
        private sanitizer: DomSanitizer
    ) {
        this.nationalAdminMode = this.content.nationalAdminMode;
    }

    ngOnInit() {
        this.subscriptions.add(
            this.libraryService.getReportedContent(this.content.report.id).subscribe((content) => {
                this.content = content;
            })
        );
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    getReportStatusDescription(): string {
        switch (this.content.report.status) {
            case ReportStatus.created:
                return 'Le signalement est en attente de traitement';
            case ReportStatus.inprogress:
                return 'Le signalement est en cours de traitement';
            case ReportStatus.closed:
                return 'Le contenu est corrigé';
            case ReportStatus.canceled:
                return 'Le contenu ne sera pas corrigé';
            default:
                break;
        }
    }

    getReportDate() {
        return new Date(this.content.report.timecreated).toLocaleString();
    }

    getAnswerDate() {
        return new Date(this.content.report.timemodified).toLocaleString();
    }

    getReportMessageAsHTML() {
        if (this.content.report.message) {
            return this.content.report.message.replace(/\\n/g, '<br />');
        }
    }

    getAnswerMessageAsHTML() {
        if (this.content.report.answer) {
            return this.content.report.answer.replace(/\\n/g, '<br />');
        }
    }

    canEditReport(): boolean {
        return (
            this.nationalAdminMode &&
            this.content.report.status !== ReportStatus.closed &&
            this.content.report.status !== ReportStatus.canceled
        );
    }

    cancelReport() {
        if (this.comment) {
            this.subscriptions.add(
                this.libraryService
                    .cancelActivityReport(this.content.report.id, this.comment)
                    .subscribe(() => {
                        this.flashMessageService.flash('Le signalement a bien été fermé');
                        this.dialogRef.close(true);
                    })
            );
        }
    }

    closeReport() {
        this.subscriptions.add(
            this.libraryService
                .closeActivityReport(this.content.report.id, this.comment)
                .subscribe(() => {
                    this.flashMessageService.flash('Le signalement a bien été fermé');
                    this.dialogRef.close(true);
                })
        );
    }

    closeDialog() {
        this.dialogRef.close(false);
    }
}
