<div class="right-content">
    @if (isSelectedKey(undefined)) {
        <div class="no-type">
            <img src="./assets/img/library-default.png" />
            <div>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse
                lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras
                elementum ultrices diam.
            </div>
        </div>
    } @else if (
        isSelectedKey('parcours') ||
        isSelectedKey('bloc') ||
        isSelectedKey('competence') ||
        isSelectedKey('module') ||
        isSelectedKey('sequence')
    ) {
        <div class="assembly">
            <div>
                <div class="legend">Vous allez créer un assemblage de type :</div>

                <div class="item">
                    <div [ngClass]="getAssemblyClass()"></div>
                    <div class="label">{{ activeKey() }}</div>
                </div>
            </div>

            <div>
                <div class="legend">Cliquer sur le bouton ci-dessous pour continuer</div>

                <div class="button" (click)="handleClickConfirm()">Créer l'assemblage</div>
            </div>
        </div>
    } @else if (isSelectedKey('activity')) {
        <div class="activity">
            <div>Pour créer une activité, choisissez un des onglets ci-dessus.</div>
            <div class="item">
                <div class="tab" (click)="handleClickSelectTab('creations')">
                    <div class="icon-video-interactive"></div>
                    Créations digitales
                </div>
                <div>
                    <b>Créations digitales</b> pour construire une activité à partir des nombreux
                    modèles à votre disposition.
                </div>
            </div>
            <div class="item">
                <div
                    class="tab"
                    [ngClass]="{ active: isSelectedTab('imports') }"
                    (click)="handleClickSelectTab('imports')"
                >
                    <div class="icon-Importer"></div>
                    Imports/Liens
                </div>
                <div>
                    <b>Imports</b> pour mettre à disposition de vos apprenants des activités qui ont
                    été réalisées par ailleurs.
                </div>
            </div>
        </div>
    } @else {
        <div class="content">
            <div class="example-container" [ngStyle]="{ 'background-image': getBackgroundStyle() }">
                @if (activeContent().example) {
                    <a class="example-button" href="{{ activeContent().example }}" target="_blank">
                        <div class="icon-voir"></div>
                        <div>Voir un exemple</div>
                    </a>
                }
            </div>
            <div class="description" [innerHTML]="activeContent().description"></div>
            <div class="confirm-button" (click)="handleClickConfirm()">Créer l'activité</div>
        </div>
    }
</div>
