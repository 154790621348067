import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { LoginService } from '@/services/login.service';
import { FavoriteService } from '@/services/favorite.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { UserService } from '@/services/user.service';
import { GroupService } from '@/services/group.service';
import { LoadingService } from '@/services/loading.service';

import { Structure } from '@/structures/structure';
import { User } from '@/structures/user';
import { Group } from '@/structures/group';
import { UntilDestroy } from '@ngneat/until-destroy';

import { EasiHttpParams } from 'src/app/interceptors/easi-http-params';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-student-panel',
    templateUrl: './student-panel.component.html',
    styleUrls: ['./student-panel.component.scss'],
    standalone: false
})
export class StudentPanelComponent implements OnInit {
    structures: Array<Structure>;
    nextcloudV2Access: Array<string>;
    LIMIT = 30;

    search: string;

    favorites: {
        users: Array<number>;
        groups: Array<number>;
    };

    hasFavoritesGroupStudent: boolean;

    scroll: {
        default: {
            students: number;
            studentGroups: number;
            teachers: number;
            teacherGroups: number;
            roleGroups: number;
        };
        favorites: {
            students: number;
            studentGroups: number;
            teachers: number;
            teacherGroups: number;
            roleGroups: number;
        };
    };

    users: {
        default: {
            students: Array<User>;
            studentGroups: Array<Group>;
            teachers: Array<User>;
            teacherGroups: Array<Group>;
            roleGroups: Array<Group>;
        };
        favorites: {
            students: Array<User>;
            studentGroups: Array<Group>;
            teachers: Array<User>;
            teacherGroups: Array<Group>;
            roleGroups: Array<Group>;
        };
    };

    count: {
        default: {
            students: number;
            studentGroups: number;
            teachers: number;
            teacherGroups: number;
            roleGroups: number;
        };
        favorites: {
            students: number;
            studentGroups: number;
            teachers: number;
            teacherGroups: number;
            roleGroups: number;
        };
    };

    currentPage: {
        default: {
            students: number;
            studentGroups: number;
            teachers: number;
            teacherGroups: number;
            roleGroups: number;
        };
        favorites: {
            students: number;
            studentGroups: number;
            teachers: number;
            teacherGroups: number;
            roleGroups: number;
        };
    };

    subscriptions = new Subscription();

    constructor(
        private loginService: LoginService,
        private favoriteService: FavoriteService,
        private flashMessageService: FlashMessageService,
        private userService: UserService,
        private groupService: GroupService,
        private loadingService: LoadingService,
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient
    ) {}

    ngOnInit() {
        this.structures = [];
        this.favorites = {
            users: undefined,
            groups: undefined
        };
        this.initValues();
        if (this.route.snapshot.queryParamMap.get('search')) {
            this.search = this.route.snapshot.queryParamMap.get('search');
        }
        this.loadingService.startLoading('studentPanel', 'getStructures');
        this.subscriptions.add(
            this.loginService.getStructures().subscribe((structures: Array<Structure>) => {
                this.structures = structures.map((structure: Structure) => {
                    return {
                        title: structure.name,
                        key: structure.id,
                        shortname: structure.shortname,
                        selected:
                            parseInt(this.loginService.getUser().structureid, 10) ===
                                structure.id ||
                            this.loginService.getLastStructure().id === structure.id
                    };
                });
                this.structures.unshift({ title: 'Administration national', key: 0 });
                this.initFavorites().subscribe(() => {
                    this.initView();
                    this.initState();
                });
                this.loadingService.stopLoading('studentPanel', 'getStructures');
            })
        );

        const config: any = {
            responseType: 'text',
            params: new EasiHttpParams({
                overrideBaseUrl: true,
                noCredentials: true
            })
        };

        this.subscriptions.add(
            this.http
                .get('https://cdn.easi-training.fr/assets/config/nextcloudv2.json', config)
                .subscribe((nextcloudV2Access: any) => {
                    this.nextcloudV2Access = nextcloudV2Access;
                })
        );
        window.addEventListener('message', (event: MessageEvent) => {
            let target;
            if (this.isCurrentView('student')) {
                if (!this.isFavoriteView()) {
                    target = this.users.default.students;
                } else {
                    target = this.users.favorites.students;
                }
            } else if (this.isCurrentView('student-group')) {
                if (!this.isFavoriteView()) {
                    target = this.users.default.studentGroups;
                } else {
                    target = this.users.favorites.studentGroups;
                }
            } else if (this.isCurrentView('teacher')) {
                if (!this.isFavoriteView()) {
                    target = this.users.default.teachers;
                } else {
                    target = this.users.favorites.teachers;
                }
            } else if (this.isCurrentView('teacher-group')) {
                if (!this.isFavoriteView()) {
                    target = this.users.default.teacherGroups;
                } else {
                    target = this.users.favorites.teacherGroups;
                }
            }
            if (target) {
                if (this.isCurrentView('student') || this.isCurrentView('teacher')) {
                    const index = target.findIndex((user) => user.uniqueid === event.data.uniqueid);
                    if (index > -1) {
                        target[index].connectionStatus = event.data.connectionstatus;
                    }
                }
                if (this.isCurrentView('student-group') || this.isCurrentView('teacher-group')) {
                    target.map((group) => {
                        if (group.users) {
                            group.users.map((user) => {
                                if (user.uniqueid === event.data.uniqueid) {
                                    user.connectionStatus = event.data.connectionstatus;
                                }
                            });
                        }
                    });
                }
            }
        });
    }

    initValues() {
        this.users = {
            default: {
                students: undefined,
                studentGroups: undefined,
                teachers: undefined,
                teacherGroups: undefined,
                roleGroups: undefined
            },
            favorites: {
                students: undefined,
                studentGroups: undefined,
                teachers: undefined,
                teacherGroups: undefined,
                roleGroups: undefined
            }
        };
        this.scroll = {
            default: {
                students: 0,
                studentGroups: 0,
                teachers: 0,
                teacherGroups: 0,
                roleGroups: 0
            },
            favorites: {
                students: 0,
                studentGroups: 0,
                teachers: 0,
                teacherGroups: 0,
                roleGroups: 0
            }
        };
        this.count = {
            default: {
                students: undefined,
                studentGroups: undefined,
                teachers: undefined,
                teacherGroups: undefined,
                roleGroups: undefined
            },
            favorites: {
                students: undefined,
                studentGroups: undefined,
                teachers: undefined,
                teacherGroups: undefined,
                roleGroups: undefined
            }
        };
        this.currentPage = {
            default: {
                students: 1,
                studentGroups: 1,
                teachers: 1,
                teacherGroups: 1,
                roleGroups: 1
            },
            favorites: {
                students: 1,
                studentGroups: 1,
                teachers: 1,
                teacherGroups: 1,
                roleGroups: 1
            }
        };
    }

    initState() {
        const defaultFavorite = this.hasFavoritesGroupStudent ? 'true' : 'false';
        const defaultView = this.hasFavoritesGroupStudent ? 'student-group' : 'student';
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                favoritesPanel: this.route.snapshot.queryParamMap.get('favoritesPanel')
                    ? this.route.snapshot.queryParamMap.get('favoritesPanel')
                    : defaultFavorite,
                view: this.route.snapshot.queryParamMap.get('view')
                    ? this.route.snapshot.queryParamMap.get('view')
                    : defaultView
            },
            queryParamsHandling: 'merge'
        });
    }

    initFavorites() {
        this.loadingService.startLoading('studentPanel', 'initFavorites');
        return new Observable((observer) => {
            const observableArray = [];
            observableArray.push(this.favoriteService.getUsers());
            observableArray.push(this.favoriteService.getGroups());
            observableArray.push(this.favoriteService.getStudentGroups());
            forkJoin(observableArray).subscribe(
                (data: any) => {
                    this.favorites.users = data[0];
                    this.favorites.groups = data[1];
                    this.hasFavoritesGroupStudent =
                        data[2].length && !this.loginService.getUser().roles.nationalAdmin;
                    observer.next();
                    this.loadingService.stopLoading('studentPanel', 'initFavorites');
                    observer.complete();
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('studentPanel', 'initFavorites');
                }
            );
        });
    }

    initView() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.getSelectedStructure()) {
                    switch (this.route.snapshot.queryParamMap.get('view')) {
                        case 'role-group': {
                            this.initViewRoleGroups();
                            break;
                        }
                        case 'student': {
                            this.initViewStudent();
                            break;
                        }
                        case 'student-group': {
                            this.initViewStudentGroup();
                            break;
                        }
                        case 'teacher': {
                            this.initViewTeacher();
                            break;
                        }
                        case 'teacher-group': {
                            this.initViewTeacherGroup();
                            break;
                        }
                        default: {
                            this.search = '';
                            this.ngOnInit();
                            break;
                        }
                    }
                }
            }
        });
    }

    initViewRoleGroups() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            offset: 0,
            limit: 30,
            type: 'role'
        };
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            !this.users.default.roleGroups
        ) {
            this.loadingService.startLoading('studentPanelBody', 'getRoleGroups');
            this.subscriptions.add(
                this.groupService.getGroups(filter).subscribe((data: Array<Group>) => {
                    this.users.default.roleGroups = data.map((roleGroup: Group) => {
                        if (this.favorites.groups.indexOf(+roleGroup.id) !== -1) {
                            roleGroup.favorite = true;
                        }
                        return roleGroup;
                    });
                    this.loadingService.stopLoading('studentPanelBody', 'getRoleGroups');
                })
            );
            this.loadingService.startLoading('studentPanelBody', 'getRoleGroupsCount');
            this.subscriptions.add(
                this.groupService.getGroupsCount(filter).subscribe(
                    (data: any) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getRoleGroupsCount');
                        this.count.default.roleGroups = data.count;
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getRoleGroupsCount');
                    }
                )
            );
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            !this.users.favorites.roleGroups
        ) {
            filter.ids = this.favorites.groups.join('|');
            this.loadingService.startLoading('studentPanelBody', 'getFavoritesRoleGroups');
            this.subscriptions.add(
                this.groupService.getGroups(filter).subscribe((data: Array<Group>) => {
                    this.users.favorites.roleGroups = data.map((roleGroup: Group) => {
                        roleGroup.favorite = true;
                        return roleGroup;
                    });
                    this.count.favorites.roleGroups = this.users.favorites.roleGroups.length;
                    this.loadingService.stopLoading('studentPanelBody', 'getFavoritesRoleGroups');
                })
            );
        }
    }

    initViewStudent() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            offset: 0,
            limit: 30,
            role: 'learner|prospect'
        };
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            !this.users.default.students
        ) {
            this.loadingService.startLoading('studentPanelBody', 'getStudents');
            this.subscriptions.add(
                this.userService.getUsers(filter).subscribe(
                    (data: Array<User>) => {
                        this.users.default.students = data.map((student: User) => {
                            if (this.favorites.users.indexOf(+student.id) !== -1) {
                                student.favorite = true;
                            }
                            return student;
                        });
                        if (data.length > 0) {
                            this.subscriptions.add(
                                this.userService
                                    .getUsersCompletion(data.map((student) => student.id))
                                    .subscribe((completionArray: Array<any>) => {
                                        this.users.default.students = this.users.default.students.map(
                                            (student: User) => {
                                                completionArray.map((completion: any) => {
                                                    if (completion.id === student.id) {
                                                        student.completion = completion.completion;
                                                    }
                                                });
                                                return student;
                                            }
                                        );
                                    })
                            );
                        }
                        this.loadingService.stopLoading('studentPanelBody', 'getStudents');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getStudents');
                    }
                )
            );
            this.loadingService.startLoading('studentPanelBody', 'getStudentsCount');
            this.subscriptions.add(
                this.userService.getUsersCount(filter).subscribe(
                    (data: any) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getStudentsCount');
                        this.count.default.students = data.count;
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getStudentsCount');
                    }
                )
            );
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            !this.users.favorites.students
        ) {
            filter.ids = this.favorites.users.join('|');
            this.loadingService.startLoading('studentPanelBody', 'getFavoritesStudents');
            this.subscriptions.add(
                this.userService.getUsers(filter).subscribe(
                    (data: Array<User>) => {
                        this.users.favorites.students = data.map((student: User) => {
                            student.favorite = true;
                            return student;
                        });
                        this.count.favorites.students = this.users.favorites.students.length;
                        if (data.length > 0) {
                            this.subscriptions.add(
                                this.userService
                                    .getUsersCompletion(data.map((student) => student.id))
                                    .subscribe(
                                        (completionArray: Array<any>) => {
                                            this.users.favorites.students = this.users.favorites.students.map(
                                                (student: User) => {
                                                    completionArray.map((completion: any) => {
                                                        if (completion.id === student.id) {
                                                            student.completion =
                                                                completion.completion;
                                                        }
                                                    });
                                                    return student;
                                                }
                                            );
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.loadingService.stopLoading(
                                                'studentPanelBody',
                                                'getFavoritesStudents'
                                            );
                                        }
                                    )
                            );
                        }
                        this.loadingService.stopLoading('studentPanelBody', 'getFavoritesStudents');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getFavoritesStudents');
                    }
                )
            );
        }
    }

    initViewStudentGroup() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            offset: 0,
            limit: 30,
            type: 'learner'
        };
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            !this.users.default.studentGroups
        ) {
            // To prevent 404 in case of Administration nationale
            if (filter.structureid === 0) {
                this.users.default.studentGroups = [];
                this.count.default.studentGroups = 0;
            } else {
                this.loadingService.startLoading('studentPanelBody', 'getStudentGroups');
                this.subscriptions.add(
                    this.groupService.getGroups(filter).subscribe(
                        (data: Array<Group>) => {
                            this.users.default.studentGroups = data.map((studentGroup: Group) => {
                                if (this.favorites.groups.indexOf(+studentGroup.id) !== -1) {
                                    studentGroup.favorite = true;
                                }
                                return studentGroup;
                            });
                            this.loadingService.stopLoading('studentPanelBody', 'getStudentGroups');
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('studentPanelBody', 'getStudentGroups');
                        }
                    )
                );
                this.loadingService.startLoading('studentPanelBody', 'getStudentGroupsCount');
                this.subscriptions.add(
                    this.groupService.getGroupsCount(filter).subscribe(
                        (data: any) => {
                            this.loadingService.stopLoading(
                                'studentPanelBody',
                                'getStudentGroupsCount'
                            );
                            this.count.default.studentGroups = data.count;
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading(
                                'studentPanelBody',
                                'getStudentGroupsCount'
                            );
                        }
                    )
                );
            }
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            !this.users.favorites.studentGroups
        ) {
            // To prevent 404 in case of Administration nationale
            if (filter.structureid === 0) {
                this.users.favorites.studentGroups = [];
                this.count.favorites.studentGroups = 0;
            } else {
                filter.ids = this.favorites.groups.join('|');
                this.loadingService.startLoading('studentPanelBody', 'getFavoritesStudentGroups');
                this.subscriptions.add(
                    this.groupService.getGroups(filter).subscribe(
                        (data: Array<Group>) => {
                            this.users.favorites.studentGroups = data.map((studentGroup: Group) => {
                                studentGroup.favorite = true;
                                return studentGroup;
                            });
                            this.count.favorites.studentGroups = this.users.favorites.studentGroups.length;
                            this.loadingService.stopLoading(
                                'studentPanelBody',
                                'getFavoritesStudentGroups'
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading(
                                'studentPanelBody',
                                'getFavoritesStudentGroups'
                            );
                        }
                    )
                );
            }
        }
    }

    initViewTeacher() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            offset: 0,
            limit: 30
        };
        if (this.loginService.getUser().roles.nationalAdmin) {
            filter.role =
                'internalTeacher|externalTeacher|corporationTeacher|siteTeacher|localAdmin|nationalAdmin|tutor';
        }
        if (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.internalTeacher ||
            this.loginService.getUser().roles.externalTeacher
        ) {
            filter.role =
                'internalTeacher|externalTeacher|corporationTeacher|siteTeacher|localAdmin|tutor';
        }
        if (
            this.loginService.getUser().roles.corporationTeacher ||
            this.loginService.getUser().roles.siteTeacher
        ) {
            filter.role =
                'internalTeacher|externalTeacher|corporationTeacher|siteTeacher|localAdmin|tutor';
        }
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            !this.users.default.teachers
        ) {
            this.loadingService.startLoading('studentPanelBody', 'getTeachers');
            this.subscriptions.add(
                this.userService.getUsers(filter).subscribe(
                    (data: Array<User>) => {
                        this.users.default.teachers = data.map((teacher: User) => {
                            if (this.favorites.users.indexOf(+teacher.id) !== -1) {
                                teacher.favorite = true;
                            }
                            return teacher;
                        });
                        if (data.length > 0) {
                            this.subscriptions.add(
                                this.userService
                                    .getUsersCompletion(data.map((student) => student.id))
                                    .subscribe(
                                        (completionArray: Array<any>) => {
                                            this.users.default.teachers = this.users.default.teachers.map(
                                                (teacher: User) => {
                                                    completionArray.map((completion: any) => {
                                                        if (completion.id === teacher.id) {
                                                            teacher.completion =
                                                                completion.completion;
                                                        }
                                                    });
                                                    return teacher;
                                                }
                                            );
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.loadingService.stopLoading(
                                                'studentPanelBody',
                                                'getTeachers'
                                            );
                                        }
                                    )
                            );
                        }
                        this.loadingService.stopLoading('studentPanelBody', 'getTeachers');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getTeachers');
                    }
                )
            );
            this.loadingService.startLoading('studentPanelBody', 'getTeachersCount');
            this.subscriptions.add(
                this.userService.getUsersCount(filter).subscribe(
                    (data: any) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getTeachersCount');
                        this.count.default.teachers = data.count;
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.startLoading('studentPanelBody', 'getTeachersCount');
                    }
                )
            );
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            !this.users.favorites.teachers
        ) {
            filter.ids = this.favorites.users.join('|');
            this.loadingService.startLoading('studentPanelBody', 'getFavoritesTeachers');
            this.subscriptions.add(
                this.userService.getUsers(filter).subscribe(
                    (data: Array<User>) => {
                        this.users.favorites.teachers = data.map((teacher: User) => {
                            teacher.favorite = true;
                            return teacher;
                        });
                        this.count.favorites.teachers = this.users.favorites.teachers.length;
                        if (data.length > 0) {
                            this.subscriptions.add(
                                this.userService
                                    .getUsersCompletion(data.map((teacher) => teacher.id))
                                    .subscribe(
                                        (completionArray: Array<any>) => {
                                            this.users.favorites.teachers = this.users.favorites.teachers.map(
                                                (teacher: User) => {
                                                    completionArray.map((completion: any) => {
                                                        if (completion.id === teacher.id) {
                                                            teacher.completion =
                                                                completion.completion;
                                                        }
                                                    });
                                                    return teacher;
                                                }
                                            );
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.loadingService.stopLoading(
                                                'studentPanelBody',
                                                'getFavoritesTeachers'
                                            );
                                        }
                                    )
                            );
                        }
                        this.loadingService.stopLoading('studentPanelBody', 'getFavoritesTeachers');
                    },
                    (error: HttpErrorResponse) => {
                        this.loadingService.stopLoading('studentPanelBody', 'getFavoritesTeachers');
                    }
                )
            );
        }
    }

    initViewTeacherGroup() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            offset: 0,
            limit: 30,
            type: 'teacher'
        };
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            !this.users.default.teacherGroups
        ) {
            // To prevent 404 in case of Administration nationale
            if (filter.structureid === 0) {
                this.users.default.teacherGroups = [];
                this.count.default.teacherGroups = 0;
            } else {
                this.loadingService.startLoading('studentPanelBody', 'getTeacherGroups');
                this.subscriptions.add(
                    this.groupService.getGroups(filter).subscribe(
                        (data: Array<Group>) => {
                            this.users.default.teacherGroups = data.map((teacherGroup: Group) => {
                                if (this.favorites.groups.indexOf(+teacherGroup.id) !== -1) {
                                    teacherGroup.favorite = true;
                                }
                                return teacherGroup;
                            });
                            this.loadingService.stopLoading('studentPanelBody', 'getTeacherGroups');
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('studentPanelBody', 'getTeacherGroups');
                        }
                    )
                );
                this.loadingService.startLoading('studentPanelBody', 'getTeacherGroupsCount');
                this.subscriptions.add(
                    this.groupService.getGroupsCount(filter).subscribe(
                        (data: any) => {
                            this.loadingService.stopLoading(
                                'studentPanelBody',
                                'getTeacherGroupsCount'
                            );
                            this.count.default.teacherGroups = data.count;
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading('studentPanelBody', 'getTeacherGroups');
                        }
                    )
                );
            }
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            !this.users.favorites.teacherGroups
        ) {
            filter.ids = this.favorites.groups.join('|');
            // To prevent 404 in case of Administration nationale
            if (filter.structureid === 0) {
                this.users.favorites.teacherGroups = [];
                this.count.favorites.teacherGroups = 0;
            } else {
                this.loadingService.startLoading('studentPanelBody', 'getFavoritesTeacherGroups');
                this.subscriptions.add(
                    this.groupService.getGroups(filter).subscribe(
                        (data: Array<Group>) => {
                            this.users.favorites.teacherGroups = data.map((studentGroup: Group) => {
                                studentGroup.favorite = true;
                                return studentGroup;
                            });
                            this.count.favorites.teacherGroups = this.users.favorites.teacherGroups.length;
                            this.loadingService.stopLoading(
                                'studentPanelBody',
                                'getFavoritesTeacherGroups'
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this.loadingService.stopLoading(
                                'studentPanelBody',
                                'getFavoritesTeacherGroups'
                            );
                        }
                    )
                );
            }
        }
    }

    nextPage() {
        if (this.getSelectedStructure()) {
            switch (this.route.snapshot.queryParamMap.get('view')) {
                case 'role-group': {
                    this.nextPageRoleGroups();
                    break;
                }
                case 'student': {
                    this.nextPageStudent();
                    break;
                }
                case 'student-group': {
                    this.nextPageStudentGroup();
                    break;
                }
                case 'teacher': {
                    this.nextPageTeacher();
                    break;
                }
                case 'teacher-group': {
                    this.nextPageTeacherGroup();
                    break;
                }
            }
        }
    }

    nextPageRoleGroups() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            limit: this.LIMIT,
            type: 'role'
        };
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false') {
            filter.offset = this.currentPage.default.roleGroups * this.LIMIT;
            this.currentPage.default.roleGroups++;
            this.loadingService.startLoading('studentPanelBodyNextPage', 'getGroupRoles');
            this.subscriptions.add(
                this.groupService.getGroups(filter).subscribe((data: Array<Group>) => {
                    this.users.default.roleGroups = this.users.default.roleGroups.concat(
                        data.map((roleGroup: Group) => {
                            if (this.favorites.groups.indexOf(+roleGroup.id) !== -1) {
                                roleGroup.favorite = true;
                            }
                            return roleGroup;
                        })
                    );
                    this.loadingService.stopLoading('studentPanelBodyNextPage', 'getGroupRoles');
                })
            );
        } else if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true') {
            filter.ids = this.favorites.groups.join('|');
            filter.offset = this.currentPage.favorites.roleGroups * this.LIMIT;
            this.currentPage.favorites.roleGroups++;
            this.loadingService.startLoading('studentPanelBodyNextPage', 'getFavoritesRoleGroups');
            this.subscriptions.add(
                this.groupService.getGroups(filter).subscribe((data: Array<Group>) => {
                    this.users.favorites.roleGroups = this.users.favorites.roleGroups.concat(
                        data.map((roleGroup: Group) => {
                            roleGroup.favorite = true;
                            return roleGroup;
                        })
                    );
                    this.count.favorites.roleGroups = this.users.favorites.roleGroups.length;
                    this.loadingService.stopLoading(
                        'studentPanelBodyNextPage',
                        'getFavoritesRoleGroups'
                    );
                })
            );
        }
    }

    nextPageStudent() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            limit: this.LIMIT,
            role: 'learner|prospect'
        };
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false') {
            filter.offset = this.currentPage.default.students * this.LIMIT;
            this.currentPage.default.students++;
            this.loadingService.startLoading('studentPanelBodyNextPage', 'getStudents');
            this.subscriptions.add(
                this.userService.getUsers(filter).subscribe((data: Array<User>) => {
                    this.users.default.students = this.users.default.students.concat(
                        data.map((student: User) => {
                            if (this.favorites.users.indexOf(+student.id) !== -1) {
                                student.favorite = true;
                            }
                            return student;
                        })
                    );
                    if (data.length > 0) {
                        this.subscriptions.add(
                            this.userService
                                .getUsersCompletion(data.map((student) => student.id))
                                .subscribe((completionArray: Array<any>) => {
                                    this.users.default.students = this.users.default.students.map(
                                        (student: User) => {
                                            completionArray.map((completion: any) => {
                                                if (completion.id === student.id) {
                                                    student.completion = completion.completion;
                                                }
                                            });
                                            return student;
                                        }
                                    );
                                })
                        );
                    }
                    this.loadingService.stopLoading('studentPanelBodyNextPage', 'getStudents');
                })
            );
        } else if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true') {
            filter.ids = this.favorites.users.join('|');
            filter.offset = this.currentPage.favorites.students * this.LIMIT;
            this.currentPage.favorites.students++;
            this.loadingService.startLoading('studentPanelBodyNextPage', 'getFavoritesStudents');
            this.subscriptions.add(
                this.userService.getUsers(filter).subscribe((data: Array<User>) => {
                    this.users.favorites.students = this.users.favorites.students.concat(
                        data.map((student: User) => {
                            student.favorite = true;
                            return student;
                        })
                    );
                    this.count.favorites.students = this.users.favorites.students.length;
                    if (data.length > 0) {
                        this.subscriptions.add(
                            this.userService
                                .getUsersCompletion(data.map((student) => student.id))
                                .subscribe((completionArray: Array<any>) => {
                                    this.users.favorites.students = this.users.favorites.students.map(
                                        (student: User) => {
                                            completionArray.map((completion: any) => {
                                                if (completion.id === student.id) {
                                                    student.completion = completion.completion;
                                                }
                                            });
                                            return student;
                                        }
                                    );
                                })
                        );
                    }
                    this.loadingService.stopLoading(
                        'studentPanelBodyNextPage',
                        'getFavoritesStudents'
                    );
                })
            );
        }
    }

    nextPageStudentGroup() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            limit: this.LIMIT,
            type: 'learner'
        };
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false') {
            filter.offset = this.currentPage.default.studentGroups * this.LIMIT;
            this.currentPage.default.studentGroups++;
            this.loadingService.startLoading('studentPanelBodyNextPage', 'getStudentGroups');
            this.subscriptions.add(
                this.groupService.getGroups(filter).subscribe((data: Array<Group>) => {
                    this.users.default.studentGroups = this.users.default.studentGroups.concat(
                        data.map((studentGroup: Group) => {
                            if (this.favorites.groups.indexOf(+studentGroup.id) !== -1) {
                                studentGroup.favorite = true;
                            }
                            return studentGroup;
                        })
                    );
                    this.loadingService.stopLoading('studentPanelBodyNextPage', 'getStudentGroups');
                })
            );
        } else if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true') {
            filter.ids = this.favorites.groups.join('|');
            filter.offset = this.currentPage.favorites.studentGroups * this.LIMIT;
            this.currentPage.favorites.studentGroups++;
            this.loadingService.startLoading(
                'studentPanelBodyNextPage',
                'getFavoritesStudentGroups'
            );
            this.subscriptions.add(
                this.groupService.getGroups(filter).subscribe((data: Array<Group>) => {
                    this.users.favorites.studentGroups = this.users.favorites.studentGroups.concat(
                        data.map((studentGroup: Group) => {
                            studentGroup.favorite = true;
                            return studentGroup;
                        })
                    );
                    this.count.favorites.studentGroups = this.users.favorites.studentGroups.length;
                    this.loadingService.stopLoading(
                        'studentPanelBodyNextPage',
                        'getFavoritesStudentGroups'
                    );
                })
            );
        }
    }

    nextPageTeacher() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            limit: this.LIMIT
        };
        if (this.loginService.getUser().roles.nationalAdmin) {
            filter.role =
                'internalTeacher|externalTeacher|corporationTeacher|siteTeacher|localAdmin|nationalAdmin|tutor';
        }
        if (
            this.loginService.getUser().roles.localAdmin ||
            this.loginService.getUser().roles.internalTeacher ||
            this.loginService.getUser().roles.externalTeacher
        ) {
            filter.role =
                'internalTeacher|externalTeacher|corporationTeacher|siteTeacher|localAdmin|tutor';
        }
        if (
            this.loginService.getUser().roles.corporationTeacher ||
            this.loginService.getUser().roles.siteTeacher
        ) {
            filter.role =
                'internalTeacher|externalTeacher|corporationTeacher|siteTeacher|localAdmin|tutor';
        }
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false') {
            filter.offset = this.currentPage.default.teachers * this.LIMIT;
            this.currentPage.default.teachers++;
            this.loadingService.startLoading('studentPanelBodyNextPage', 'getTeachers');
            this.subscriptions.add(
                this.userService.getUsers(filter).subscribe((data: Array<User>) => {
                    this.users.default.teachers = this.users.default.teachers.concat(
                        data.map((teacher: User) => {
                            if (this.favorites.users.indexOf(+teacher.id) !== -1) {
                                teacher.favorite = true;
                            }
                            return teacher;
                        })
                    );
                    if (data.length > 0) {
                        this.subscriptions.add(
                            this.userService
                                .getUsersCompletion(data.map((student) => student.id))
                                .subscribe((completionArray: Array<any>) => {
                                    this.users.default.teachers = this.users.default.teachers.map(
                                        (teacher: User) => {
                                            completionArray.map((completion: any) => {
                                                if (completion.id === teacher.id) {
                                                    teacher.completion = completion.completion;
                                                }
                                            });
                                            return teacher;
                                        }
                                    );
                                })
                        );
                    }
                    this.loadingService.stopLoading('studentPanelBodyNextPage', 'getTeachers');
                })
            );
        } else if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true') {
            filter.ids = this.favorites.users.join('|');
            filter.offset = this.currentPage.favorites.teachers * this.LIMIT;
            this.currentPage.favorites.teachers++;
            this.loadingService.startLoading('studentPanelBodyNextPage', 'getFavoritesTeachers');
            this.subscriptions.add(
                this.userService.getUsers(filter).subscribe((data: Array<User>) => {
                    this.users.favorites.teachers = this.users.favorites.teachers.concat(
                        data.map((teacher: User) => {
                            teacher.favorite = true;
                            return teacher;
                        })
                    );
                    if (data.length > 0) {
                        this.subscriptions.add(
                            this.userService
                                .getUsersCompletion(data.map((student) => student.id))
                                .subscribe((completionArray: Array<any>) => {
                                    this.users.favorites.teachers = this.users.favorites.teachers.map(
                                        (teacher: User) => {
                                            completionArray.map((completion: any) => {
                                                if (completion.id === teacher.id) {
                                                    teacher.completion = completion.completion;
                                                }
                                            });
                                            return teacher;
                                        }
                                    );
                                })
                        );
                    }
                    this.count.favorites.teachers = this.users.favorites.teachers.length;
                    this.loadingService.stopLoading(
                        'studentPanelBodyNextPage',
                        'getFavoritesTeachers'
                    );
                })
            );
        }
    }

    nextPageTeacherGroup() {
        const filter: any = {
            structureid: this.getSelectedStructure().id,
            limit: this.LIMIT,
            type: 'teacher'
        };
        if (this.route.snapshot.queryParamMap.get('search')) {
            filter.search = this.route.snapshot.queryParamMap.get('search');
        }
        if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false') {
            filter.offset = this.currentPage.default.teacherGroups * this.LIMIT;
            this.currentPage.default.teacherGroups++;
            this.loadingService.startLoading('studentPanelBodyNextPage', 'getTeacherGroup');
            this.subscriptions.add(
                this.groupService.getGroups(filter).subscribe((data: Array<Group>) => {
                    this.users.default.teacherGroups = this.users.default.teacherGroups.concat(
                        data.map((teacherGroup: Group) => {
                            if (this.favorites.groups.indexOf(+teacherGroup.id) !== -1) {
                                teacherGroup.favorite = true;
                            }
                            return teacherGroup;
                        })
                    );
                    this.loadingService.stopLoading('studentPanelBodyNextPage', 'getTeacherGroup');
                })
            );
        } else if (this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true') {
            filter.ids = this.favorites.groups.join('|');
            filter.offset = this.currentPage.favorites.teacherGroups * this.LIMIT;
            this.currentPage.favorites.teacherGroups++;
            this.loadingService.startLoading(
                'studentPanelBodyNextPage',
                'getFavoritesTeacherGroup'
            );
            this.subscriptions.add(
                this.groupService.getGroups(filter).subscribe((data: Array<Group>) => {
                    this.users.favorites.teacherGroups = this.users.favorites.teacherGroups.concat(
                        data.map((teacherGroup: Group) => {
                            teacherGroup.favorite = true;
                            return teacherGroup;
                        })
                    );
                    this.count.favorites.teacherGroups = this.users.favorites.teacherGroups.length;
                    this.loadingService.stopLoading(
                        'studentPanelBodyNextPage',
                        'getFavoritesTeacherGroup'
                    );
                })
            );
        }
    }

    canViewRoleGroups() {
        return (
            !this.loginService.getUser().roles.tutor &&
            !this.loginService.getUser().roles.corporationTeacher &&
            !this.loginService.getUser().roles.siteTeacher &&
            !this.loginService.getUser().roles.externalTeacher
        );
    }

    canViewGroups() {
        return (
            !this.loginService.getUser().roles.corporationTeacher &&
            !this.loginService.getUser().roles.siteTeacher
        );
    }

    canViewTeachers() {
        return (
            !this.loginService.getUser().roles.tutor &&
            !this.loginService.getUser().roles.externalTeacher
        );
    }

    isFavoriteView() {
        return this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true';
    }

    shouldDisplayCloudIcon() {
        return this.nextcloudV2Access.indexOf(this.getSelectedStructure().shortname) === -1;
    }

    getNextCloudLink(group: Group) {
        if (window.location.href.indexOf('pilot.easi-training.fr') !== -1) {
            return `https://pilot-${
                this.getSelectedStructure().shortname
            }.cloud.easi-training.fr/index.php/apps/files/?dir=/Groupes%20apprenants/${group.name}`;
        } else {
            return `https://${
                this.getSelectedStructure().shortname
            }.cloud.easi-training.fr/index.php/apps/files/?dir=/Groupes%20apprenants/${group.name}`;
        }
    }

    getSelectedStructure() {
        if (this.isNationalAdmin()) {
            if (this.structures.filter((item) => item.selected === true)[0]) {
                return {
                    id: this.structures.filter((item) => item.selected === true)[0].key,
                    name: this.structures.filter((item) => item.selected === true)[0].title,
                    shortname: this.structures.filter((item) => item.selected === true)[0].shortname
                };
            }
        } else {
            return {
                id: this.loginService.getUser().structureid,
                name: this.loginService.getUser().localStructure,
                shortname: this.structures.filter(
                    (item) => +item.key === +this.loginService.getUser().structureid
                )[0].shortname
            };
        }
    }

    updateSearch() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { search: this.search },
            queryParamsHandling: 'merge'
        });
        this.initValues();
    }

    cancelSearch() {
        this.search = '';
        this.updateSearch();
    }

    updateStructure($event: Array<Structure>) {
        this.structures = $event;
        this.initValues();
        this.loginService.updateLastStructure(this.getSelectedStructure());
        this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }

    isNationalAdmin() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    isFavorite() {
        return this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true';
    }

    addToFavorites($event: any) {
        if ($event.type === 'role-group') {
            this.subscriptions.add(
                this.favoriteService.addGroupToFavorites($event.element).subscribe(() => {
                    this.users.default.roleGroups.map((roleGroup: Group) => {
                        if (roleGroup.id === $event.element.id) {
                            roleGroup.favorite = true;
                            if (this.count.favorites.roleGroups) {
                                this.count.favorites.roleGroups++;
                            }
                            if (this.users.favorites.roleGroups) {
                                this.users.favorites.roleGroups.push(roleGroup);
                            }
                            this.favorites.groups.push(roleGroup.id);
                        }
                        return roleGroup;
                    });
                    this.flashMessageService.flash('Le groupe a été ajouté à vos favoris');
                })
            );
        } else if ($event.type === 'student') {
            this.subscriptions.add(
                this.favoriteService.addUserToFavorites($event.element).subscribe(() => {
                    this.users.default.students.map((user: User) => {
                        if (user.id === $event.element.id) {
                            user.favorite = true;
                            if (this.count.favorites.students) {
                                this.count.favorites.students++;
                            }
                            if (this.users.favorites.students) {
                                this.users.favorites.students.push(user);
                            }
                            this.favorites.users.push(user.id);
                        }
                        return user;
                    });
                    this.flashMessageService.flash("L'apprenant a été ajouté à vos favoris");
                })
            );
        } else if ($event.type === 'student-group') {
            this.subscriptions.add(
                this.favoriteService.addGroupToFavorites($event.element).subscribe(() => {
                    this.users.default.studentGroups.map((group: Group) => {
                        if (group.id === $event.element.id) {
                            group.favorite = true;
                            if (this.count.favorites.studentGroups) {
                                this.count.favorites.studentGroups++;
                            }
                            if (this.users.favorites.studentGroups) {
                                this.users.favorites.studentGroups.push(group);
                            }
                            this.favorites.groups.push(group.id);
                        }
                        return group;
                    });
                    this.flashMessageService.flash(
                        "Le groupe d'apprenant a été ajouté à vos favoris"
                    );
                })
            );
        } else if ($event.type === 'teacher') {
            this.subscriptions.add(
                this.favoriteService.addUserToFavorites($event.element).subscribe(() => {
                    this.users.default.teachers.map((user: User) => {
                        if (user.id === $event.element.id) {
                            user.favorite = true;
                            if (this.count.favorites.teachers) {
                                this.count.favorites.teachers++;
                            }
                            if (this.users.favorites.teachers) {
                                this.users.favorites.teachers.push(user);
                            }
                            this.favorites.users.push(user.id);
                        }
                        return user;
                    });
                    this.flashMessageService.flash('Le formateur a été ajouté à vos favoris');
                })
            );
        } else if ($event.type === 'teacher-group') {
            this.subscriptions.add(
                this.favoriteService.addGroupToFavorites($event.element).subscribe(() => {
                    this.users.default.teacherGroups.map((group: Group) => {
                        if (group.id === $event.element.id) {
                            group.favorite = true;
                            if (this.count.favorites.teacherGroups) {
                                this.count.favorites.teacherGroups++;
                            }
                            if (this.users.favorites.teacherGroups) {
                                this.users.favorites.teacherGroups.push(group);
                            }
                            this.favorites.groups.push(group.id);
                        }
                        return group;
                    });
                    this.flashMessageService.flash(
                        'Le groupe de formateur a été ajouté à vos favoris'
                    );
                })
            );
        }
    }

    removeFromFavorites($event: any) {
        if ($event.type === 'role-group') {
            this.subscriptions.add(
                this.favoriteService.removeGroupFromFavorites($event.element).subscribe(() => {
                    if (this.users.default.roleGroups) {
                        this.users.default.roleGroups.map((roleGroup: Group) => {
                            if (roleGroup.id === $event.element.id) {
                                roleGroup.favorite = false;
                            }
                            return roleGroup;
                        });
                    }
                    if (this.count.favorites.roleGroups) {
                        this.count.favorites.roleGroups--;
                    }
                    if (this.users.favorites.roleGroups) {
                        this.users.favorites.roleGroups.splice(
                            this.users.favorites.roleGroups.indexOf($event.element),
                            1
                        );
                    }
                    this.favorites.groups.splice(
                        this.favorites.groups.indexOf(+$event.element.id),
                        1
                    );
                    this.flashMessageService.flash('Le groupe a été retiré de vos favoris');
                })
            );
        } else if ($event.type === 'student') {
            this.subscriptions.add(
                this.favoriteService.removeUserFromFavorites($event.element).subscribe(() => {
                    if (this.users.default.students) {
                        this.users.default.students.map((student: User) => {
                            if (student.id === $event.element.id) {
                                student.favorite = false;
                            }
                            return student;
                        });
                    }
                    if (this.count.favorites.students) {
                        this.count.favorites.students--;
                    }
                    if (this.users.favorites.students) {
                        this.users.favorites.students.splice(
                            this.users.favorites.students.indexOf($event.element),
                            1
                        );
                    }
                    this.favorites.users.splice(
                        this.favorites.users.indexOf(+$event.element.id),
                        1
                    );
                    this.flashMessageService.flash("L'apprenant a été retiré de vos favoris");
                })
            );
        } else if ($event.type === 'student-group') {
            this.subscriptions.add(
                this.favoriteService.removeGroupFromFavorites($event.element).subscribe(() => {
                    if (this.users.default.studentGroups) {
                        this.users.default.studentGroups.map((studentGroup: Group) => {
                            if (studentGroup.id === $event.element.id) {
                                studentGroup.favorite = false;
                            }
                            return studentGroup;
                        });
                    }
                    if (this.count.favorites.studentGroups) {
                        this.count.favorites.studentGroups--;
                    }
                    if (this.users.favorites.studentGroups) {
                        this.users.favorites.studentGroups.splice(
                            this.users.favorites.studentGroups.indexOf($event.element),
                            1
                        );
                    }
                    this.favorites.groups.splice(
                        this.favorites.groups.indexOf(+$event.element.id),
                        1
                    );
                    this.flashMessageService.flash(
                        "Le group d'apprenant a été retiré de vos favoris"
                    );
                })
            );
        } else if ($event.type === 'teacher') {
            this.subscriptions.add(
                this.favoriteService.removeUserFromFavorites($event.element).subscribe(() => {
                    if (this.users.default.teachers) {
                        this.users.default.teachers.map((teacher: User) => {
                            if (teacher.id === $event.element.id) {
                                teacher.favorite = false;
                            }
                            return teacher;
                        });
                    }
                    if (this.count.favorites.teachers) {
                        this.count.favorites.teachers--;
                    }
                    if (this.users.favorites.teachers) {
                        this.users.favorites.teachers.splice(
                            this.users.favorites.teachers.indexOf($event.element),
                            1
                        );
                    }
                    this.favorites.users.splice(
                        this.favorites.users.indexOf(+$event.element.id),
                        1
                    );
                    this.flashMessageService.flash('Le formateur a été retiré de vos favoris');
                })
            );
        } else if ($event.type === 'teacher-group') {
            this.subscriptions.add(
                this.favoriteService.removeGroupFromFavorites($event.element).subscribe(() => {
                    if (this.users.default.teacherGroups) {
                        this.users.default.teacherGroups.map((teacherGroup: Group) => {
                            if (teacherGroup.id === $event.element.id) {
                                teacherGroup.favorite = false;
                            }
                            return teacherGroup;
                        });
                    }
                    if (this.count.favorites.teacherGroups) {
                        this.count.favorites.teacherGroups--;
                    }
                    if (this.users.favorites.teacherGroups) {
                        this.users.favorites.teacherGroups.splice(
                            this.users.favorites.teacherGroups.indexOf($event.element),
                            1
                        );
                    }
                    this.favorites.groups.splice(
                        this.favorites.groups.indexOf(+$event.element.id),
                        1
                    );
                    this.flashMessageService.flash(
                        'Le group de formateurs a été retiré de vos favoris'
                    );
                })
            );
        }
    }

    toggleFavorites() {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                favoritesPanel:
                    this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true'
                        ? 'false'
                        : 'true'
            },
            queryParamsHandling: 'merge'
        });
    }

    toggleView(view: string) {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: { view: view },
            queryParamsHandling: 'merge'
        });
    }

    isCurrentView(view: string) {
        return this.route.snapshot.queryParamMap.get('view') === view;
    }

    getRoleGroupsCount() {
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            this.count &&
            !!this.count.default.roleGroups !== undefined
        ) {
            return this.count.default.roleGroups;
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            this.count &&
            this.count.favorites.roleGroups !== undefined
        ) {
            return this.count.favorites.roleGroups;
        }
    }

    getStudentsCount() {
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            this.count &&
            !!this.count.default.students !== undefined
        ) {
            return this.count.default.students;
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            this.count &&
            this.count.favorites.students !== undefined
        ) {
            return this.count.favorites.students;
        }
    }

    getStudentGroupsCount() {
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            this.count &&
            !!this.count.default.studentGroups !== undefined
        ) {
            return this.count.default.studentGroups;
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            this.count &&
            this.count.favorites.studentGroups !== undefined
        ) {
            return this.count.favorites.studentGroups;
        }
    }

    getTeachersCount() {
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            this.count &&
            !!this.count.default.teachers !== undefined
        ) {
            return this.count.default.teachers;
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            this.count &&
            this.count.favorites.teachers !== undefined
        ) {
            return this.count.favorites.teachers;
        }
    }

    getTeacherGroupsCount() {
        if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'false' &&
            this.count &&
            !!this.count.default.teacherGroups !== undefined
        ) {
            return this.count.default.teacherGroups;
        } else if (
            this.route.snapshot.queryParamMap.get('favoritesPanel') === 'true' &&
            this.count &&
            this.count.favorites.teacherGroups !== undefined
        ) {
            return this.count.favorites.teacherGroups;
        }
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }
}
