import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';

import { LibraryService } from '@/services/library.service';
import { LoginService } from '@/services/login.service';
import { LoadingService } from '@/services/loading.service';
import { UserService } from '@/services/user.service';

import { Content } from '@/structures/content';
import { User } from '@/structures/user';
import { DatesPipe } from 'src/app/pipes/dates.pipe';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-content-details',
    templateUrl: './content-details.component.html',
    styleUrls: ['./content-details.component.scss'],
    providers: [DatesPipe],
    standalone: false
})
export class ContentDetailsComponent implements OnInit {
    openContentCreation: Subject<Content> = new Subject();
    openEditAuthor: Subject<Content> = new Subject();

    subscriptions = new Subscription();

    content: Content;
    author: User;

    constructor(
        @Inject(MAT_DIALOG_DATA) private contentId: number,
        private dialogRef: MatDialogRef<ContentDetailsComponent>,
        private libraryService: LibraryService,
        private loginService: LoginService,
        private loadingService: LoadingService,
        private userService: UserService,
        private datesPipe: DatesPipe
    ) {}

    ngOnInit() {
        this.loadingService.startLoading('contentDetailsDialog', 'getContent');
        this.subscriptions.add(
            this.libraryService.getContent(this.contentId).subscribe(
                (data: Content) => {
                    this.content = data;
                    this.loadingService.startLoading('contentDetailsDialog', 'getAuthor');
                    this.subscriptions.add(
                        this.userService.getUser(this.content.author).subscribe(
                            (author: User) => {
                                this.loadingService.stopLoading(
                                    'contentDetailsDialog',
                                    'getAuthor'
                                );
                                this.author = author;
                            },
                            (error: HttpErrorResponse) => {
                                this.loadingService.stopLoading('contentDetailsDialog');
                            }
                        )
                    );
                    this.loadingService.stopLoading('contentDetailsDialog', 'getContent');
                },
                (error: HttpErrorResponse) => {
                    this.closeDialog();
                    this.loadingService.stopLoading('contentDetailsDialog');
                }
            )
        );
    }

    isTrainingOfTeachers() {
        if (this.content) {
            return this.content.is_training_of_teachers;
        }
    }

    getContentComment() {
        if (this.content) {
            if (this.content.comment) {
                return (
                    this.content.comment +
                    ' (' +
                    new Date(this.content.comment_date).toLocaleDateString() +
                    ')'
                );
            }
        }
    }

    getContentStatus() {
        if (this.content.hidden) {
            return 'Il existe une version plus récente de ce contenu dans la bibliothèque';
        }
        if (this.content.archived) {
            return 'Ce contenu est archivé';
        }
        if (!this.content.enabled) {
            return 'Ce contenu est désactivé';
        }
    }

    getContentColor() {
        if (this.content) {
            switch (this.content.category) {
                case 'preparation':
                case 'ressource':
                case 'devoir':
                    return '#199fe2';
                case 'tp':
                case 'presentiel':
                case 'url':
                    return '#01a59b';
                case 'positionnement':
                case 'evaluation':
                    return '#007a73';
                case 'travail':
                    return '#19BAE2';
                case 'corrige':
                    return '#1F94CE';
                default:
                    switch (this.content.level) {
                        case 'parcours':
                            return '#01a59b';
                        case 'bloc':
                            return '#005980';
                        case 'competence':
                            return '#ff5a00';
                        case 'module':
                            return '#ea63d2';
                        case 'sequence':
                            return '#ab80fb';
                    }
            }
        }
    }

    getContentCategory() {
        if (this.content.level) {
            return this.libraryService.getCategoryAndLevelLabelFromKey(this.content.level);
        } else {
            return this.libraryService.getCategoryAndLevelLabelFromKey(this.content.category);
        }
    }

    getDialogTitle() {
        if (this.content) {
            if (this.content.level) {
                return `Fiche d'assemblage (#${this.content.id})`;
            } else {
                return `Fiche d'activité (#${this.content.id})`;
            }
        }
    }

    getCategoryIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getContentTitle() {
        return this.content.title;
    }

    getOriginIcon() {
        if (!this.content.structureid) {
            return 'icon-national';
        } else if (this.content.structureid && this.content.sites.length === 0) {
            return 'icon-local';
        }
    }

    getContentOrigin() {
        if (!this.content.structureid) {
            return 'Contenu national';
        } else if (this.content.structureid && this.content.sites.length === 0) {
            return `Contenu local : <b>${this.content.localStructure}</b>`;
        } else {
            if (this.content.sites.length === 1) {
                return `Contenu restreint à un site : <i>${this.content.localStructure}</i> : <b>${this.content.sites[0].name}</b>`;
            } else {
                let string = `Contenu restreint à plusieurs sites : <i>${this.content.localStructure}</i> : <b>`;
                for (const i in this.content.sites) {
                    if (this.content.sites[i]) {
                        string += this.content.sites[i].name + ', ';
                    }
                }
                string = string.slice(0, string.length - 2);
                string += '</b>';
                return string;
            }
        }
    }

    getContentDescription() {
        return this.content.description.replace(/\n/g, '<br />');
    }

    getContentDomains() {
        return this.content.domains;
    }

    getContentKeywords() {
        return this.content.keywords;
    }

    getContentGuide() {
        if (this.content.guide) {
            const tmp = this.content.guide.split('/');
            const res = tmp[tmp.length - 1];
            if (res.length < 32) {
                return decodeURIComponent(res) + '';
            } else {
                const hash = res.substring(0, 32);
                const regex = RegExp('^[a-z0-9]+$', 'g');
                if (!regex.test(hash)) {
                    return decodeURIComponent(res);
                } else {
                    return decodeURIComponent(res.substring(32));
                }
            }
        } else {
            return 'Guide non renseigné';
        }
    }

    getContentVersion() {
        return this.content.version;
    }

    getContentCreationDate() {
        let string = `(créé le ${this.datesPipe.transform(this.content.timecreated)}`;
        if (this.content.timemodified !== '' && this.content.version > 1) {
            string += `, dernière mise à jour le ${this.datesPipe.transform(
                this.content.timemodified
            )}`;
        }
        string += ')';
        return string;
    }

    getContentCode() {
        if (this.content.ucode) {
            return this.content.ucode;
        } else {
            return 'Code contenu non renseigné';
        }
    }

    getContentTaxonomy() {
        if (this.content.taxonomy) {
            return this.content.taxonomy;
        } else {
            return 'Taxonomie non renseigné';
        }
    }

    getContentProvider() {
        if (this.content.provider) {
            return this.content.provider;
        } else {
            return 'Fournisseur non renseigné';
        }
    }

    getContentCopyright() {
        if (this.content.copyright) {
            return this.content.copyright;
        } else {
            return 'Copyright non renseigné';
        }
    }

    showPrice() {
        return this.content.hasprice && this.content.price;
    }

    getContentPrice() {
        return this.content.price + '€';
    }

    showDuration() {
        if (
            this.content.duration &&
            this.content.duration !== '00:00' &&
            (this.content.level === 'module' ||
                this.content.level === 'sequence' ||
                !this.content.level)
        ) {
            return true;
        }
        return false;
    }

    isIncompleteDuration() {
        if (this.content.level) {
            return this.checkIncompleteDurationRecursive(this.content);
        }
        return false;
    }

    checkIncompleteDurationRecursive(entry) {
        if (
            (!entry.duration || entry.duration === '00:00') &&
            (!entry.content_duration || entry.content_duration === '00:00')
        ) {
            return true;
        }
        for (const i in entry.children) {
            if (entry.children[i]) {
                const toRename = this.checkIncompleteDurationRecursive(entry.children[i]);
                if (toRename) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    getContentDuration() {
        const splittedTime = this.content.duration.split(':');
        if (splittedTime[0] === '00') {
            return splittedTime[1] + 'mn';
        } else {
            if (splittedTime[0][0] === '0') {
                splittedTime[0] = splittedTime[0][1];
            }
            return splittedTime[0] + 'h' + splittedTime[1] + 'mn';
        }
    }

    getContentAuthor() {
        return `${this.author.lastname.toUpperCase()} ${this.author.firstname}`;
    }

    canEditAuthor() {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            (this.loginService.getUser().roles.localAdmin &&
                this.content.structureid === +this.loginService.getUser().structureid)
        );
    }

    editAuthor() {
        this.openEditAuthor.next(this.content);
    }

    canEditContent() {
        if (this.content) {
            if (this.loginService.getUser().roles.nationalAdmin) {
                return true;
            }

            if (this.loginService.getUser().roles.localAdmin) {
                if (this.content.structureid === +this.loginService.getUser().structureid) {
                    return true;
                }
            }
        }
        return false;
    }

    openEditContentDialog() {
        this.closeDialog();
        this.openContentCreation.next(this.content);
    }

    isLoading() {
        return this.loadingService.isLoading('contentDetailsDialog');
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
