// Internal dependencies
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-approve-content',
    templateUrl: './approve-content.component.html',
    styleUrls: ['./approve-content.component.scss'],
    imports: [SharedModule, MatDialogModule]
})
export class ApproveContentComponent implements OnInit {
    comment: string;

    constructor(
        public dialogRef: MatDialogRef<ApproveContentComponent>,
        @Inject(MAT_DIALOG_DATA) public content: Content,
        private libraryService: LibraryService
    ) {}

    ngOnInit() {
        this.comment = '';
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    canApproveContent() {
        return this.comment.length > 0;
    }

    approveContent() {
        if (this.canApproveContent()) {
            this.dialogRef.close(this.comment);
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
