import { Component, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

import { PanelService } from '@/services/panel.service';
import { LoginService } from '@/services/login.service';

@Component({
    selector: 'app-more-actions',
    templateUrl: './more-actions.component.html',
    styleUrls: ['./more-actions.component.scss'],
    standalone: false
})
export class MoreActionsComponent implements OnInit {
    @Input() element: any;
    @Input() moreActionOrigin: string;
    @Input() moreActionClass: string;

    @Input() close: Function;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
        private panelService: PanelService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.renderer.listen('window', 'click', ($event: Event) => {
            if ($event.target !== this.el.nativeElement) {
                this.closeComponent();
            }
        });

        this.panelService.closedAllOpened.subscribe({
            next: (el: ElementRef) => {
                if (
                    this.el.nativeElement.children[0].classList[0] !==
                    el.nativeElement.children[0].classList[0]
                ) {
                    this.closeComponent();
                }
            }
        });

        setTimeout(() => {
            this.panelService.closeAllOpened(this.el);
        });
    }

    isAdmin() {
        return (
            this.loginService.getUser().roles.nationalAdmin ||
            this.loginService.getUser().roles.localAdmin
        );
    }

    hasSeenCatalog() {
        return this.loginService.getUser().ed_has_seen_catalog;
    }

    closeComponent() {
        this.close();
    }
}
