// Internal dependencies
import { Component, OnInit, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';
import * as FileSaver from 'file-saver';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { AdminService } from '@/services/admin.service';
import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes
import { FileDropzoneDirective } from 'src/app/directives/file-dropdown.directive';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-mass-import',
    templateUrl: './mass-import.component.html',
    styleUrls: ['./mass-import.component.scss'],
    imports: [SharedModule, MatDialogModule, FileDropzoneDirective]
})
export class MassImportComponent implements OnInit {
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    @ViewChild('fileInput', { static: false }) fileInput;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MassImportComponent>,
        private adminService: AdminService,
        private libraryService: LibraryService,
        private loadingService: LoadingService,
        private loginService: LoginService
    ) {}

    subscriptions = new Subscription();

    file: File;

    disabledLocalLibrary: boolean;
    loadingLocal: boolean;
    loadingNational: boolean;

    PENDING = 1;
    OK = 2;
    NOK = 3;
    ABORTED = 4;

    uploadedFileContent = '';

    arrayHeader = [];
    arrayContent = [];
    arraySortedIndexContent = [];

    arrayCreated = [];
    arrayPublished = [];
    arrayComment = [];

    domainsToCreate = [];
    providersToCreate = [];

    structuresList = [];
    domainsList = [];
    providersList = [];
    taxonomiesList = [];
    ucodesList = [];
    ucodesChildrenList = [];

    importStarted: boolean;
    importFinished = false;

    ngOnInit() {
        this.importStarted = false;
        const arrayObservable = [];
        this.loadingService.startLoading('massImportMainView');

        arrayObservable.push(this.libraryService.getDomains());
        arrayObservable.push(this.libraryService.getProviders());
        arrayObservable.push(this.libraryService.getTaxonomies());
        arrayObservable.push(this.loginService.getStructures());

        this.subscriptions.add(
            forkJoin(arrayObservable).subscribe((success: Array<any>) => {
                this.domainsList = success[0];
                this.providersList = success[1];
                this.taxonomiesList = success[2];
                this.structuresList = success[3];
                this.loadingService.stopLoading('massImportMainView');
            })
        );
    }

    isActivityImport() {
        return this.data.importType === 'mass_activity';
    }

    getTitle() {
        if (this.data.importType === 'mass_activity') {
            return "Import d'activités";
        } else {
            return "Import d'assemblage";
        }
    }

    getCurrentFileLabel() {
        if (this.file) {
            return this.file.name;
        } else {
            return 'Glisser-déposer votre fichier ici';
        }
    }

    openSelectFile() {
        this.fileInput.nativeElement.click();
    }

    uploadFile(files: FileList) {
        this.file = files.item(0);
        this.parseFile();
    }

    parseFile() {
        const converterUTF8 = new FileReader();

        converterUTF8.onload = (e: any) => {
            this.uploadedFileContent = e.target.result;
            if (this.uploadedFileContent.indexOf('ç') !== -1) {
                this.convertFileToArray();
            } else {
                const converterANSI = new FileReader();

                converterANSI.onload = (event: any) => {
                    this.uploadedFileContent = event.target.result;
                    this.convertFileToArray();
                };
                converterANSI.readAsText(this.file, 'ISO-8859-1');
            }
        };
        converterUTF8.readAsText(this.file, 'UTF8');
    }

    convertFileToArray() {
        this.arrayContent = this.uploadedFileContent.split('\n');
        for (const i in this.arrayContent) {
            if (this.arrayContent[i]) {
                this.arrayContent[i] = this.arrayContent[i].split(';');
                for (const j in this.arrayContent[i]) {
                    if (this.arrayContent[i][j]) {
                        this.arrayContent[i][j] = this.arrayContent[i][j].trim();
                    }
                }
            }
        }
        this.arrayHeader = this.arrayContent.splice(0, 1)[0];
        this.arrayHeader[this.arrayHeader.length - 1] = this.arrayHeader[
            this.arrayHeader.length - 1
        ].trim();

        if (this.arrayHeader.indexOf('validationForçable') === -1) {
            this.openErrorDialog.next(
                "L'encodage du fichier n'est pas correct. Veuillez encoder votre fichier en UTF-8 puis réessayer"
            );
        } else if (
            this.arrayHeader.indexOf('type*') === -1 &&
            this.data.importType === 'mass_assembly'
        ) {
            this.openErrorDialog.next(
                "Le format du fichier n'est pas correct pour un import d'assemblages. Veuillez vérifier le contenu de votre fichier."
            );
        } else if (
            this.arrayHeader.indexOf('categorie*') === -1 &&
            this.data.importType === 'mass_activity'
        ) {
            this.openErrorDialog.next(
                "Le format du fichier n'est pas correct pour un import d'activités. Veuillez vérifier le contenu de votre fichier."
            );
        }

        const ucodes = {
            ucodes: []
        };
        const ucodesChildren = {
            ucodes: []
        };
        const arrayObservable = [];

        for (const l in this.arrayContent) {
            if (!!this.arrayContent[l][this.getIndexFromString('code')]) {
                ucodes.ucodes.push(this.arrayContent[l][this.getIndexFromString('code')]);
            }
        }

        for (const k in this.arrayContent) {
            if (
                this.arrayContent[k][this.getIndexFromString('code_contenu')] !== '' &&
                this.arrayContent[k][this.getIndexFromString('code_contenu')] !== undefined
            ) {
                ucodesChildren.ucodes = ucodesChildren.ucodes.concat(
                    this.arrayContent[k][this.getIndexFromString('code_contenu')].split('|')
                );
            }
        }
        if (ucodes.ucodes.length) {
            arrayObservable.push(this.libraryService.getContentByUcode(ucodes));
        }
        if (!this.isActivityImport() && ucodesChildren.ucodes.length) {
            arrayObservable.push(this.libraryService.getContentByUcode(ucodesChildren));
        }
        if (arrayObservable.length === 0) {
            this.checkArray();
        }
        this.subscriptions.add(
            forkJoin(arrayObservable).subscribe((success: Array<any>) => {
                if (ucodes.ucodes.length) {
                    this.ucodesList = success[0];
                } else {
                    this.ucodesList = [];
                }
                if (!this.isActivityImport()) {
                    if (ucodesChildren.ucodes.length) {
                        if (!ucodes.ucodes.length) {
                            this.ucodesChildrenList = success[0];
                        } else {
                            this.ucodesChildrenList = success[1];
                        }
                    } else {
                        this.ucodesChildrenList = [];
                    }
                    this.sortArray();
                }
                this.checkArray();
            })
        );
    }

    sortArray() {
        const arraySequence = [];
        const arrayModule = [];
        const arrayCP = [];
        const arrayBloc = [];
        const arrayParcours = [];
        this.arrayContent.map((content: any) => {
            if (content[this.getIndexFromString('type*')] === 'Séquence') {
                arraySequence.push(content);
            }
            if (content[this.getIndexFromString('type*')] === 'Module') {
                arrayModule.push(content);
            }
            if (content[this.getIndexFromString('type*')] === 'Compétence') {
                arrayCP.push(content);
            }
            if (content[this.getIndexFromString('type*')] === 'Bloc') {
                arrayBloc.push(content);
            }
            if (content[this.getIndexFromString('type*')] === 'Parcours') {
                arrayParcours.push(content);
            }
            this.arraySortedIndexContent = arraySequence.concat(
                arrayModule.concat(arrayCP.concat(arrayBloc.concat(arrayParcours)))
            );
        });
    }

    checkArray() {
        if (
            this.arrayHeader.indexOf('titre*') === -1 ||
            (this.arrayHeader.indexOf('categorie*') === -1 && this.arrayHeader.indexOf('type*')) ===
                -1 ||
            this.arrayHeader.indexOf('description*') === -1 ||
            this.arrayHeader.indexOf('motClef*') === -1 ||
            (this.arrayHeader.indexOf('url*') === -1 &&
                this.arrayHeader.indexOf('code_contenu') === -1)
        ) {
            this.openErrorDialog.next(
                "Le format du fichier n'est pas correct pour un import d'assemblages. Veuillez vérifier le contenu de votre fichier."
            );
        }

        for (const i in this.arrayContent) {
            if (this.arrayContent[i]) {
                if (this.arrayContent[i].length !== this.arrayHeader.length) {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = 'Ligne invalide';
                    continue;
                }

                if (
                    this.ucodesList[this.arrayContent[i][this.getIndexFromString('code')]] !== -1 &&
                    this.arrayContent[i][this.getIndexFromString('typeImport')] === '' &&
                    this.arrayContent[i][this.getIndexFromString('code')] !== ''
                ) {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = 'Le code unique est déja utilisé';
                    continue;
                } else if (
                    this.ucodesList[this.arrayContent[i][this.getIndexFromString('code')]] === -1 &&
                    this.arrayContent[i][this.getIndexFromString('typeImport')] === 'mineur'
                ) {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = "Le code indiqué n'existe pas";
                    continue;
                } else if (
                    this.arrayContent[i][this.getIndexFromString('code')] === '' &&
                    this.arrayContent[i][this.getIndexFromString('typeImport')] === 'mineur'
                ) {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = 'Code obligatoire en cas de mise à jour mineur';
                    continue;
                } else if (
                    this.getIndexFromString('typeImport') === -1 &&
                    this.ucodesList[this.arrayContent[i][this.getIndexFromString('code')]] !== -1 &&
                    this.arrayContent[i][this.getIndexFromString('code')] !== ''
                ) {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = 'Ce code unique est déja utilisé';
                    continue;
                }

                if (this.getIndexFromString('typeImport') > -1) {
                    if (
                        this.arrayContent[i][this.getIndexFromString('typeImport')] !== '' &&
                        this.arrayContent[i][this.getIndexFromString('typeImport')] !== 'mineur'
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le format du champ 'typeImport' n'est pas correct";
                        continue;
                    }
                }

                let existingUcode = false;
                for (let z = 0; z < parseInt(i, 10); z++) {
                    if (
                        this.arrayContent[i][this.getIndexFromString('code')] ===
                            this.arrayContent[z][this.getIndexFromString('code')] &&
                        this.arrayContent[z][this.getIndexFromString('code')] !== '' &&
                        this.arrayContent[z][this.getIndexFromString('typeImport')] !== 'mineur'
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = 'Ce code est déja présent dans le document';
                        existingUcode = true;
                        break;
                    }
                }
                if (existingUcode) {
                    continue;
                }

                if (this.arrayContent[i][this.getIndexFromString('titre*')] === '') {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = "Le champ 'titre' ne peut pas être vide";
                    continue;
                }

                if (this.data.importType === 'mass_activity') {
                    if (this.arrayContent[i][this.getIndexFromString('categorie*')] === '') {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le champ 'categorie' ne peut pas être vide";
                        continue;
                    }
                    if (
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'ressource' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'positionnement' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'préparation' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'travaux pratiques' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'activité présentielle' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'mise en application' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'evaluation' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'corrigé' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'glossaire' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'devoir'
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le champ 'categorie' n'est pas valide";
                        continue;
                    }
                } else {
                    if (
                        this.arrayContent[i][this.getIndexFromString('type*')].toLowerCase() !==
                            'parcours' &&
                        this.arrayContent[i][this.getIndexFromString('type*')].toLowerCase() !==
                            'bloc' &&
                        this.arrayContent[i][this.getIndexFromString('type*')].toLowerCase() !==
                            'compétence' &&
                        this.arrayContent[i][this.getIndexFromString('type*')].toLowerCase() !==
                            'module' &&
                        this.arrayContent[i][this.getIndexFromString('type*')].toLowerCase() !==
                            'séquence' &&
                        this.arrayContent[i][this.getIndexFromString('type*')].toLowerCase() !== ''
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le champ 'type' n'est pas valide";
                        continue;
                    }
                }

                if (this.arrayContent[i][this.getIndexFromString('description*')] === '') {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = "Le champ 'description' ne peut pas être vide";
                    continue;
                }

                if (this.arrayContent[i][this.getIndexFromString('domaine*')] === '') {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = "Le champ 'domaine' ne peut pas être vide";
                    continue;
                }

                this.checkDomainsForLine(i);

                if (this.getIndexFromString('duree*') > -1) {
                    if (
                        !this.arrayContent[i][this.getIndexFromString('duree*')].match(
                            /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g
                        ) &&
                        this.arrayContent[i][this.getIndexFromString('duree*')] !== ''
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le format du champ 'duree' n'est pas correct";
                        continue;
                    }
                }

                if (this.getIndexFromString('auteur') > -1) {
                    if (
                        isNaN(this.arrayContent[i][this.getIndexFromString('auteur')]) &&
                        this.arrayContent[i][this.getIndexFromString('auteur')] !== ''
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le format du champ 'auteur' n'est pas correct";
                        continue;
                    }
                }

                this.checkProvidersForLine(+i);
                if (this.arrayContent[i][this.getIndexFromString('taxonomie')] !== '') {
                    if (!this.checkTaxonomiesForLine(+i)) {
                        continue;
                    }
                }

                // payant = 0 => pas de prix
                // payant  = 1 => un prix en float
                // payant = 2 (nouveau) => Le prix est masqué pour tous sauf admins (la notion d'admin doit être précisée, actuellement seuls les national Admins peuvent le voir )
                // payant = 3  (nouveau) => on prends en compte  pricetxt = 'Toto TAta blabla'
                if (this.getIndexFromString('payant') > -1) {
                    if (
                        this.arrayContent[i][this.getIndexFromString('payant')] !== '0' &&
                        this.arrayContent[i][this.getIndexFromString('payant')] !== '1' &&
                        this.arrayContent[i][this.getIndexFromString('payant')] !== '2' &&
                        this.arrayContent[i][this.getIndexFromString('payant')] !== '3' &&
                        this.arrayContent[i][this.getIndexFromString('payant')] !== ''
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le format du champ 'payant' n'est pas correct";
                        continue;
                    } else {
                        this.arrayContent[i][this.getIndexFromString('payant')] = Number(
                            this.arrayContent[i][this.getIndexFromString('payant')]
                        );
                    }

                    // erreur si payant == 1 et prix au format texte
                    if (
                        this.arrayContent[i][this.getIndexFromString('payant')] === '1' &&
                        isNaN(Number(this.arrayContent[i][this.getIndexFromString('prix')]))
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] =
                            "Le format des champs 'payant' et 'prix' ne sont pas compatibles. Le prix doit être un nombre";
                        continue;
                    }
                }

                if (this.arrayContent[i][this.getIndexFromString('motClef*')] === '') {
                    this.arrayCreated[i] = this.ABORTED;
                    this.arrayPublished[i] = this.ABORTED;
                    this.arrayComment[i] = "Le champ 'motClef' ne peut pas être vide";
                    continue;
                }

                if (this.getIndexFromString('actif') > -1) {
                    if (
                        this.arrayContent[i][this.getIndexFromString('actif')] !== 'O' &&
                        this.arrayContent[i][this.getIndexFromString('actif')] !== 'N' &&
                        this.arrayContent[i][this.getIndexFromString('actif')] !== ''
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le format du champ 'actif' n'est pas correct";
                        continue;
                    }
                }

                if (this.getIndexFromString('structure') > -1) {
                    if (
                        isNaN(this.arrayContent[i][this.getIndexFromString('structure')]) &&
                        this.arrayContent[i][this.getIndexFromString('structure')] !== ''
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le format du champ 'structure' n'est pas correct";
                        continue;
                    }
                }

                if (this.getIndexFromString('validationForçable') > -1) {
                    if (
                        this.arrayContent[i][this.getIndexFromString('validationForçable')] !==
                            'O' &&
                        this.arrayContent[i][this.getIndexFromString('validationForçable')] !==
                            'N' &&
                        this.arrayContent[i][this.getIndexFromString('validationForçable')] !== ''
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] =
                            "Le format du champ 'validationForçable' n'est pas correct";
                        continue;
                    }
                }

                if (this.data.importType === 'mass_activity') {
                    if (
                        this.arrayContent[i][this.getIndexFromString('url*')] === '' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'travaux pratiques' &&
                        this.arrayContent[i][
                            this.getIndexFromString('categorie*')
                        ].toLowerCase() !== 'activité présentielle'
                    ) {
                        this.arrayCreated[i] = this.ABORTED;
                        this.arrayPublished[i] = this.ABORTED;
                        this.arrayComment[i] = "Le champ 'url' ne peut pas être vide";
                        continue;
                    }
                }

                if (this.data.importType === 'mass_assembly') {
                    let existingUcodeChildren = true;
                    const ucodesChildrenArray = this.arrayContent[i][
                        this.getIndexFromString('code_contenu')
                    ].split('|');
                    for (const k in ucodesChildrenArray) {
                        if (this.ucodesChildrenList[ucodesChildrenArray[k]] === -1) {
                            existingUcodeChildren = false;
                            for (const l in this.arrayContent) {
                                if (
                                    ucodesChildrenArray[k] ===
                                    this.arrayContent[l][this.getIndexFromString('code')]
                                ) {
                                    existingUcodeChildren = true;
                                    break;
                                }
                            }
                            if (existingUcodeChildren === false) {
                                this.arrayCreated[i] = this.ABORTED;
                                this.arrayPublished[i] = this.ABORTED;
                                this.arrayComment[i] =
                                    "L'un des codes de 'code_contenu' n'existe pas";
                                break;
                            }
                        }
                    }
                    if (existingUcodeChildren === false) {
                        continue;
                    }
                }

                if (this.arrayCreated[i] !== this.ABORTED) {
                    this.arrayCreated[i] = this.PENDING;
                }
                if (this.arrayPublished[i] !== this.ABORTED) {
                    this.arrayPublished[i] = this.PENDING;
                }
            }
        }
    }

    checkDomainsForLine(index) {
        const arrayDomain = this.arrayContent[index][this.getIndexFromString('domaine*')].split(
            '|'
        );
        arrayDomain.map((domain) => {
            let existing = false;
            domain = domain.trim();
            for (const i in this.domainsList) {
                if (this.domainsList[i].name === domain) {
                    existing = true;
                }
            }
            if (!existing) {
                if (this.domainsToCreate.indexOf(domain) === -1 && domain !== '') {
                    this.domainsToCreate.push(domain);
                    this.arrayComment[index] = 'Le domaine va être créé';
                }
            }
        });
    }

    checkProvidersForLine(index: number) {
        let existing = false;
        for (const i in this.providersList) {
            if (
                this.providersList[i].name ===
                this.arrayContent[index][this.getIndexFromString('fournisseur')]
            ) {
                existing = true;
            }
        }
        if (!existing) {
            if (
                this.providersToCreate.indexOf(
                    this.arrayContent[index][this.getIndexFromString('fournisseur')]
                ) === -1 &&
                this.arrayContent[index][this.getIndexFromString('fournisseur')] !== ''
            ) {
                this.providersToCreate.push(
                    this.arrayContent[index][this.getIndexFromString('fournisseur')]
                );
                this.arrayComment[index] = 'Le fournisseur va être créé';
            }
        }
    }

    checkTaxonomiesForLine(index: number) {
        let existing = false;
        for (const i in this.taxonomiesList) {
            if (
                this.taxonomiesList[i].name ===
                this.arrayContent[index][this.getIndexFromString('taxonomie')]
            ) {
                existing = true;
            }
        }
        if (!existing) {
            this.arrayCreated[index] = this.ABORTED;
            this.arrayPublished[index] = this.ABORTED;
            this.arrayComment[index] = "Cette taxonomie n'existe pas";
            return false;
        }
        return true;
    }

    getIndexFromString(string: any) {
        for (const i in this.arrayHeader) {
            if (this.arrayHeader[i] === string) {
                return +i;
            }
        }
        return -1;
    }

    getStructureNameFromId(id: number) {
        for (const i in this.structuresList) {
            if (this.structuresList[i].id === +id) {
                return this.structuresList[i].name;
            }
        }
        return 0;
    }

    getFileType(element) {
        if (!element) {
            return '';
        }

        const extension = /[^.]+$/.exec(element)[0].toLowerCase();

        switch (extension) {
            case 'pdf':
                return 'PDF';
            case 'csv':
                return 'CSV';
            case 'mp4':
            case 'f4v':
            case 'swf':
            case 'flv':
                return 'VIDEO';
            case 'html':
                return 'HTML';
            case 'zip':
                return 'SCORM';
            case 'odt':
            case 'ott':
            case 'odm':
            case 'oth':
            case 'ods':
            case 'ots':
            case 'odg':
            case 'otg':
            case 'odp':
            case 'otp':
            case 'odf':
            case 'oxt':
                return 'OFFICE';
            case 'sldprt':
            case 'sldasm':
            case 'slddrw':
            case 'iges':
            case 'step':
            case 'toppkg':
            case 'psimsch':
            case 'smv':
            case 'amf':
            case 'stl':
            case 'obj':
            case '3mf':
            case 'ply':
            case 'gcode':
            case 'dft':
            case 'mpp':
            case 'stp':
            case 'top':
            case 'x_t':
            case 'bmp':
            case 'dwg':
            case 'dxf':
            case 'db':
            case 'rpt':
            case 'igs':
                return '3D';
            case 'json':
                return 'json';
            default:
                if (
                    extension.indexOf('xl') === 0 ||
                    extension.indexOf('do') === 0 ||
                    extension.indexOf('pp') === 0
                ) {
                    return 'OFFICE';
                }
                return 'INCONNU';
        }
    }

    getCellValue(line, string) {
        return line[this.getIndexFromString(string)];
    }

    getComment(index) {
        return this.arrayComment[index];
    }

    getCreatedStatus(index) {
        return this.arrayCreated[index];
    }

    getPublishedStatus(index) {
        return this.arrayPublished[index];
    }

    isImportStarted() {
        return this.importStarted;
    }

    startImport() {
        this.importStarted = true;

        const arrayObservable = [];
        this.domainsToCreate.map((domain) => {
            arrayObservable.push(this.adminService.createDomain(domain));
        });
        this.providersToCreate.map((provider) => {
            arrayObservable.push(this.adminService.createProvider(provider));
        });
        this.loadingService.startLoading('massImportFooterView');
        if (arrayObservable.length === 0) {
            if (this.data.importType === 'mass_activity') {
                this.importActivityRecursive(0);
            } else {
                this.importAssemblyRecursive(0);
            }
        } else {
            this.subscriptions.add(
                forkJoin(arrayObservable).subscribe(() => {
                    if (this.data.importType === 'mass_activity') {
                        this.importActivityRecursive(0);
                    } else {
                        this.importAssemblyRecursive(0);
                    }
                })
            );
        }
    }

    importActivityRecursive(i) {
        if (i < this.arrayContent.length) {
            if (this.arrayCreated[i] === this.PENDING) {
                const content = this.initActivity(this.arrayContent[i]);
                if (this.arrayContent[i][this.getIndexFromString('typeImport')] !== 'mineur') {
                    this.subscriptions.add(
                        this.libraryService.createActivity(content).subscribe(
                            (success: any) => {
                                this.arrayCreated[i] = this.OK;
                                success.publicationMode = 'new';
                                success.validator = [{ id: this.loginService.getUser().id }];
                                this.subscriptions.add(
                                    this.libraryService
                                        .approveContent(success, undefined)
                                        .subscribe(
                                            () => {
                                                this.arrayPublished[i] = this.OK;
                                                this.importActivityRecursive(i + 1);
                                            },
                                            (error: HttpErrorResponse) => {
                                                this.arrayComment[i] = error.error.userMessage;
                                                this.arrayPublished[i] = this.NOK;
                                                this.importActivityRecursive(i + 1);
                                            }
                                        )
                                );
                            },
                            (error: HttpErrorResponse) => {
                                this.arrayComment[i] = error.error.userMessage;
                                this.arrayCreated[i] = this.NOK;
                                this.importActivityRecursive(i + 1);
                            }
                        )
                    );
                } else {
                    const contentToEdit = {
                        id: this.ucodesList[this.arrayContent[i][this.getIndexFromString('code')]],
                        level: 'activity',
                        downloadUrl: this.arrayContent[i][this.getIndexFromString('url*')]
                    };
                    this.libraryService.duplicateContent(contentToEdit, false).subscribe(
                        (success) => {
                            content.id = success.id;
                            content.ucode = null;
                            content.desiredPublicationMode = 'update';
                            this.libraryService.updateActivity(content).subscribe(
                                () => {
                                    this.arrayCreated[i] = this.OK;
                                    const tmp: any = {};
                                    tmp.publicationMode = 'minor-update';
                                    tmp.validator = [{ id: this.loginService.getUser().id }];
                                    tmp.id = content.id;
                                    this.libraryService.approveContent(tmp, undefined).subscribe(
                                        () => {
                                            this.arrayPublished[i] = this.OK;
                                            this.importActivityRecursive(i + 1);
                                        },
                                        (error: HttpErrorResponse) => {
                                            this.arrayComment[i] = error.error.userMessage;
                                            this.arrayPublished[i] = this.NOK;
                                            this.importActivityRecursive(i + 1);
                                        }
                                    );
                                },
                                (error: HttpErrorResponse) => {
                                    this.arrayComment[i] = error.error.userMessage;
                                    this.arrayCreated[i] = this.NOK;
                                    this.importActivityRecursive(i + 1);
                                }
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this.arrayComment[i] = error.error.userMessage;
                            this.arrayCreated[i] = this.NOK;
                            this.importActivityRecursive(i + 1);
                        }
                    );
                }
            } else {
                this.importActivityRecursive(i + 1);
            }
        } else {
            this.loadingService.stopLoading('massImportFooterView');
            this.exportAsCSV();
        }
    }

    initActivity(contentArray) {
        const contentObject: any = {
            status: 'draft',
            sites: [],
            hidden: false,
            ucode: contentArray[this.getIndexFromString('code')]
                ? contentArray[this.getIndexFromString('code')]
                : '',
            title: contentArray[this.getIndexFromString('titre*')],
            category: this.getCategoryFromString(
                contentArray[this.getIndexFromString('categorie*')]
            ),
            description: contentArray[this.getIndexFromString('description*')],
            domains: contentArray[this.getIndexFromString('domaine*')].split('|'),
            author: contentArray[this.getIndexFromString('auteur')]
                ? contentArray[this.getIndexFromString('auteur')]
                : this.loginService.getUser().id,
            hasprice: contentArray[this.getIndexFromString('payant')]
                ? Number(contentArray[this.getIndexFromString('payant')])
                : 0,
            keywords: contentArray[this.getIndexFromString('motClef*')].split('|'),
            enabled: contentArray[this.getIndexFromString('actif')]
                ? contentArray[this.getIndexFromString('actif')] === 'O'
                    ? true
                    : false
                : true,
            localStructure: contentArray[this.getIndexFromString('structure')]
                ? this.getStructureNameFromId(contentArray[this.getIndexFromString('structure')])
                : this.loginService.getUser().structureid,
            copyright: contentArray[this.getIndexFromString('copyright')]
                ? contentArray[this.getIndexFromString('copyright')]
                : 'UIAF',
            forceStatus: contentArray[this.getIndexFromString('validationForçable')]
                ? contentArray[this.getIndexFromString('validationForçable')] === 'O'
                    ? true
                    : false
                : true,
            downloadUrl: contentArray[this.getIndexFromString('url*')]
        };

        if (contentArray[this.getIndexFromString('type*')] === 'nathan') {
            contentObject.url = contentObject.downloadUrl;
            contentObject.type = 'nathan';
            delete contentObject.downloadUrl;
        }

        if (contentArray[this.getIndexFromString('duree*')] !== '') {
            contentObject.duration = contentArray[this.getIndexFromString('duree*')];
        }
        if (contentArray[this.getIndexFromString('taxonomie')] !== '') {
            contentObject.taxonomy = contentArray[this.getIndexFromString('taxonomie')];
        }
        if (contentArray[this.getIndexFromString('fournisseur')] !== '') {
            contentObject.provider = contentArray[this.getIndexFromString('fournisseur')];
        }
        if (contentArray[this.getIndexFromString('prix')] !== '') {
            if (contentArray[this.getIndexFromString('payant')] === 3) {
                contentObject.pricetxt = contentArray[this.getIndexFromString('prix')];
            } else {
                contentObject.price = Number(contentArray[this.getIndexFromString('prix')]);
            }
        }

        if (
            this.getCategoryFromString(contentArray[this.getIndexFromString('categorie*')]) ===
                'tp' ||
            this.getCategoryFromString(contentArray[this.getIndexFromString('categorie*')]) ===
                'presentiel'
        ) {
            contentObject.type = 'presentiel';
        }

        if (
            this.getCategoryFromString(contentArray[this.getIndexFromString('categorie*')]) ===
            'devoir'
        ) {
            contentObject.type = 'devoir';
        }

        contentObject.domains.filter((domain: any) => {
            return domain.trim() !== '';
        });

        return contentObject;
    }

    getCategoryFromString(string) {
        if (string.toLowerCase() === 'ressource') {
            return 'ressource';
        } else if (string.toLowerCase() === 'positionnement') {
            return 'positionnement';
        } else if (string.toLowerCase() === 'préparation') {
            return 'preparation';
        } else if (string.toLowerCase() === 'travaux pratiques') {
            return 'tp';
        } else if (string.toLowerCase() === 'activité présentielle') {
            return 'presentiel';
        } else if (string.toLowerCase() === 'mise en application') {
            return 'travail';
        } else if (string.toLowerCase() === 'evaluation') {
            return 'evaluation';
        } else if (string.toLowerCase() === 'corrigé') {
            return 'corrige';
        } else if (string.toLowerCase() === 'glossaire') {
            return 'glossaire';
        } else if (string.toLowerCase() === 'devoir') {
            return 'devoir';
        }
    }

    importAssemblyRecursive(index: number) {
        let realIndex: number;
        if (index < this.arraySortedIndexContent.length) {
            realIndex = this.arrayContent.findIndex((content: Content) => {
                return (
                    JSON.stringify(this.arraySortedIndexContent[index]) === JSON.stringify(content)
                );
            });
            if (this.arrayCreated[realIndex] === this.PENDING) {
                const content = this.initAssembly(this.arraySortedIndexContent[index]);
                this.subscriptions.add(
                    this.libraryService.createAssembly(content).subscribe(
                        (success: any) => {
                            this.arrayCreated[realIndex] = this.OK;
                            success.publicationMode = 'new';
                            success.validator = [{ id: this.loginService.getUser().id }];
                            if (content.ucode) {
                                this.ucodesChildrenList[content.ucode] = success.id;
                            }
                            this.subscriptions.add(
                                this.libraryService.approveContent(success, undefined).subscribe(
                                    () => {
                                        this.arrayPublished[realIndex] = this.OK;
                                        this.importAssemblyRecursive(index + 1);
                                    },
                                    (error: HttpErrorResponse) => {
                                        this.arrayComment[realIndex] = error.error.userMessage;
                                        this.arrayPublished[realIndex] = this.NOK;
                                        this.importAssemblyRecursive(index + 1);
                                    }
                                )
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this.arrayComment[realIndex] = error.error.userMessage;
                            this.arrayCreated[realIndex] = this.NOK;
                            this.importAssemblyRecursive(index + 1);
                        }
                    )
                );
            } else {
                this.importAssemblyRecursive(index + 1);
            }
        } else {
            this.loadingService.stopLoading('massImportFooterView');
            this.exportAsCSV();
        }
    }

    initAssembly(contentArray: Array<any>) {
        const contentObject: any = {
            status: 'draft',
            sites: [],
            hidden: false,
            ucode: contentArray[this.getIndexFromString('code')]
                ? contentArray[this.getIndexFromString('code')]
                : '',
            title: contentArray[this.getIndexFromString('titre*')],
            level: this.getLevelFromString(contentArray[this.getIndexFromString('type*')]),
            description: contentArray[this.getIndexFromString('description*')],
            domains: contentArray[this.getIndexFromString('domaine*')].split('|'),
            author: contentArray[this.getIndexFromString('auteur')]
                ? contentArray[this.getIndexFromString('auteur')]
                : this.loginService.getUser().id,
            hasprice: contentArray[this.getIndexFromString('payant')]
                ? contentArray[this.getIndexFromString('payant')].toString()
                : '0',
            keywords: contentArray[this.getIndexFromString('motClef*')].split('|'),
            enabled: contentArray[this.getIndexFromString('actif')]
                ? contentArray[this.getIndexFromString('actif')] === 'O'
                    ? true
                    : false
                : true,
            localStructure: contentArray[this.getIndexFromString('structure')]
                ? this.getStructureNameFromId(contentArray[this.getIndexFromString('structure')])
                : this.loginService.getUser().structureid,
            copyright: contentArray[this.getIndexFromString('copyright')]
                ? contentArray[this.getIndexFromString('copyright')]
                : 'UIAF',
            forceStatus: contentArray[this.getIndexFromString('validationForçable')]
                ? contentArray[this.getIndexFromString('validationForçable')] === 'O'
                    ? true
                    : false
                : true,
            children: this.getChildrenIdFromUcodes(contentArray)
        };

        if (contentArray[this.getIndexFromString('duree*')] !== '') {
            contentObject.duration = contentArray[this.getIndexFromString('duree*')];
        }
        if (contentArray[this.getIndexFromString('taxonomie')] !== '') {
            contentObject.taxonomy = contentArray[this.getIndexFromString('taxonomie')];
        }
        if (contentArray[this.getIndexFromString('fournisseur')] !== '') {
            contentObject.provider = contentArray[this.getIndexFromString('fournisseur')];
        }
        if (contentArray[this.getIndexFromString('prix')] !== '') {
            if (contentArray[this.getIndexFromString('payant')] === 3) {
                contentObject.pricetxt = contentArray[this.getIndexFromString('prix')];
            } else {
                contentObject.price = contentArray[this.getIndexFromString('prix')];
            }
        }

        contentObject.domains.filter((domain: any) => {
            return domain.trim() !== '';
        });

        return contentObject;
    }

    getChildrenIdFromUcodes(contentArray: Array<any>) {
        const ucodes = contentArray[this.getIndexFromString('code_contenu')].split('|');
        const children = [];

        for (const i in ucodes) {
            if (this.ucodesChildrenList[ucodes[i]]) {
                children.push({
                    id: this.ucodesChildrenList[ucodes[i]],
                    position: (parseInt(i, 10) + 1) * 1000000
                });
            }
        }
        return children;
    }

    getLevelFromString(level: string) {
        if (level === 'Parcours') {
            return 'parcours';
        } else if (level === 'Bloc') {
            return 'bloc';
        } else if (level === 'Compétence') {
            return 'competence';
        } else if (level === 'Module') {
            return 'module';
        } else if (level === 'Séquence') {
            return 'sequence';
        }
    }

    exportAsCSV() {
        const arrayTmp = [];
        arrayTmp.push(this.arrayHeader.join(';') + ';créé;publié;commentaire');
        for (const i in this.arrayContent) {
            if (Array.isArray(this.arrayContent[i])) {
                let tmp = this.arrayContent[i].join(';');
                if (this.arrayCreated[i] === this.OK) {
                    tmp += ';true';
                } else {
                    tmp += ';false';
                }
                if (this.arrayPublished[i] === this.OK) {
                    tmp += ';true';
                } else {
                    tmp += ';false';
                }
                if (this.arrayComment[i] !== undefined) {
                    tmp += ';' + this.arrayComment[i];
                } else {
                    tmp += '';
                }
                arrayTmp.push(tmp);
            }
        }
        const blob = new Blob(['\ufeff', arrayTmp.join('\n')], {
            type: 'text/plain;charset=UTF8;'
        });
        FileSaver.saveAs(blob, 'import_de_masse.csv');
        this.importFinished = true;
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }

    isImportFinished(): boolean {
        return this.importFinished;
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
