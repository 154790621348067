// Internal dependencies
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LoginService } from '@/services/login.service';
import { LoadingService } from '@/services/loading.service';
import { LibraryService } from '@/services/library.service';

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-h5p-player',
    templateUrl: './h5p-player.component.html',
    styleUrls: ['./h5p-player.component.scss'],
    imports: [SharedModule, MatSlideToggleModule]
})
export class H5PPlayerComponent implements OnInit, AfterViewInit {
    constructor(
        private loadingService: LoadingService,
        private libraryService: LibraryService,
        private loginService: LoginService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {}

    editing: boolean = false;
    content: Content;
    iframe_url: SafeResourceUrl;
    iframeHeight: string;
    private subscriptions$: Subscription = new Subscription();

    @ViewChild('iframe') iframe: ElementRef;

    ngAfterViewInit() {
        window.addEventListener('message', (event) => {
            if (event.data.frameResized) {
                this.iframeHeight = event.data.frameResized + 'px';
            }
        });
    }

    ngOnInit() {
        this.loadingService.startLoading('h5pPlayer');
        this.subscriptions$.add(
            this.libraryService
                .getContent(+this.route.snapshot.paramMap.get('activityId'))
                .subscribe((data: Content) => {
                    this.content = data;
                    if (this.canEdit()) {
                        this.editing = true;
                    }
                    this.setFrameSrc();
                    this.loadingService.stopLoading('h5pPlayer');
                })
        );
    }

    canEdit() {
        return this.content.status === 'draft';
    }

    setFrameSrc() {
        if (this.editing) {
            this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.edit_path);
        } else {
            this.iframe_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.path);
        }
    }

    toggleEditionMode() {
        this.editing = !this.editing;
        this.setFrameSrc();
    }

    getIcon(): string {
        if (this.content) {
            return this.libraryService.getDraftIcon(this.content);
        }
    }

    handleLoadFrame() {
        document.querySelector('.main-container').scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    getDescription() {
        return this.content.description;
    }

    isLoading() {
        return this.loadingService.isLoading('h5pPlayer');
    }
}
