// Internal dependencies
import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { UserService } from '@/services/user.service';

// Interfaces
import { Content } from '@/structures/content';
import { User } from '@/structures/user';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-select-validator',
    templateUrl: './select-validator.component.html',
    styleUrls: ['./select-validator.component.scss'],
    imports: [SharedModule, MatDialogModule]
})
export class SelectValidatorComponent implements OnInit {
    openContentHistory: Subject<Content> = new Subject();

    subscriptions = new Subscription();

    editionPossible: boolean;
    search: string;
    comment: string;
    urgent: boolean;
    validators: Array<User>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public content: Content,
        public dialogRef: MatDialogRef<SelectValidatorComponent>,
        private libraryService: LibraryService,
        private loadingService: LoadingService,
        private loginService: LoginService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.urgent = false;
        this.search = '';
        const params = {
            role: 'validator',
            offset: 0
        };
        this.editionPossible = true;
        this.loadingService.startLoading('selectValidator', 'getUsers');
        this.subscriptions.add(
            this.userService.getUsersLight(params).subscribe(
                (data: Array<User>) => {
                    params.role = 'localAdmin';
                    this.userService.getUsersLight(params).subscribe((data2: Array<User>) => {
                        data = data
                            .concat(data2)
                            .filter((user) => user.id !== this.loginService.getUser().id);
                        if (this.isDraft()) {
                            this.validators = data
                                .map((validator: User) => {
                                    return {
                                        ...validator,
                                        selected: false
                                    };
                                })
                                .sort((a, b) => {
                                    if (a.additionalRoles.validator && b.roles.localAdmin) {
                                        return -1;
                                    } else if (a.roles.localAdmin && b.additionalRoles.validator) {
                                        return 1;
                                    } else {
                                        return 0;
                                    }
                                });
                        } else {
                            this.loadingService.startLoading('selectValidator', 'getContentInfo');
                            this.subscriptions.add(
                                this.libraryService.getContentInfo(this.content).subscribe(
                                    (validationInfo: Array<any>) => {
                                        this.validators = data
                                            .map((validator: User) => {
                                                const selected =
                                                    validationInfo.filter((validatorInfo: any) => {
                                                        if (
                                                            validator.id === validatorInfo.id &&
                                                            !!validatorInfo.action
                                                        ) {
                                                            this.editionPossible = false;
                                                        }
                                                        return validator.id === validatorInfo.id;
                                                    }).length > 0;

                                                return {
                                                    ...validator,
                                                    selected: selected
                                                };
                                            })
                                            .sort((a, b) => {
                                                if (
                                                    a.additionalRoles.validator &&
                                                    b.roles.localAdmin
                                                ) {
                                                    return -1;
                                                } else if (
                                                    a.roles.localAdmin &&
                                                    b.additionalRoles.validator
                                                ) {
                                                    return 1;
                                                } else {
                                                    return 0;
                                                }
                                            });
                                        this.loadingService.stopLoading(
                                            'selectValidator',
                                            'getContentInfo'
                                        );
                                    },
                                    (error: HttpErrorResponse) => {
                                        this.loadingService.stopLoading(
                                            'selectValidator',
                                            'getUsers'
                                        );
                                    }
                                )
                            );
                        }
                        this.loadingService.stopLoading('selectValidator', 'getUsers');
                    });
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('selectValidator', 'getUsers');
                }
            )
        );
    }

    isDraft() {
        return this.content.status === 'draft';
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    getSelectedValidatorCount() {
        if (this.validators) {
            return this.validators.filter((validator: User) => {
                return validator.selected;
            }).length;
        }
    }

    cancelSearch() {
        this.search = '';
    }

    getValidatorName(validator: User) {
        return `${validator.lastname.toUpperCase()} ${validator.firstname}`;
    }

    isValidatorSelected(validator: User) {
        return validator.selected;
    }

    toggleValidator(validator: User) {
        if (this.canEditValidator()) {
            validator.selected = !validator.selected;
        }
    }

    isUrgent() {
        return this.urgent;
    }

    toggleUrgent() {
        this.urgent = !this.urgent;
    }

    getUrgentLabel() {
        if (this.urgent) {
            return 'Cette demande est urgente';
        } else {
            return 'Signaler cette demande comme urgente';
        }
    }

    canEditValidator() {
        return this.editionPossible;
    }

    viewHistory() {
        this.openContentHistory.next(this.content);
    }

    canSendToValidation() {
        if (this.isDraft()) {
            return this.getSelectedValidatorCount() > 0 && this.comment && this.comment.length > 0;
        } else {
            return this.getSelectedValidatorCount() > 0 && this.editionPossible;
        }
    }

    sendToValidation() {
        if (!this.canSendToValidation()) {
            return;
        }
        let data: any;
        if (this.isDraft()) {
            data = {
                urgent: this.urgent,
                comment: this.comment,
                validators: this.validators
                    .filter((validator) => validator.selected)
                    .map((validator) => validator.id)
            };
        } else {
            data = this.validators
                .filter((validator) => validator.selected)
                .map((validator) => validator.id);
        }
        this.dialogRef.close(data);
    }

    isLoading() {
        return this.loadingService.isLoading('selectValidator');
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
