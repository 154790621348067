import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Subscription, Subject } from 'rxjs';

import { LibraryService } from '@/services/library.service';
import { UserService } from '@/services/user.service';
import { GroupService } from '@/services/group.service';
import { LoginService } from '@/services/login.service';
import { LoadingService } from '@/services/loading.service';
import { AssignmentService } from '@/services/assignment.service';
import { FlashMessageService } from '@/services/flash-message.service';

import { Structure } from '@/structures/structure';
import { User } from '@/structures/user';
import { Group } from '@/structures/group';
import { slideToggle } from 'src/app/animations/slide-toggle.animation';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-advanced-assignation',
    templateUrl: './advanced-assignation.component.html',
    styleUrls: ['./advanced-assignation.component.scss'],
    animations: [slideToggle],
    standalone: false
})
export class AdvancedAssignationComponent implements OnInit {
    emitOpenSelectFIFCDialog: Subject<any> = new Subject();
    getOpenSelectFIFCDialog: Subject<any> = new Subject();

    emitOpenDevoirConfirmationDialog: Subject<any> = new Subject();
    getOpenDevoirConfirmationDialog: Subject<any> = new Subject();

    structures: Array<any> = [];
    previousStructures: Array<any> = []; // used to perform doCheck changes detection

    subscriptions = new Subscription();

    getStudents$: Subscription;
    getStudentsCount$: Subscription;
    students: Array<User>;
    selectedStudents: Array<User>;
    studentsCount: number;
    currentPageStudent: number;

    getGroupsStudent$: Subscription;
    getGroupsStudentCount$: Subscription;
    getGroupStudent$: Subscription;
    groupsStudent: Array<Group>;
    selectedGroupsStudent: Array<Group>;
    groupsStudentCount: number;
    currentPageGroupsStudent: number;

    getTeachers$: Subscription;
    getTeachersCount$: Subscription;
    teachers: Array<User>;
    selectedTeachers: Array<User>;
    teachersCount: number;
    currentPageTeacher: number;

    getGroupsTeacher$: Subscription;
    getGroupsTeacherCount$: Subscription;
    getGroupTeacher$: Subscription;
    groupsTeacher: Array<Group>;
    selectedGroupsTeacher: Array<Group>;
    groupsTeacherCount: number;
    currentPageGroupsTeacher: number;

    getGroupsRole$: Subscription;
    getGroupsRoleCount$: Subscription;
    groupsRole: Array<Group>;
    selectedGroupsRole: Array<Group>;
    groupsRoleCount: number;
    currentPageGroupsRole: number;

    searchTerm: string;
    view: string;
    assignationFIStatus: boolean;

    LIMIT = 30;

    constructor(
        public dialogRef: MatDialogRef<AdvancedAssignationComponent>,
        private libraryService: LibraryService,
        private userService: UserService,
        private groupService: GroupService,
        private loginService: LoginService,
        private loadingService: LoadingService,
        private assignmentService: AssignmentService,
        private flashMessageService: FlashMessageService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.structures = [];

        this.students = [];
        this.selectedStudents = [];
        this.currentPageStudent = -1;
        this.studentsCount = 0;

        this.groupsStudent = [];
        this.selectedGroupsStudent = [];
        this.currentPageGroupsStudent = -1;
        this.groupsStudentCount = 0;

        this.teachers = [];
        this.selectedTeachers = [];
        this.currentPageTeacher = -1;
        this.teachersCount = 0;

        this.groupsTeacher = [];
        this.selectedGroupsTeacher = [];
        this.currentPageGroupsTeacher = -1;
        this.groupsTeacherCount = 0;

        this.groupsRole = [];
        this.selectedGroupsRole = [];
        this.currentPageGroupsRole = -1;
        this.groupsRoleCount = 0;

        this.view = 'student';
        this.assignationFIStatus = undefined;
        if (!Array.isArray(this.data)) {
            this.data = [this.data];
        }
        this.subscriptions.add(
            this.loginService.getStructures().subscribe((data: Array<Structure>) => {
                this.structures = data.map((structure: Structure) => {
                    return {
                        title: structure.name,
                        key: structure.id,
                        selected:
                            parseInt(this.loginService.getUser().structureid, 10) ===
                                structure.id ||
                            this.loginService.getLastStructure().id === structure.id
                    };
                });
                this.refreshSearch();
            })
        );
    }

    updateStructures($event) {
        this.structures = $event;
        this.refreshSearch();
    }

    showStructuresDropdown() {
        return this.loginService.getUser().roles.nationalAdmin;
    }

    nextPage() {
        switch (this.view) {
            case 'student': {
                this.refreshStudents();
                break;
            }
            case 'groupStudent': {
                this.refreshGroupsStudent();
                break;
            }
            case 'teacher': {
                this.refreshTeachers();
                break;
            }
            case 'groupTeacher': {
                this.refreshGroupsTeacher();
                break;
            }
            case 'role-group': {
                this.refreshGroupsRole();
                break;
            }
        }
    }

    refreshSearch() {
        this.currentPageStudent = -1;
        this.currentPageGroupsStudent = -1;
        this.currentPageTeacher = -1;
        this.currentPageGroupsTeacher = -1;
        this.currentPageGroupsRole = -1;

        this.students = [];
        this.groupsStudent = [];
        this.teachers = [];
        this.groupsTeacher = [];
        this.groupsRole = [];

        this.studentsCount = 0;
        this.groupsStudentCount = 0;
        this.teachersCount = 0;
        this.groupsTeacherCount = 0;
        this.groupsRoleCount = 0;

        this.refreshStudents();
        this.refreshGroupsStudent();
        this.refreshTeachers();
        this.refreshGroupsTeacher();
        this.refreshGroupsRole();
    }

    refreshStudents() {
        if (!this.structures.some((e) => e.selected)) {
            return;
        }
        this.currentPageStudent++;
        this.loadingService.startLoading('advanced-assignation', 'getStudent');
        const params = {
            offset: this.currentPageStudent * this.LIMIT,
            limit: this.LIMIT,
            role: 'learner|prospect',
            enabled: true,
            search: this.searchTerm ? this.searchTerm : '',
            structureid: this.structures.find((structure) => structure.selected).key
        };
        if (this.getStudents$) {
            this.getStudents$.unsubscribe();
        }
        this.getStudents$ = this.userService.getUsers(params).subscribe((data: Array<User>) => {
            for (const i in data) {
                if (data[i]) {
                    let canPush = true;
                    for (const j in this.selectedStudents) {
                        if (data[i].id === this.selectedStudents[j].id) {
                            canPush = false;
                        }
                    }
                    if (canPush) {
                        this.students.push(data[i]);
                    }
                }
            }
            this.loadingService.stopLoading('advanced-assignation', 'getStudent');
        });
        if (this.studentsCount === 0) {
            if (this.getStudentsCount$) {
                this.getStudentsCount$.unsubscribe();
            }
            this.getStudentsCount$ = this.userService
                .getUsersCount(params)
                .subscribe((data: any) => {
                    this.studentsCount = data.count;
                });
        }
    }

    refreshGroupsStudent() {
        if (!this.structures.some((e) => e.selected)) {
            return;
        }
        this.currentPageGroupsStudent++;
        this.loadingService.startLoading('advanced-assignation', 'getGroupsStudent');
        const params = {
            offset: this.currentPageGroupsStudent * this.LIMIT,
            limit: this.LIMIT,
            search: this.searchTerm ? this.searchTerm : '',
            structureid: this.structures.find((structure) => structure.selected).key,
            type: 'learner'
        };
        if (this.getGroupsStudent$) {
            this.getGroupsStudent$.unsubscribe();
        }
        this.getGroupsStudent$ = this.groupService
            .getGroups(params)
            .subscribe((data: Array<Group>) => {
                for (const i in data) {
                    if (data[i]) {
                        let canPush = true;
                        for (const j in this.selectedGroupsStudent) {
                            if (data[i].id === this.selectedGroupsStudent[j].id) {
                                canPush = false;
                            }
                        }
                        if (canPush) {
                            this.groupsStudent.push(data[i]);
                        }
                    }
                }
                this.loadingService.stopLoading('advanced-assignation', 'getGroupsStudent');
            });

        if (this.groupsStudentCount === 0) {
            if (this.getGroupsStudentCount$) {
                this.getGroupsStudentCount$.unsubscribe();
            }
            this.getGroupsStudentCount$ = this.groupService
                .getGroupsCount(params)
                .subscribe((data: any) => {
                    this.groupsStudentCount = data.count;
                });
        }
    }

    refreshTeachers() {
        if (!this.structures.some((e) => e.selected)) {
            return;
        }
        this.currentPageTeacher++;
        this.loadingService.startLoading('advanced-assignation', 'getTeacher');
        const params = {
            offset: this.currentPageTeacher * this.LIMIT,
            limit: this.LIMIT,
            role: 'internalTeacher|externalTeacher|corporationTeacher|siteTeacher|localAdmin|tutor',
            enabled: true,
            search: this.searchTerm ? this.searchTerm : '',
            structureid: this.structures.find((structure) => structure.selected).key
        };
        if (this.getTeachers$) {
            this.getTeachers$.unsubscribe();
        }
        this.getTeachers$ = this.userService.getUsers(params).subscribe((data: Array<User>) => {
            for (const i in data) {
                if (data[i]) {
                    let canPush = true;
                    for (const j in this.selectedTeachers) {
                        if (data[i].id === this.selectedTeachers[j].id) {
                            canPush = false;
                        }
                    }
                    if (canPush) {
                        this.teachers.push(data[i]);
                    }
                }
            }
            this.loadingService.stopLoading('advanced-assignation', 'getTeacher');
        });
        if (this.teachersCount === 0) {
            if (this.getTeachersCount$) {
                this.getTeachersCount$.unsubscribe();
            }
            this.getTeachersCount$ = this.userService
                .getUsersCount(params)
                .subscribe((data: any) => {
                    this.teachersCount = data.count;
                });
        }
    }

    refreshGroupsTeacher() {
        if (!this.structures.some((e) => e.selected)) {
            return;
        }
        this.currentPageGroupsTeacher++;
        this.loadingService.startLoading('advanced-assignation', 'getGroupsTeacher');
        const params = {
            offset: this.currentPageGroupsTeacher * this.LIMIT,
            limit: this.LIMIT,
            search: this.searchTerm ? this.searchTerm : '',
            structureid: this.structures.find((structure) => structure.selected).key,
            type: 'teacher'
        };
        if (this.getGroupsTeacher$) {
            this.getGroupsTeacher$.unsubscribe();
        }
        this.getGroupsTeacher$ = this.groupService
            .getGroups(params)
            .subscribe((data: Array<Group>) => {
                for (const i in data) {
                    if (data[i]) {
                        let canPush = true;
                        for (const j in this.selectedGroupsTeacher) {
                            if (data[i].id === this.selectedGroupsTeacher[j].id) {
                                canPush = false;
                            }
                        }
                        if (canPush) {
                            this.groupsTeacher.push(data[i]);
                        }
                    }
                }
                this.loadingService.stopLoading('advanced-assignation', 'getGroupsTeacher');
            });

        if (this.groupsTeacherCount === 0) {
            if (this.getGroupsTeacherCount$) {
                this.getGroupsTeacherCount$.unsubscribe();
            }
            this.getGroupsTeacherCount$ = this.groupService
                .getGroupsCount(params)
                .subscribe((data: any) => {
                    this.groupsTeacherCount = data.count;
                });
        }
    }

    refreshGroupsRole() {
        if (!this.structures.some((e) => e.selected)) {
            return;
        }
        this.currentPageGroupsRole++;
        this.loadingService.startLoading('advanced-assignation', 'getGroupsRole');
        const params = {
            offset: this.currentPageGroupsRole * this.LIMIT,
            limit: this.LIMIT,
            search: this.searchTerm ? this.searchTerm : '',
            structureid: this.structures.find((structure) => structure.selected).key,
            type: 'role'
        };
        if (this.getGroupsRole$) {
            this.getGroupsRole$.unsubscribe();
        }
        this.getGroupsRole$ = this.groupService
            .getGroups(params)
            .subscribe((data: Array<Group>) => {
                for (const i in data) {
                    if (data[i]) {
                        let canPush = true;
                        for (const j in this.selectedGroupsRole) {
                            if (data[i].id === this.selectedGroupsRole[j].id) {
                                canPush = false;
                            }
                        }
                        if (canPush) {
                            this.groupsRole.push(data[i]);
                        }
                    }
                }
                this.loadingService.stopLoading('advanced-assignation', 'getGroupsRole');
            });

        if (this.groupsRoleCount === 0) {
            if (this.getGroupsRoleCount$) {
                this.getGroupsRoleCount$.unsubscribe();
            }
            this.getGroupsRoleCount$ = this.groupService
                .getGroupsCount(params)
                .subscribe((data: any) => {
                    this.groupsRoleCount = data.count;
                });
        }
    }

    cancelSearch() {
        this.searchTerm = '';
        this.refreshSearch();
    }

    getIcon(content) {
        return this.libraryService.getIcon(content);
    }

    getUserIcon(user) {
        if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    getTitle(content) {
        return content.title;
    }

    isView(view: string) {
        return this.view === view;
    }

    toggleView(view: string) {
        this.view = view;
    }

    selectStudent(student: User) {
        if (student.selected) {
            student.selected = false;
            for (const i in this.selectedStudents) {
                if (this.selectedStudents[i].id === student.id) {
                    this.selectedStudents.splice(+i, 1);
                }
            }
        } else {
            this.selectedStudents.push(student);
            student.selected = true;
        }
    }

    isSelectedStudent(student: User) {
        for (const i in this.students) {
            if (this.students[i].id === student.id) {
                return student.selected;
            }
        }
    }

    getSelectedStudentsCount() {
        return this.selectedStudents.length;
    }

    getStudentsCount() {
        return this.studentsCount;
    }

    selectGroupStudent(group: Group) {
        if (group.selected) {
            group.selected = false;
            for (const i in this.selectedGroupsStudent) {
                if (this.selectedGroupsStudent[i].id === group.id) {
                    this.selectedGroupsStudent.splice(+i, 1);
                }
            }
        } else {
            this.selectedGroupsStudent.push(group);
            group.selected = true;
        }
    }

    isSelectedGroupStudent(group: User) {
        for (const i in this.groupsStudent) {
            if (this.groupsStudent[i].id === group.id) {
                return group.selected;
            }
        }
    }

    getSelectedGroupsStudentCount() {
        return this.selectedGroupsStudent.length;
    }

    getGroupsStudentCount() {
        return this.groupsStudentCount;
    }

    toggleGroupStudent($event: any, group: Group) {
        if (!group.users) {
            if (this.getGroupStudent$) {
                this.getGroupStudent$.unsubscribe();
            }
            this.getGroupStudent$ = this.groupService
                .getGroup(group.id)
                .subscribe((data: Group) => {
                    group.users = data.users;
                    group.opened = !group.opened;
                });
            this.subscriptions.add(this.getGroupStudent$);
        } else {
            group.opened = !group.opened;
        }
    }

    selectTeacher(teacher: User) {
        if (teacher.selected) {
            teacher.selected = false;
            for (const i in this.selectedTeachers) {
                if (this.selectedTeachers[i].id === teacher.id) {
                    this.selectedTeachers.splice(+i, 1);
                }
            }
        } else {
            this.selectedTeachers.push(teacher);
            teacher.selected = true;
        }
    }

    isSelectedTeacher(teacher: User) {
        for (const i in this.teachers) {
            if (this.teachers[i].id === teacher.id) {
                return teacher.selected;
            }
        }
    }

    getSelectedTeachersCount() {
        return this.selectedTeachers.length;
    }

    getTeachersCount() {
        return this.teachersCount;
    }

    selectGroupTeacher(group: Group) {
        if (group.selected) {
            group.selected = false;
            for (const i in this.selectedGroupsTeacher) {
                if (this.selectedGroupsTeacher[i].id === group.id) {
                    this.selectedGroupsTeacher.splice(+i, 1);
                }
            }
        } else {
            this.selectedGroupsTeacher.push(group);
            group.selected = true;
        }
    }

    isSelectedGroupTeacher(group: User) {
        for (const i in this.groupsTeacher) {
            if (this.groupsTeacher[i].id === group.id) {
                return group.selected;
            }
        }
    }

    getSelectedGroupsTeacherCount() {
        return this.selectedGroupsTeacher.length;
    }

    getGroupsTeacherCount() {
        return this.groupsTeacherCount;
    }

    toggleGroupTeacher($event: any, group: Group) {
        if (!group.users) {
            if (this.getGroupTeacher$) {
                this.getGroupTeacher$.unsubscribe();
            }
            this.getGroupTeacher$ = this.groupService
                .getGroup(group.id)
                .subscribe((data: Group) => {
                    group.users = data.users;
                    group.opened = !group.opened;
                });
            this.subscriptions.add(this.getGroupTeacher$);
        } else {
            group.opened = !group.opened;
        }
    }

    selectGroupRole(group: Group) {
        if (group.selected) {
            group.selected = false;
            for (const i in this.selectedGroupsRole) {
                if (this.selectedGroupsRole[i].id === group.id) {
                    this.selectedGroupsRole.splice(+i, 1);
                }
            }
        } else {
            this.selectedGroupsRole.push(group);
            group.selected = true;
        }
    }

    isSelectedGroupRole(group: User) {
        for (const i in this.groupsRole) {
            if (this.groupsRole[i].id === group.id) {
                return group.selected;
            }
        }
    }

    getSelectedGroupsRoleCount() {
        return this.selectedGroupsRole.length;
    }

    getGroupsRoleCount() {
        return this.groupsRoleCount;
    }

    canAssignContent() {
        if (this.selectedStudents.length > 0) {
            return true;
        }
        if (this.selectedGroupsStudent.length > 0) {
            return true;
        }
        if (this.selectedTeachers.length > 0) {
            return true;
        }
        if (this.selectedGroupsTeacher.length > 0) {
            return true;
        }
        if (this.selectedGroupsRole.length > 0) {
            return true;
        }
        return false;
    }

    startAssign() {
        let showSelectFIFCDialog = false;
        for (const content of this.data) {
            if (content.hasprice) {
                showSelectFIFCDialog = true;
            }
        }

        if (showSelectFIFCDialog) {
            this.emitOpenSelectFIFCDialog.next();
            this.subscriptions.add(
                this.getOpenSelectFIFCDialog.subscribe((data) => {
                    this.assignationFIStatus = data;
                    this.assign();
                })
            );
        } else {
            this.assign();
        }
    }

    assign() {
        if (this.canAssignContent()) {
            const observableArray = [];
            for (const content of this.data) {
                this.selectedStudents.map((student: User) => {
                    observableArray.push(
                        this.assignmentService.assignContentToUser(
                            content,
                            student,
                            undefined,
                            undefined,
                            this.assignationFIStatus
                        )
                    );
                });
                this.selectedTeachers.map((teacher: User) => {
                    observableArray.push(
                        this.assignmentService.assignContentToUser(
                            content,
                            teacher,
                            undefined,
                            undefined,
                            this.assignationFIStatus
                        )
                    );
                });
                this.selectedGroupsStudent.map((group: Group) => {
                    for (const i in this.structures) {
                        if (this.structures[i].selected) {
                            group.structurename = this.structures[i].title;
                        }
                    }
                    observableArray.push(
                        this.assignmentService.assignContentToGroup(
                            content,
                            group,
                            undefined,
                            undefined,
                            this.assignationFIStatus
                        )
                    );
                });
                this.selectedGroupsTeacher.map((group: Group) => {
                    for (const i in this.structures) {
                        if (this.structures[i].selected) {
                            group.structurename = this.structures[i].title;
                        }
                    }
                    observableArray.push(
                        this.assignmentService.assignContentToGroup(
                            content,
                            group,
                            undefined,
                            undefined,
                            this.assignationFIStatus
                        )
                    );
                });
                this.selectedGroupsRole.map((group: Group) => {
                    for (const i in this.structures) {
                        if (this.structures[i].selected) {
                            group.structurename = this.structures[i].title;
                        }
                    }
                    observableArray.push(
                        this.assignmentService.assignContentToGroup(
                            content,
                            group,
                            undefined,
                            undefined,
                            this.assignationFIStatus
                        )
                    );
                });
            }
            this.subscriptions.add(
                forkJoin(observableArray).subscribe((data: any) => {
                    if (this.data.length === 1) {
                        this.flashMessageService.flash('Le contenu a bien été assigné');
                    } else {
                        this.flashMessageService.flash('Les contenus ont bien été assignés');
                    }
                    const groupDevoirList = [];
                    for (const i in data) {
                        if (
                            data[i].assignment.type === 'devoir' &&
                            data[i].assignment.enrolmentSource !== 'individual'
                        ) {
                            groupDevoirList.push(data[i].assignment);
                        }
                    }
                    if (groupDevoirList.length) {
                        this.emitOpenDevoirConfirmationDialog.next();
                        this.subscriptions.add(
                            this.getOpenDevoirConfirmationDialog.subscribe((confirmed: boolean) => {
                                if (confirmed) {
                                    const secondObservableArray = [];
                                    for (const i in groupDevoirList) {
                                        if (groupDevoirList[i]) {
                                            secondObservableArray.push(
                                                this.groupService.markAssignmentAsAnonymous(
                                                    groupDevoirList[i].assignmentId
                                                )
                                            );
                                        }
                                    }
                                    this.subscriptions.add(
                                        forkJoin(secondObservableArray).subscribe(() => {
                                            this.flashMessageService.flash(
                                                `L'assignation a été marqué comme anonyme`
                                            );
                                            this.closeDialog();
                                        })
                                    );
                                } else {
                                    this.closeDialog();
                                }
                            })
                        );
                    } else {
                        this.closeDialog();
                    }
                })
            );
        }
    }

    isLoading() {
        return this.loadingService.isLoading('advanced-assignation');
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
