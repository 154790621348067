import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';

import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';
import { FlashMessageService } from '@/services/flash-message.service';

import { Content } from '@/structures/content';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-report-activity-error',
    templateUrl: './report-activity-error.component.html',
    styleUrls: ['./report-activity-error.component.scss'],
    standalone: false
})
export class ReportActivityErrorComponent implements OnInit {
    subscriptions = new Subscription();

    @ViewChild('fileInput', { static: false }) fileInput;

    comment: string;
    file: File;
    reportSending: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public content: Content,
        public dialogRef: MatDialogRef<ReportActivityErrorComponent>,
        private libraryService: LibraryService,
        private flashMessageService: FlashMessageService,
        private loadingService: LoadingService
    ) {}

    ngOnInit() {
        this.reportSending = false;
        this.comment = '';
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    openSelectFile() {
        this.fileInput.nativeElement.click();
    }

    uploadFile(files: FileList) {
        this.file = files.item(0);
    }

    canReportError() {
        return this.comment.length > 0;
    }

    reportError() {
        if (!this.reportSending) {
            this.loadingService.startLoading('delegateValidator');
            this.reportSending = true;
            this.subscriptions.add(
                this.libraryService
                    .reportActivityError(this.content.id, this.comment, this.file)
                    .subscribe(() => {
                        this.loadingService.startLoading('delegateValidator');
                        this.dialogRef.close({ activityReported: true });
                    })
            );
        }
    }

    isReportSending() {
        return this.reportSending;
    }

    isLoading() {
        return this.loadingService.isLoading('delegateValidator');
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
