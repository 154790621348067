import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { SharedModule } from './shared.module';

import { AdministrationComponent } from '../components/administration/administration.component';
import { DomainsComponent } from '../components/administration/domains/domains.component';
import { ProvidersComponent } from '../components/administration/providers/providers.component';

import { CreateDomainComponent } from '../dialogs/create-domain/create-domain.component';
import { CreateProviderComponent } from '../dialogs/create-provider/create-provider.component';

@NgModule({
    declarations: [
        AdministrationComponent,
        DomainsComponent,
        ProvidersComponent,
        CreateDomainComponent,
        CreateProviderComponent
    ],
    imports: [CommonModule, FormsModule, MatDialogModule, SharedModule],
    exports: [
        AdministrationComponent,
        DomainsComponent,
        ProvidersComponent,
        CreateDomainComponent,
        CreateProviderComponent
    ]
})
export class AdministrationModule {}
