<div class="left-content">
    @if (isSelectedTab('assemblies')) {
        <div class="legend">Sélectionner votre <b>niveau d'assemblage</b></div>
        <div class="tree">
            @for (content of contentsList().assemblies; track content.key) {
                <div
                    class="item"
                    [ngClass]="{ active: isSelectedKey(content.key) }"
                    (click)="handleClickSelectKey(content.key)"
                >
                    <div [ngClass]="content.icon"></div>
                    <div class="label">{{ content.title }}</div>
                </div>
            }
        </div>
    }
    @if (isSelectedTab('creations')) {
        <div>Sélectionnez un type de <b>création digitale</b></div>
        <div class="label">À l'affiche</div>
        <div class="contents">
            @for (content of contentsList().creations.spotlight; track content.key) {
                <div
                    class="content spotlight"
                    [ngClass]="{ active: isSelectedKey(content.key) }"
                    (click)="handleClickSelectKey(content.key)"
                >
                    <img [src]="getContentIcon(content)" />
                    <div>{{ content.title }}</div>
                </div>
            }
        </div>
        <div class="label">Activité évaluatives</div>
        <div class="contents">
            @for (content of contentsList().creations.evaluations; track content.key) {
                <div
                    class="content"
                    [ngClass]="{ active: isSelectedKey(content.key) }"
                    (click)="handleClickSelectKey(content.key)"
                >
                    <img [src]="getContentIcon(content)" />
                    <div>{{ content.title }}</div>
                </div>
            }
        </div>
        <div class="label">Activité formatives</div>
        <div class="contents">
            @for (content of contentsList().creations.formations; track content.key) {
                <div
                    class="content"
                    [ngClass]="{ active: isSelectedKey(content.key) }"
                    (click)="handleClickSelectKey(content.key)"
                >
                    <img [src]="getContentIcon(content)" />
                    <div>{{ content.title }}</div>
                </div>
            }
        </div>
        <div class="label">Section Administrateurs</div>
        <div class="contents">
            @for (content of contentsList().creations.admin; track content.key) {
                <div
                    class="content"
                    [ngClass]="{ active: isSelectedKey(content.key) }"
                    (click)="handleClickSelectKey(content.key)"
                >
                    <img [src]="getContentIcon(content)" />
                    <div>{{ content.title }}</div>
                </div>
            }
        </div>
        <div class="label">Section Administrateurs nationaux</div>
        <div class="contents">
            @for (content of contentsList().creations.national_admin; track content.key) {
                <div
                    class="content"
                    [ngClass]="{ active: isSelectedKey(content.key) }"
                    (click)="handleClickSelectKey(content.key)"
                >
                    <img [src]="getContentIcon(content)" />
                    <div>{{ content.title }}</div>
                </div>
            }
        </div>
    }
    @if (isSelectedTab('imports')) {
        <div>Sélectionnez ce que vous souhaitez <b>importer</b></div>
        <div class="label">À l'affiche</div>
        <div class="contents">
            @for (content of contentsList().imports.spotlight; track content.key) {
                <div
                    class="content spotlight"
                    [ngClass]="{ active: isSelectedKey(content.key) }"
                    (click)="handleClickSelectKey(content.key)"
                >
                    <img [src]="getContentIcon(content)" />
                    <div>{{ content.title }}</div>
                </div>
            }
        </div>
    }
</div>
