import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
    standalone: false
})
export class AdministrationComponent implements OnInit {
    adminTabs: any;
    activeTab: string;

    ngOnInit() {
        this.adminTabs = [
            {
                label: 'Domaines',
                key: 'domains'
            },
            {
                label: 'Fournisseurs',
                key: 'providers'
            }
        ];

        this.activeTab = this.adminTabs[0].key;
    }

    goToTab(tab: any): void {
        this.activeTab = tab.key;
    }

    isActiveTab(tab: string): boolean {
        return tab === this.activeTab;
    }
}
