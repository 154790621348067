import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DndModule } from 'ngx-drag-drop';

import { SharedModule } from './shared.module';

import { GroupAssignmentsComponent } from '../components/teacher/student-panel/group-assignments/group-assignments.component';
import { UserAssignmentsComponent } from '../components/teacher/student-panel/user-assignments/user-assignments.component';
import { GroupAssignmentsEntryComponent } from '../components/teacher/student-panel/group-assignments/group-assignments-entry/group-assignments-entry.component';
import { UserAssignmentsEntryComponent } from '../components/teacher/student-panel/user-assignments/user-assignments-entry/user-assignments-entry.component';

import { AdvancedAssignationComponent } from '../dialogs/advanced-assignation/advanced-assignation.component';
import { SelectFIFCComponent } from '../dialogs/select-fi-fc/select-fi-fc.component';
import { AddDisponibilityComponent } from '../dialogs/add-disponibility/add-disponibility.component';
import { PersonnalizeGroupAssignmentComponent } from '../dialogs/personnalize-group-assignment/personnalize-group-assignment.component';
import { MinorUpdateChangeComponent } from '../dialogs/minor-update-change/minor-update-change.component';
import { DevoirConfirmationComponent } from '../dialogs/devoir-confirmation/devoir-confirmation.component';
import { UserTrackingComponent } from '../dialogs/user-tracking/user-tracking.component';
import { GroupTrackingComponent } from '../dialogs/group-tracking/group-tracking.component';
import { ViewChartComponent } from '../dialogs/view-chart/view-chart.component';

import { UploadDevoirCorrectionComponent } from '../dialogs/upload-devoir-correction/upload-devoir-correction.component';
import { RevealAnonymousDevoirConfirmationComponent } from '../dialogs/reveal-anonymous-devoir-confirmation/reveal-anonymous-devoir-confirmation.component';
import { SelectGroupReporting } from '../dialogs/select-group-reporting/select-group-reporting.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EndpointInterceptor } from '../interceptors/endpoint.interceptor';
import { CredentialsInterceptor } from '../interceptors/credentials.interceptor';
import { LogInterceptor } from '../interceptors/log.interceptor';
import { ErrorInterceptor } from '../interceptors/error.interceptor';

@NgModule({
    declarations: [
        UserAssignmentsComponent,
        GroupAssignmentsComponent,
        GroupAssignmentsEntryComponent,
        UserAssignmentsEntryComponent,
        AdvancedAssignationComponent,
        SelectFIFCComponent,
        AddDisponibilityComponent,
        PersonnalizeGroupAssignmentComponent,
        MinorUpdateChangeComponent,
        UserTrackingComponent,
        GroupTrackingComponent,
        ViewChartComponent,
        UploadDevoirCorrectionComponent,
        RevealAnonymousDevoirConfirmationComponent,
        DevoirConfirmationComponent,
        SelectGroupReporting
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatExpansionModule,
        MatDialogModule,
        MatDatepickerModule,
        InfiniteScrollModule,
        DndModule,
        SharedModule,
        BaseChartDirective
    ],
    exports: [
        UserAssignmentsComponent,
        GroupAssignmentsComponent,
        GroupAssignmentsEntryComponent,
        UserAssignmentsEntryComponent,
        AdvancedAssignationComponent,
        SelectFIFCComponent,
        AddDisponibilityComponent,
        PersonnalizeGroupAssignmentComponent,
        MinorUpdateChangeComponent,
        UserTrackingComponent,
        UploadDevoirCorrectionComponent,
        RevealAnonymousDevoirConfirmationComponent,
        DevoirConfirmationComponent,
        SelectGroupReporting
    ],
    providers: [provideCharts(withDefaultRegisterables())]
})
export class AssignmentModule {}
