<div class="validation-space-entry-container">
    <div class="icon" [ngClass]="getIcon()"></div>
    <div class="title" [tooltip]="getDraftTooltip()" tooltipPosition="below-right">
        <div class="name">{{ draft().title }}</div>
    </div>
    <div class="icons">
        <div class="hover-icons">
            @if (displayIcon('fiche')) {
                <div
                    class="icon-Fiche"
                    (click)="handleClickViewFiche($event)"
                    [tooltip]="'Voir la fiche'"
                ></div>
            }
            @if (displayIcon('guide')) {
                <div
                    class="icon-guide"
                    (click)="handleClickViewGuide($event)"
                    [tooltip]="'Voir le guide'"
                ></div>
            }
            @if (displayIcon('edit-validators')) {
                <div
                    class="icon-valideur"
                    (click)="editValidators($event)"
                    [tooltip]="'Modifier les valideurs'"
                ></div>
            }
            @if (displayIcon('cancel-validation')) {
                <div
                    class="icon-rejeter"
                    (click)="cancelValidation($event)"
                    [tooltip]="'Annuler la demande de validation'"
                ></div>
            }
            @if (displayIcon('send-reminder')) {
                <div
                    class="icon-message"
                    (click)="sendReminder($event)"
                    [tooltip]="'Envoyer un rappel aux valideurs'"
                ></div>
            }
            @if (displayIcon('view-history')) {
                <div
                    class="icon-historique"
                    (click)="openHistoryDialog($event)"
                    [tooltip]="'Voir l\'historique'"
                ></div>
            }
            @if (displayIcon('open-activity')) {
                <div
                    class="icon-voir"
                    (click)="openActivity($event)"
                    [tooltip]="'Voir l\'activité'"
                ></div>
            }

            @if (displayIcon('approve-activity')) {
                <div
                    class="icon-valideur"
                    (click)="approveContent($event)"
                    [tooltip]="getValidateContentTooltip()"
                ></div>
            }
            @if (displayIcon('reject-activity')) {
                <div
                    class="icon-rejeter"
                    (click)="rejectContent($event)"
                    [tooltip]="getRejectContentTooltip()"
                ></div>
            }
            @if (displayIcon('add-validator')) {
                <div
                    class="icon-ajout-valideur"
                    (click)="addValidator($event)"
                    [tooltip]="'Ajouter un valideur'"
                ></div>
            }
            @if (displayIcon('delegate-validation')) {
                <div
                    class="icon-deleguer-validation"
                    (click)="delegateValidator($event)"
                    [tooltip]="'Déléguer ma validation'"
                ></div>
            }
        </div>
        <div class="default-icons">
            @if (displayIcon('waiting-for-approval')) {
                <div class="icon-Attente" [tooltip]="'En attente de validation'"></div>
            }
            @if (draft().urgent) {
                <div class="icon-Timer" [tooltip]="'Cette demande est urgente'"></div>
            }
        </div>
    </div>
</div>
