import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-select-group-reporting',
    templateUrl: './select-group-reporting.component.html',
    styleUrls: ['./select-group-reporting.component.scss'],
    standalone: false
})
export class SelectGroupReporting {
    @Output() openConfirmationDialog: EventEmitter<string> = new EventEmitter();

    constructor(public dialogRef: MatDialogRef<SelectGroupReporting>) {}

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog(value) {
        this.dialogRef.close(value);
    }
}
