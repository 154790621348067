import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DndModule } from 'ngx-drag-drop';

import { RoutingModule } from './routing.module';
import { LibraryModule } from './library.module';
import { SharedModule } from './shared.module';

import { TabsComponent } from '../components/teacher/tabs/tabs.component';
import { StudentPanelComponent } from '../components/teacher/student-panel/student-panel.component';
import { RoleGroupComponent } from '../components/teacher/student-panel/role-group/role-group.component';
import { GroupComponent } from '../components/teacher/student-panel/group/group.component';
import { UserComponent } from '../components/teacher/student-panel/user/user.component';

import { TeacherComponent } from '../components/teacher/teacher.component';
import { TutorComponent } from '../components/tutor/tutor.component';

import { ExchangeComponent } from '../dialogs/exchange/exchange.component';
import { MoreActionsModule } from '../components/teacher/library/library-entry/more-actions/more-actions.module';

@NgModule({
    declarations: [
        TeacherComponent,
        TabsComponent,
        StudentPanelComponent,
        RoleGroupComponent,
        GroupComponent,
        UserComponent,
        TutorComponent,
        ExchangeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatExpansionModule,
        MatDialogModule,
        MatDatepickerModule,
        InfiniteScrollModule,
        SharedModule,
        DndModule,
        RoutingModule,
        LibraryModule,
        MoreActionsModule
    ],
    exports: [
        TeacherComponent,
        TabsComponent,
        StudentPanelComponent,
        RoleGroupComponent,
        GroupComponent,
        UserComponent,
        TutorComponent,
        ExchangeComponent
    ]
})
export class PanelModule {}
