import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { LoadingModule } from '../modules/loading.module';

import { TooltipModule } from 'libs/uimm-tooltip/src/public-api';
import { DropdownModule } from 'libs/uimm-dropdown/src/public-api';

import { WarningComponent } from '../dialogs/warning/warning.component';
import { ErrorComponent } from '../dialogs/error/error.component';
import { ConfirmationComponent } from '../dialogs/confirmation/confirmation.component';

import { DatesPipe } from '../pipes/dates.pipe';
import { UserSearchPipe } from '../pipes/user-search.pipe';
import { InfoComponent } from '../dialogs/info/info.component';

@NgModule({
    declarations: [
        DatesPipe,
        UserSearchPipe,
        WarningComponent,
        ErrorComponent,
        ConfirmationComponent,
        InfoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        LoadingModule,
        TooltipModule,
        DropdownModule
    ],
    exports: [
        DatesPipe,
        UserSearchPipe,
        WarningComponent,
        ErrorComponent,
        CommonModule,
        FormsModule,
        ConfirmationComponent,
        LoadingModule,
        TooltipModule,
        DropdownModule
    ]
})
export class SharedModule {}
