import { Component, HostListener, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { LoginService } from './services/login.service';
import { ConfigService } from './services/config.service';
import { AssignmentService } from './services/assignment.service';
import { DialogService } from './services/dialog.service';

import { UntilDestroy } from '@ngneat/until-destroy';
import { isMobile, isIE } from 'mobile-device-detect';

/**
 * Composant principal de l'application
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
    subscriptions = new Subscription();

    mouseMoved: boolean;
    loading = true;

    assigning: boolean;

    message = {
        icons: {
            center: [
                {
                    key: 'teacher/panel',
                    icon: 'icon-tuteurentreprise',
                    label: 'Mes apprenants',
                    permission: ['tutor']
                },
                {
                    key: 'teacher/panel',
                    icon: 'icon-FormateurEntreprise',
                    label: 'Vue formateur',
                    permission: ['corporationTeacher']
                },
                {
                    key: 'teacher/panel',
                    icon: 'icon-formateurexterne',
                    label: 'Vue formateur',
                    permission: ['externalTeacher']
                },
                {
                    key: 'teacher/panel',
                    icon: 'icon-formateur-site',
                    label: 'Vue formateur',
                    permission: ['siteTeacher']
                },
                {
                    key: 'teacher/panel',
                    icon: 'icon-formateurinterne',
                    label: 'Vue formateur',
                    permission: ['nationalAdmin', 'localAdmin', 'internalTeacher']
                },
                {
                    key: 'reporting',
                    icon: 'icon-reporting',
                    label: 'Voir les suivis',
                    permission: ['nationalAdmin', 'localAdmin']
                },
                {
                    key: 'admin',
                    icon: 'icon-adminnational',
                    label: 'Administration',
                    permission: ['nationalAdmin']
                }
            ],
            right: [],
            help: 'enabled'
        }
    };

    constructor(
        private router: Router,
        private loginService: LoginService,
        private configService: ConfigService,
        private dialogService: DialogService,

        private assignmentService: AssignmentService
    ) {}

    @HostListener('window:message', ['$event']) onMessage(event: any) {
        if (event.data.logout) {
            window.location.href = event.data.logout;
        } else if (event.data.structureid) {
            this.loginService.setLastStructure(event.data);
        } else if (event.data === 'getURL') {
            this.subscriptions.add(
                this.loginService.getCurrentUserTraining().subscribe((data: any) => {
                    if (data === 0) {
                        window.location.href = this.configService.getRedirectEndPoint();
                    } else {
                        if (isMobile) {
                            if (this.loading) {
                                this.dialogService.openError(
                                    "Ce site n'est pas optimisé pour un usage mobile, préférez une navigation sur ordinateur"
                                );
                            }
                        }
                        this.loading = false;
                    }
                })
            );
        } else if (event.data === 'getIcons') {
            (<HTMLIFrameElement>(
                document.getElementById('header-container')
            )).contentWindow.postMessage(this.message, '*');
        } else {
            if (event.data === 'teacher/panel' || event.data === 'admin') {
                if (!this.router.url.includes(event.data)) {
                    this.router.navigate([event.data]);
                }
            }
            if (event.data === 'reporting') {
                window.open(this.configService.getReportOldFrontEndpoint(), '_blank');
            }
        }
    }

    @HostListener('window:mousemove', ['$event']) onMouseMoved(event: any) {
        this.mouseMoved = true;
    }

    ngOnInit() {
        this.mouseMoved = false;
        this.pingGetCurrent();
        this.loginService.initCheckConnectedInterval();

        this.subscriptions.add(
            this.assignmentService.assigning.subscribe((assigning: boolean) => {
                this.assigning = assigning;
            })
        );
        this.loginService.getCurrentUserTraining().subscribe((data: any) => {
            if (data === 0) {
                window.location.href = this.configService.getRedirectEndPoint();
            } else {
                if (isMobile) {
                    if (this.loading) {
                        this.dialogService.openError(
                            "Ce site n'est pas optimisé pour un usage mobile, préférez une navigation sur ordinateur"
                        );
                    }
                }
                this.loading = false;
            }
        });
    }

    isLoading() {
        return this.loading;
    }

    isAssigning() {
        return this.assigning;
    }

    /**
     * Effectue un appel à getCurrentUser toute les 20 minutes, si l'utilisateur a déplacé au moins une fois sa souris dans les dernières 20 minutes.
     * Cela permet de s'assurer que l'utilisateur ne sera pas déconnecté du serveur pour inactivité.
     */
    pingGetCurrent() {
        setInterval(() => {
            if (this.mouseMoved) {
                this.subscriptions.add(
                    this.loginService.getCurrentUserTraining().subscribe((data: any) => {
                        this.mouseMoved = false;
                    })
                );
            }
        }, 1000 * 20 * 60);
    }
}
