import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DndModule } from 'ngx-drag-drop';
import { ClickOutsideModule } from '@solidexpert/ng-click-outside';

import { SharedModule } from './shared.module';

import { LibraryComponent } from '../components/teacher/library/library.component';
import { LibraryHeaderComponent } from '../components/teacher/library/library-header/library-header.component';
import { LibraryEntryComponent } from '../components/teacher/library/library-entry/library-entry.component';

import { ActivityRatingComponent } from '../dialogs/activity-rating/activity-rating.component';
import { CopyContentComponent } from '../dialogs/copy-content/copy-content.component';
import { EditAuthorComponent } from '../dialogs/edit-author/edit-author.component';
import { ExportCatalogComponent } from '../dialogs/export-catalog/export-catalog.component';
import { ContentDetailsComponent } from '../dialogs/content-details/content-details.component';
import { LibraryConfirmationComponent } from '../dialogs/library-confirmation/library-confirmation.component';
import { ReportActivityErrorComponent } from '../dialogs/report-activity-error/report-activity-error.component';
import { ViewActivityErrorComponent } from '../dialogs/view-activity-error/view-activity-error.component';
import { MoreActionsModule } from '../components/teacher/library/library-entry/more-actions/more-actions.module';

@NgModule({
    declarations: [
        LibraryComponent,
        LibraryHeaderComponent,
        LibraryEntryComponent,
        CopyContentComponent,
        EditAuthorComponent,
        ExportCatalogComponent,
        ContentDetailsComponent,
        LibraryConfirmationComponent,
        ReportActivityErrorComponent,
        ViewActivityErrorComponent,
        ActivityRatingComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatDialogModule,
        InfiniteScrollModule,
        DndModule,
        ClickOutsideModule,
        SharedModule,
        MoreActionsModule
    ],
    exports: [
        LibraryComponent,
        LibraryHeaderComponent,
        LibraryEntryComponent,
        CopyContentComponent,
        ExportCatalogComponent,
        ContentDetailsComponent,
        EditAuthorComponent,
        LibraryConfirmationComponent,
        ReportActivityErrorComponent,
        ViewActivityErrorComponent,
        ActivityRatingComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LibraryModule {}
