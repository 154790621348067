// Internal dependencies
import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components

// Services
import { LibraryService } from '@/services/library.service';
import { LoadingService } from '@/services/loading.service';
import { LoginService } from '@/services/login.service';
import { UserService } from '@/services/user.service';

// Interfaces
import { Content } from '@/structures/content';
import { User } from '@/structures/user';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-delegate-validation',
    templateUrl: './delegate-validation.component.html',
    styleUrls: ['./delegate-validation.component.scss'],
    imports: [SharedModule, MatDialogModule]
})
export class DelegateValidationComponent implements OnInit {
    subscriptions = new Subscription();

    editionPossible: boolean;
    search: string;
    comment: string;
    urgent: boolean;
    validators: Array<User>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public content: Content,
        public dialogRef: MatDialogRef<DelegateValidationComponent>,
        private libraryService: LibraryService,
        private loadingService: LoadingService,
        private loginService: LoginService,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.urgent = false;
        this.search = '';
        const params = {
            role: 'validator',
            offset: 0
        };
        this.editionPossible = true;
        this.loadingService.startLoading('delegateValidator', 'getUsers');
        this.subscriptions.add(
            this.userService.getUsersLight(params).subscribe(
                (data: Array<User>) => {
                    params.role = 'localAdmin';
                    this.userService.getUsersLight(params).subscribe((data2: Array<User>) => {
                        data = data.concat(data2);
                        this.loadingService.startLoading('delegateValidator', 'getContentInfo');
                        this.subscriptions.add(
                            this.libraryService.getContentInfo(this.content).subscribe(
                                (validationInfo: Array<any>) => {
                                    this.validators = data
                                        .filter((validator: User) => {
                                            if (validator.id === this.loginService.getUser().id) {
                                                return false;
                                            }
                                            const selected =
                                                validationInfo.filter((validatorInfo: any) => {
                                                    return validator.id === validatorInfo.id;
                                                }).length > 0;
                                            return !selected;
                                        })
                                        .sort((a, b) => {
                                            if (a.additionalRoles.validator && b.roles.localAdmin) {
                                                return -1;
                                            } else if (
                                                a.roles.localAdmin &&
                                                b.additionalRoles.validator
                                            ) {
                                                return 1;
                                            } else {
                                                return 0;
                                            }
                                        });
                                    this.loadingService.stopLoading(
                                        'delegateValidator',
                                        'getContentInfo'
                                    );
                                },
                                (error: HttpErrorResponse) => {
                                    this.loadingService.stopLoading(
                                        'delegateValidator',
                                        'getUsers'
                                    );
                                }
                            )
                        );
                        this.loadingService.stopLoading('delegateValidator', 'getUsers');
                    });
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('delegateValidator', 'getUsers');
                }
            )
        );
    }

    getIcon() {
        return this.libraryService.getIcon(this.content);
    }

    getTitle() {
        return this.content.title;
    }

    cancelSearch() {
        this.search = '';
    }

    getValidatorName(validator: User) {
        return `${validator.lastname.toUpperCase()} ${validator.firstname}`;
    }

    isValidatorSelected(validator: User) {
        return validator.selected;
    }

    toggleValidator(selectedValidator: User) {
        this.validators.map((validator: User) => {
            if (validator.id === selectedValidator.id) {
                validator.selected = true;
            } else {
                validator.selected = false;
            }
        });
    }

    canSendToValidation() {
        if (!this.comment || this.comment.length === 0) {
            return false;
        }
        if (this.validators) {
            return this.validators.filter((validator: User) => validator.selected).length === 1;
        }
        return false;
    }

    sendToValidation() {
        const data = {
            comment: this.comment,
            validator: this.validators
                .filter((validator) => validator.selected)
                .map((validator) => validator.id)[0]
        };
        this.dialogRef.close(data);
    }

    isLoading() {
        return this.loadingService.isLoading('delegateValidator');
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
