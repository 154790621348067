<div class="header">
    <div
        class="tab"
        [ngClass]="{ active: isSelectedTab('assemblies') }"
        (click)="handleClickSelectTab('assemblies')"
    >
        <div class="icon-creer-assemblage"></div>
        Assemblages
    </div>
    <div
        class="tab"
        [ngClass]="{ active: isSelectedTab('creations') }"
        (click)="handleClickSelectTab('creations')"
    >
        <div class="icon-video-interactive"></div>
        Créations digitales
    </div>
    <div
        class="tab"
        [ngClass]="{ active: isSelectedTab('imports') }"
        (click)="handleClickSelectTab('imports')"
    >
        <div class="icon-Importer"></div>
        Imports/Liens
    </div>
    <div
        class="icon-close"
        [ngClass]="{ active: isSelectedTab('imports') }"
        (click)="handleClickCloseDialog()"
    ></div>
</div>
<mat-dialog-content>
    @if (contentsList) {
        <app-left-content
            [activeTab]="activeTab"
            [(activeKey)]="activeKey"
            [contentsList]="contentsList"
        ></app-left-content>
        <app-right-content
            [(activeTab)]="activeTab"
            [(activeKey)]="activeKey"
            [activeContent]="getActiveContent()"
            (confirmed)="handleConfirm()"
        ></app-right-content>
    }
</mat-dialog-content>
