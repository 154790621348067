import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ConfigService } from '@/services/config.service';
import { UserService } from '@/services/user.service';
import { GroupService } from '@/services/group.service';
import { LoadingService } from '@/services/loading.service';
import { LibraryService } from '@/services/library.service';
import { FlashMessageService } from '@/services/flash-message.service';
import { AssignmentService } from '@/services/assignment.service';
import { DevoirService } from '@/services/devoir.service';
import { ReportingService } from '@/services/reporting.service';

import { Assignment } from '@/structures/assignment';
import { User } from '@/structures/user';

import * as FileSaver from 'file-saver';

import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-group-tracking',
    templateUrl: './group-tracking.component.html',
    styleUrls: ['./group-tracking.component.scss'],
    standalone: false
})
export class GroupTrackingComponent implements OnInit {
    openUploadDevoir: Subject<any> = new Subject();
    openAnonymousConfirmationDialog: Subject<any> = new Subject();
    teacherChoiceForAnonymousAssignation: Subject<any> = new Subject();
    openConfirmationDialog: Subject<any> = new Subject();

    quizList = [];

    subscriptions = new Subscription();

    tracking: any;
    notes: any;
    assignment: Assignment;
    saveNotesAsDefinitive = false;
    canCancelGrading = false;

    searchInput: string;

    constructor(
        public dialogRef: MatDialogRef<GroupTrackingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private configService: ConfigService,
        private userService: UserService,
        private groupService: GroupService,
        private loadingService: LoadingService,
        private assignmentService: AssignmentService,
        private devoirService: DevoirService,
        private flashMessageService: FlashMessageService,
        private sanitizer: DomSanitizer,
        private libraryService: LibraryService,
        private reportingService: ReportingService
    ) {}

    ngOnInit() {
        this.tracking = null;
        this.notes = {};
        this.assignment = this.data.assignment;
        this.searchInput = '';
        this.loadingService.startLoading('mainViewGroupTracking', 'getWorkTracking');

        this.subscriptions.add(
            this.groupService.getGroupDevoirTracking(this.data.assignment.assignmentId).subscribe(
                (data: any) => {
                    this.tracking = data;
                    if (this.assignment.is_revealed) {
                        this.saveNotesAsDefinitive = true;
                    }
                    if (this.assignment.level) {
                        this.groupService
                            .getGroupCompletion(
                                this.data.target.id,
                                this.data.assignment.assignmentId
                            )
                            .subscribe((completion) => {
                                this.tracking.map((trackingUser) =>
                                    completion.users_completions.map((toto: any) => {
                                        if (toto.id === trackingUser.userid) {
                                            trackingUser.completion = toto.completion;
                                        }
                                    })
                                );
                            });
                    }
                    this.loadingService.stopLoading('mainViewGroupTracking', 'getWorkTracking');
                },
                (error: HttpErrorResponse) => {
                    this.loadingService.stopLoading('addDisponibilityDialog');
                }
            )
        );
    }

    canHideAssignment() {
        return this.tracking.filter((trackingStatus) => trackingStatus.hidden).length === 0;
    }

    showAssignment(user?: any) {
        if (user) {
            this.subscriptions.add(
                this.userService.showUserAssignment(user.assignmentUserId).subscribe(() => {
                    user.hidden = false;
                    this.flashMessageService.flash("L'assignation a été réassignée.");
                })
            );
        } else {
            this.subscriptions.add(
                this.groupService
                    .showGroupAssignment(this.assignment.assignmentId)
                    .subscribe(() => {
                        this.tracking.map((trackingUser) => (trackingUser.hidden = false));
                        this.flashMessageService.flash("L'assignation a été réassignée.");
                    })
            );
        }
    }

    hideAssignment(user?: any) {
        if (user) {
            this.subscriptions.add(
                this.userService.hideUserAssignment(user.assignmentUserId).subscribe(() => {
                    user.hidden = true;
                    this.flashMessageService.flash("L'assignation a été désassignée.");
                })
            );
        } else {
            this.subscriptions.add(
                this.groupService
                    .hideGroupAssignment(this.assignment.assignmentId)
                    .subscribe(() => {
                        this.tracking.map((trackingUser) => (trackingUser.hidden = true));
                        this.flashMessageService.flash("L'assignation a été désassignée.");
                    })
            );
        }
    }

    sendReminder(user?) {
        let params;
        if (user) {
            params = {
                enrolmentSource: 'individual',
                assignmentId: user.assignmentUserId
            };
        } else {
            params = {
                enrolmentSource: 'group',
                assignmentId: this.assignment.assignmentId
            };
        }
        this.subscriptions.add(
            this.assignmentService.remindAssignment(params).subscribe(() => {
                this.flashMessageService.flash('Email envoyé');
            })
        );
    }

    downloadCSVReport() {
        const filters = {
            groupId: this.data.target.id,
            contentId: this.assignment.id
        };
        this.reportingService.getCSVReporting(filters).subscribe((csvText: string) => {
            const date =
                (new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()) +
                '-' +
                (new Date().getMonth() + 1 < 10
                    ? '0' + (new Date().getMonth() + 1)
                    : new Date().getMonth() + 1) +
                '-' +
                new Date().getFullYear();
            const csvName = 'reporting_' + date + '.csv';
            const blob = new Blob(['\ufeff', csvText], {
                type: 'text/plain;charset=iso-8859-1;'
            });
            FileSaver.saveAs(blob, csvName);

            this.flashMessageService.flash("L'export CSV est terminé");
        });
    }

    validateAssignment(user: any) {
        if (this.assignment.level && !user.forcedStatus) {
            this.openConfirmationDialog.next({ key: 'validate_assignment', user: user });
        } else {
            this.subscriptions.add(
                this.userService
                    .validateContentForUser(user.userid, this.assignment.id)
                    .subscribe(() => {
                        user.forcedStatus = 'completed';
                        this.flashMessageService.flash(
                            'Le contenu <b>' + this.assignment.title + '</b> a été validé'
                        );
                    })
            );
        }
    }

    onConfirmValidateAssignment(user: any) {
        this.subscriptions.add(
            this.userService
                .validateContentForUser(user.userid, this.assignment.id)
                .subscribe(() => {
                    this.groupService
                        .getGroupCompletion(this.data.target.id, this.data.assignment.assignmentId)
                        .subscribe((completion) => {
                            this.tracking.map((trackingUser) =>
                                completion.users_completions.map((toto: any) => {
                                    if (toto.id === trackingUser.userid) {
                                        trackingUser.completion = toto.completion;
                                    }
                                })
                            );
                        });
                    user.forcedStatus = 'completed';
                    this.flashMessageService.flash(
                        'Le contenu <b>' + this.assignment.title + '</b> a été validé'
                    );
                })
        );
    }

    invalidateAssignment(user: any) {
        this.subscriptions.add(
            this.userService
                .unvalidateContentForUser(user.userid, this.assignment.id)
                .subscribe(() => {
                    user.forcedStatus = null;
                    this.groupService
                        .getGroupCompletion(this.data.target.id, this.data.assignment.assignmentId)
                        .subscribe((completion) => {
                            this.tracking.map((trackingUser) =>
                                completion.users_completions.map((toto: any) => {
                                    if (toto.id === trackingUser.userid) {
                                        trackingUser.completion = toto.completion;
                                    }
                                })
                            );
                        });
                    this.flashMessageService.flash(
                        'Le contenu <b>' + this.assignment.title + '</b> a été invalidé'
                    );
                })
        );
    }

    resetGroupTracking() {
        this.openConfirmationDialog.next({
            key: 'reset_group_tracking',
            assignment: this.assignment
        });
    }

    onConfirmResetGroupTracking() {
        this.subscriptions.add(
            this.groupService
                .removeGroupTracking(this.data.target.id, this.assignment.id)
                .subscribe(() => {
                    this.flashMessageService.flash(
                        'Le contenu <b>' + this.assignment.title + '</b> a été réinitialisé'
                    );
                    this.tracking.map((user) => {
                        user.status = 'not attempted';
                        user.score = undefined;
                    });
                })
        );
    }

    canResetTracking(user: any) {
        return user.forcedStatus === null && user.status !== 'not attempted';
    }

    resetTracking(user?: any) {
        this.openConfirmationDialog.next({
            key: 'reset_tracking',
            user: user,
            assignment: this.assignment
        });
    }

    onConfirmResetTracking(user: any) {
        this.subscriptions.add(
            this.userService.removeTracking(user.userid, this.assignment).subscribe(() => {
                this.flashMessageService.flash(
                    'Le contenu <b>' + this.assignment.title + '</b> a été réinitialisé'
                );
                user.status = 'not attempted';
                user.score = undefined;
                user.temporaryScore = undefined;
                user.submitted_file = undefined;
            })
        );
    }

    openGroupTestReporting() {
        this.quizList = [];
        if (this.assignment.children) {
            this.getQuizsContentFromAssignments(this.assignment.children);
        } else {
            this.quizList = [this.assignment.quizId];
        }
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?groupId=' +
                this.data.target.id +
                '&select=test&id=' +
                this.quizList.join('|') +
                '&name=' +
                this.assignment.title,
            '_blank'
        );
    }

    openUserTestReporting(user) {
        this.quizList = [];
        if (this.assignment.children) {
            this.getQuizsContentFromAssignments(this.assignment.children);
        } else {
            this.quizList = [this.assignment.quizId];
        }
        window.open(
            this.configService.getReportingFrontEndpoint() +
                '#/?studentId=' +
                user.userid +
                '&select=test&id=' +
                this.quizList.join('|') +
                '&name=' +
                this.assignment.title,
            '_blank'
        );
    }

    getQuizsContentFromAssignments(assignments) {
        let found: boolean;
        for (const i in assignments) {
            if (assignments[i].type === 'quiz') {
                found = false;
                for (const j in this.quizList) {
                    if (this.quizList[j] === assignments[i].quizId) {
                        found = true;
                    }
                }
                if (!found) {
                    this.quizList.push(assignments[i].quizId);
                }
            } else if (assignments[i].children) {
                this.getQuizsContentFromAssignments(assignments[i].children);
            }
        }
    }

    canStartReview(user: any) {
        return (
            this.assignment.type === 'quiz' &&
            ((user.attempt === 1 && user.status !== 'opened') || user.attempt > 1)
        );
    }

    startReview(user: any) {
        window.open(
            `${this.configService.getFrontEndPoint()}/quizPlayer/#/${
                this.assignment.id
            }?teacher=true&studentId=${user.userid}`,
            '_blank'
        );
    }

    toggleUserBeingNoted(user) {
        user.isBeingNoted = true;
    }

    getDateToShow(user: any) {
        switch (this.assignment.type) {
            case undefined: {
                if (user.lastAccess) {
                    return 'Dernier accès le ' + new Date(user.lastAccess).toLocaleDateString();
                }
                break;
            }
            case 'presentiel': {
                if (user.notation_date) {
                    return 'Noté le ' + new Date(user.notation_date).toLocaleDateString();
                }
                break;
            }
            case 'devoir': {
                if (user.submission_time) {
                    return 'Rendu le ' + new Date(user.submission_time).toLocaleDateString();
                }
                break;
            }
            case 'quiz': {
                if (user.lastAccess) {
                    return (
                        'Dernière tentative le ' + new Date(user.lastAccess).toLocaleDateString()
                    );
                }
                break;
            }
            default: {
                if (user.lastAccess) {
                    return new Date(user.lastAccess).toLocaleDateString();
                }
                break;
            }
        }
    }

    showDateTooltip() {
        return (
            this.assignment.type !== 'presentiel' &&
            this.assignment.type !== 'devoir' &&
            this.assignment.type !== 'quiz'
        );
    }

    getDateTooltip(user) {
        return `Nombre totale de consultations : ${user.consultations}`;
    }

    getQuizTooltip(user) {
        let tooltip = '';
        tooltip += `Nombre totale de tentative : ${user.notes.length}<br><br>Historique des notes<br>`;
        for (const note of user.notes) {
            tooltip += `${new Date(note.date).toLocaleDateString()} - ${note.score}/100<br>`;
        }
        return tooltip;
    }

    postNote(user, passed) {
        this.subscriptions.add(
            this.userService
                .noteContent(user.userid, this.assignment.id, user.score, passed)
                .subscribe(() => {
                    this.flashMessageService.flash(
                        'Le contenu <strong>' + this.assignment.title + '</strong> a été noté'
                    );
                    user.status = passed ? 'passed' : 'failed';
                    user.isBeingNoted = false;
                })
        );
    }

    getContentIcon() {
        return this.libraryService.getIcon(this.assignment);
    }

    getIconTracking(user: any) {
        const status = user.forcedStatus ? user.forcedStatus : user.status;

        let classList = [];

        switch (status) {
            case 'not attempted':
                classList = ['icon-select'];
                break;
            case 'opened':
                classList = ['icon-Progression40'];
                break;
            case 'completed':
                classList = ['icon-online'];
                break;
            case 'failed':
                classList = ['icon-Fermerdetails'];
                break;
            case 'passed':
                classList = ['icon-Valide'];
                break;
            default:
                classList = ['icon-select'];
                break;
        }
        if (user.forcedStatus) {
            classList.push('orange');
        }
        return classList;
    }

    getTooltipTracking(user) {
        const status = user.forcedStatus ? user.forcedStatus : user.status;

        let tooltip = '';

        switch (status) {
            case 'not attempted':
                tooltip += 'Nouveau';
                break;
            case 'opened':
                tooltip += 'En cours';
                break;
            case 'completed':
                tooltip += 'Terminé';
                break;
            case 'failed':
                tooltip += 'Non réussi';
                break;
            case 'passed':
                tooltip += 'Réussi';
                break;
            default:
                tooltip += 'Nouveau';
                break;
        }
        if (user.forcedStatus) {
            tooltip += ' (forcé par le formateur)';
        }
        if (user.attempt) {
            tooltip += '<br>' + user.attempt + ' tentative(s)';
        }
        return tooltip;
    }

    inSearchResult(user) {
        const reg = new RegExp(this.accentFold(this.searchInput), 'i');

        const firstLast = this.accentFold(user.firstname + ' ' + user.lastname.toUpperCase());
        const lastFirst = this.accentFold(user.lastname.toUpperCase() + ' ' + user.firstname);

        if (firstLast.search(reg) > -1 || lastFirst.search(reg) > -1) {
            return true;
        }

        return false;
    }

    accentFold(string: string) {
        return string.replace(
            /([àáâãäå])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
            function (str, a, c, e, i, n, o, s, u, y, ae) {
                if (a) {
                    return 'a';
                } else if (c) {
                    return 'c';
                } else if (e) {
                    return 'e';
                } else if (i) {
                    return 'i';
                } else if (n) {
                    return 'n';
                } else if (o) {
                    return 'o';
                } else if (s) {
                    return 's';
                } else if (u) {
                    return 'u';
                } else if (y) {
                    return 'y';
                } else if (ae) {
                    return 'ae';
                }
            }
        );
    }

    cancelSearch() {
        this.searchInput = '';
    }

    isAssignmentDisabled(user) {
        return user.hidden;
    }

    canForceAssignment() {
        return this.data.assignment.forceStatus;
    }

    isAssignmentForced(user) {
        return !!user.forcedStatus;
    }

    isAnonymous() {
        return this.data.assignment.is_anonymous;
    }

    isLoading(view: string) {
        return this.loadingService.isLoading(view);
    }

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }

    /****************************************** DEVOIR ***************************************/

    /**
     * disable le bouton de reveal si un apprenant a rendu son devoir mais n'a pas encore été noté
     */
    isRevealButtonDisabled(): boolean {
        if (this.tracking) {
            return this.tracking.some((user) => {
                return user.status === 'completed' && user.submitted_file && !user.temporaryScore;
            });
        }
        return true;
    }

    showNoteDefinitiveCheckbox() {
        return this.tracking.some((user) => user.temporaryScore !== null && !user.isBeingNoted);
    }

    toggleNotesDefinitive(): void {
        this.saveNotesAsDefinitive = !this.saveNotesAsDefinitive;
    }

    /**
     * Ouvre une popup pour confirmer la levée de l'anonymat
     * + message spécial si présence de copies non rendues/notées
     */
    openAnonymousRevealConfirmation(): void {
        if (this.getNumberOfNoted() === this.assignment.users) {
            this.openAnonymousConfirmationDialog.next({ allDevoirsReady: true });
        } else {
            this.openAnonymousConfirmationDialog.next({ allDevoirsReady: false });
        }
    }

    postNoteDevoir(user) {
        this.userService
            .noteDevoirTemporary(user.userid, this.assignment.id, user.temporaryScore)
            .subscribe(() => {
                this.canCancelGrading = true;
                user.isBeingNoted = false;
            });
    }

    initGradeGroup(choice: any) {
        this.saveNotesAsDefinitive = true;
        if (choice === true) {
            this.gradeGroup();
        } else if (choice.missingNotes === 0) {
            this.gradeGroup('zero');
        } else if (choice.missingNotes === null) {
            this.gradeGroup('noNote');
        }
    }

    getGradingAction(): 'grade' | 'cancel' {
        if (this.saveNotesAsDefinitive) {
            return 'grade';
        } else {
            return null;
        }
    }

    gradeGroup(giveZeroToMissingNotes?) {
        if (this.data.assignment.type === 'devoir' && this.getGradingAction()) {
            this.devoirService
                .gradeGroupWork(
                    this.assignment.assignmentId,
                    this.getGradingAction(),
                    giveZeroToMissingNotes
                )
                .subscribe(() => {
                    this.dialogRef.close(true);
                });
        } else {
            this.dialogRef.close(true);
        }
    }

    getDownloadAllDevoirsLink(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            this.groupService.getGroupDevoirDownloadAllLink(this.data.assignment.assignmentId)
        );
    }

    getTooltipCorrectionUser(user: any) {
        if (user.correction_file) {
            return 'Voir le devoir corrigé';
        } else {
            return 'Déposer le devoir corrigé';
        }
    }

    getTooltipUploadDevoir() {
        if (this.assignment.correction_file) {
            return 'Voir le corrigé général';
        } else {
            return 'Rendre un corrigé général';
        }
    }

    /**
     * ouvre la modale d'upload de corrigé de devoir individuel ou collectif
     * @param user (optionnel) user dans le cas d'un corrigé individuel
     */
    openUploadDevoirCorrectionGroup(): void {
        if (this.assignment.correction_file) {
            window.open(this.assignment.correction_file, '_blank');
        } else {
            this.openUploadDevoir.next({
                assignment: this.assignment,
                group: this.data.target
            });
        }
    }

    openUploadDevoirCorrectionUser(user?: User) {
        if (user.correction_file) {
            window.open(user.correction_file, '_blank');
        } else {
            this.openUploadDevoir.next({
                assignment: this.assignment,
                user
            });
        }
    }

    getNumberOfCompleted() {
        let number = 0;
        if (this.tracking) {
            for (let i = 0; i < this.tracking.length; i++) {
                if (!!this.tracking[i].submitted_file) {
                    number++;
                }
            }
        }
        return number;
    }

    getNumberOfNoted() {
        let number = 0;
        if (this.tracking) {
            for (let i = 0; i < this.tracking.length; i++) {
                if (!!this.tracking[i].temporaryScore) {
                    number++;
                }
            }
        }
        return number;
    }

    getDevoirDates(param) {
        return new Date(new Date(param).getTime() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, -1);
    }

    openCorrectionFile(url: string): void {
        window.open(url, '_blank');
    }

    /****************************************** END DEVOIR ***********************************/
}
