// Internal dependencies
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

// External dependencies
import { UntilDestroy } from '@ngneat/until-destroy';

// Modules
import { SharedModule } from 'src/app/modules/shared.module';

// Components
import { AssemblyConfirmationEntryComponent } from './assembly-confirmation-entry/assembly-confirmation-entry.component';

// Services

// Interfaces
import { Content } from '@/structures/content';

// Pipes

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-assembly-confirmation',
    templateUrl: './assembly-confirmation.component.html',
    styleUrls: ['./assembly-confirmation.component.scss'],
    imports: [SharedModule, MatDialogModule, AssemblyConfirmationEntryComponent]
})
export class AssemblyConfirmationComponent implements OnInit {
    content: Content;
    mode: string;
    activeContentCount: any;

    constructor(
        public dialogRef: MatDialogRef<AssemblyConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.content = this.data.content;
        this.mode = this.data.mode;
        this.activeContentCount = {
            bloc: 0,
            parcours: 0,
            competence: 0,
            module: 0,
            sequence: 0
        };
        this.getActiveContentCount(this.content);
    }

    getTitle() {
        switch (this.mode) {
            case 'delete': {
                return "Suppression de l'assemblage";
            }
            case 'publish': {
                return "Publication de l'assemblage";
            }
            case 'copy': {
                return "Copie de l'assemblage";
            }
        }
    }

    getLegend() {
        switch (this.mode) {
            case 'delete': {
                return '';
            }
            case 'publish': {
                return (
                    this.activeContentCount +
                    ' contenus (affichés en vert) vont être publié(s) dans la bibliothèque de easi training'
                );
            }
            case 'copy': {
                return (
                    this.activeContentCount +
                    ' contenus (affichés en bleu) vont être copié(s) dans votre espace de création de contenus'
                );
            }
        }
    }

    getLegendClass() {
        switch (this.mode) {
            case 'delete': {
                return 'red';
            }
            case 'publish': {
                return 'green';
            }
            case 'copy': {
                return 'blue';
            }
        }
    }

    getCount() {
        switch (this.mode) {
            case 'delete': {
                return 'Contenus supprimés';
            }
            case 'publish': {
                return 'Contenus publiés';
            }
            case 'copy': {
                return 'Contenu copiés';
            }
        }
    }

    getButton() {
        switch (this.mode) {
            case 'delete': {
                return 'Supprimer';
            }
            case 'publish': {
                return 'Publier';
            }
            case 'copy': {
                return 'Copier';
            }
        }
    }

    getActiveContentCount(content: Content) {
        if (content.status !== 'approved') {
            this.activeContentCount[content.level]++;
        }
        for (const i in content.children) {
            if (content.children[i]) {
                this.getActiveContentCount(content.children[i]);
            }
        }
    }

    action() {
        this.dialogRef.close(true);
    }

    closeDialog() {
        this.dialogRef.close(false);
    }
}
