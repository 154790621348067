import {
    Directive,
    Input,
    ComponentRef,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { MoreActionsComponent } from './more-actions.component';
import {
    OverlayRef,
    Overlay,
    OverlayPositionBuilder,
    ConnectedPosition,
    OverlayConfig
} from '@angular/cdk/overlay';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { ComponentPortal } from '@angular/cdk/portal';
import { LibraryEntryComponent } from '../library-entry.component';
import { PanelService } from '@/services/panel.service';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Directive({
    selector: '[more-actions]',
    standalone: false
})
export class MoreActionsDirective implements OnInit, OnDestroy {
    opened = false;
    @Input('more-actions') element: ComponentRef<LibraryEntryComponent>;
    @Input('more-actions-origin') moreActionOrigin: string;
    @Input('more-actions-class') moreActionClass: string;

    componentPortal: ComponentPortal<MoreActionsComponent>;
    private overlayRef: OverlayRef;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef,
        private overlay: Overlay,
        public scrollDispatcher: ScrollDispatcher,
        private panelService: PanelService
    ) {}

    @HostListener('click') toggle() {
        if (!this.opened) {
            this.show();
        } else {
            this.close();
        }
    }

    show() {
        this.componentPortal = new ComponentPortal(MoreActionsComponent);

        const componentRef: ComponentRef<MoreActionsComponent> = this.overlayRef.attach(
            this.componentPortal
        );

        this.opened = true;
        componentRef.instance.element = this.element;
        componentRef.instance.moreActionOrigin = this.moreActionOrigin;
        componentRef.instance.moreActionClass = this.moreActionClass;

        componentRef.instance.close = this.close.bind(this);
    }

    close() {
        this.opened = false;
        this.overlayRef.detach();
    }

    setPositions(): ConnectedPosition[] {
        const top: ConnectedPosition = {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetY: -8,
            panelClass: 'above-panel-tooltip'
        };
        const bottom: ConnectedPosition = {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 8,
            panelClass: 'below-panel-tooltip'
        };

        return [top, bottom];
    }

    ngOnInit() {
        const positions = this.setPositions();
        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions(positions)
            .withTransformOriginOn('#more-actions');

        const overlayConfig = new OverlayConfig({
            scrollStrategy: this.overlay.scrollStrategies.close(),
            positionStrategy
        });
        this.overlayRef = this.overlay.create(overlayConfig);
        this.subscriptions.add(
            this.panelService.scrollInLibrary.subscribe({
                next: () => {
                    this.close();
                }
            })
        );
    }

    ngOnDestroy() {
        this.overlayRef.detach();
        this.overlayRef.dispose();
    }
}
