import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dates',
    standalone: false
})
export class DatesPipe extends DatePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        switch (args[0]) {
            case 'du-au':
                return this.fromStartToEndFormatting(value);
            default:
                return this.simpleFormatting(value);
                break;
        }
    }

    /**
     * si l'heure est 00:00, retourne uns string vide, sinon, retourne l'heure au format 13h15
     * @param date la date
     */
    formatHoursMinutes(date: Date): string {
        if (date.getHours() === 0 && date.getMinutes() === 0) {
            return '';
        } else {
            return " (HH'h'mm)";
        }
    }

    /**
     * fix temporaire : si la date est un timestamp, elle est convertie pour éviter le plantage du pipe
     * @param date la date à tester
     * @deprecated
     */
    timestampsOverride(date) {
        if (!isNaN(+date)) {
            return Number(date);
        } else {
            return date;
        }
    }

    simpleFormatting(value): string {
        const start = new Date(value);
        return super.transform(start, `dd/MM/yyyy`);
    }

    fromStartToEndFormatting({ start, end }): string {
        // const startOverridden = new Date(this.timestampsOverride(start));
        const startDate = new Date(start);
        const endDate = new Date(end);

        if (!end) {
            return `A partir du ${super.transform(
                startDate,
                `dd/MM/yyyy${this.formatHoursMinutes(startDate)}`
            )}`;
        } else {
            // const end = new Date(this.timestampsOverride(end));
            return `Du ${super.transform(
                startDate,
                `dd/MM/yyyy${this.formatHoursMinutes(startDate)}`
            )} au ${super.transform(end, `dd/MM/yyyy${this.formatHoursMinutes(endDate)}`)}`;
        }
    }
}
