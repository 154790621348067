import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../structures/user';

@Pipe({
    name: 'userSearch',
    standalone: false
})
export class UserSearchPipe implements PipeTransform {
    /**
     * @param { Array<Structure> } structures Un tableau contenant l'ensemble des structures sur lequel on souhaite filtrer
     * @param { string } filter Le filtre que l'on souhaite appliquer sur les structures
     * Un pipe permettant de filtrer les structures sur leur nom
     */
    transform(users: Array<User>, filter: string): Array<User> {
        const res: Array<User> = [];

        if (!filter) {
            return users;
        }

        for (const i in users) {
            if (
                users[i].firstname.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
                users[i].lastname.toLowerCase().indexOf(filter.toLowerCase()) > -1
            ) {
                res.push(users[i]);
            }
        }
        return res;
    }
}
