import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';

import { LoginService } from '@/services/login.service';

import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-export-catalog',
    templateUrl: './export-catalog.component.html',
    styleUrls: ['./export-catalog.component.scss'],
    standalone: false
})
export class ExportCatalogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ExportCatalogComponent>,
        private loginService: LoginService,
        private http: HttpClient
    ) {}

    subscriptions = new Subscription();

    disabledLocalLibrary: boolean;
    loadingLocal: boolean;
    loadingNational: boolean;

    ngOnInit() {
        this.disabledLocalLibrary = false;
        this.loadingLocal = false;
        this.loadingNational = false;
        if (!this.loginService.getUser().structureid && !this.loginService.getLastStructure().id) {
            this.disabledLocalLibrary = true;
        }
    }

    downloadLocalLibrary() {
        const config: any = {
            withCredentials: true,
            responseType: 'text'
        };

        if (!this.disabledLocalLibrary && !this.loadingLocal) {
            this.loadingLocal = true;
            if (this.loginService.getUser().structureid) {
                this.subscriptions.add(
                    this.http
                        .get('/contents/export/' + this.loginService.getUser().structureid, config)
                        .subscribe((success: any) => {
                            const blob = new Blob(['\ufeff', success], {
                                type: 'text/csv;charset=iso-8859-1;'
                            });
                            FileSaver.saveAs(
                                blob,
                                'export_contenus_' +
                                    this.loginService.getUser().localStructure +
                                    '.csv'
                            );
                            this.loadingLocal = false;
                            this.closeDialog();
                        })
                );
            } else {
                this.subscriptions.add(
                    this.http
                        .get('/contents/export/' + this.loginService.getLastStructure().id, config)
                        .subscribe((success: any) => {
                            const blob = new Blob(['\ufeff', success], {
                                type: 'text/csv;charset=iso-8859-1;'
                            });
                            FileSaver.saveAs(
                                blob,
                                'export_contenus_' +
                                    this.loginService.getLastStructure().name +
                                    '.csv'
                            );
                            this.loadingLocal = false;
                            this.closeDialog();
                        })
                );
            }
        }
    }

    downloadNationalLibrary = function () {
        const config = {
            withCredentials: true,
            responseType: 'text'
        };

        if (!this.loadingNational) {
            this.loadingNational = true;
            if (this.getExport$) {
                this.getExport$.unsubscribe();
            }
            this.subscriptions.add(
                this.http.get('/contents/export/0', config).subscribe((success: any) => {
                    const blob = new Blob(['\ufeff', success], {
                        type: 'text/csv;charset=iso-8859-1;'
                    });
                    FileSaver.saveAs(blob, 'export_contenus_national.csv');
                    this.loadingNational = false;
                    this.closeDialog();
                })
            );
        }
    };

    /**
     * Ferme la fenêtre d'erreur
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
